<template>
  <v-container class="pa-0" v-if="selectedPage == 'Intro'" fluid>
    <v-row align="center" no-gutters class="top-separator">
      <v-col class="text-center" cols="12">
        <v-card
          color="#C4C4C4"
          class="d-flex align-content-space-around flex-wrap px-5 mx-0 justify-center"
          tile
          :height="44"
          flat
        >
          <v-checkbox
            :input-value="SHM"
            @change="onChange"
            color="primary"
            label="Show help messages"
            class="toc-label-text"
            off-icon="$boxBlankWhite"
            on-icon="$boxCheckedWhite"
          ></v-checkbox>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="text-center" cols="12">
        <Button
          look="btnNextWP"
          @click="getStarted()"
          height="39"
          width="147"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Button from "@/components/Button";
import { mapGetters, mapMutations } from "vuex";
import logdown from "logdown";
const logger = logdown("IntroFooter");

export default {
  name: "IntroFooter",

  components: {
    Button,
  },
  computed: {
    ...mapGetters({
      selectedPage: "getSelectedPage",
      selectedTerm: "getSelectedTerm",
      SHM: "getShowHelpMessages",
    }),
  },
  data: function() {
    return {};
  },

  methods: {
    ...mapMutations({
      selectPage: "setSelectedPage",
    }),
    getStarted() {
      const nextPage = "SelectATerm" 
      logger.debug(`next, going to ${nextPage}`);
      // this.$vuetify.goTo(0);
      this.selectPage(nextPage);
      //reset TAC for the next interaction
      this.$store.commit("setTermsAndConditions", false);
      this.$forceUpdate();
    },
    onChange(event) {
      logger.debug("onChange", event);
      this.$store.commit("setShowHelpMessages", event);
    },
  },
};
</script>

<style>
/* label. */
.toc-label-text .v-label {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  color: #4f4f4f;
}
.top-separator {
  border-top: solid 1px #5f5f5f;
}
</style>
