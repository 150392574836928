import logo from './logo.svg'
import { terms, introText } from './terms'
import courses from './courses'
import coursesAnyTerm from './coursesAnyTerm'


const customer = {
    ...require('./static'),
    logo,
    terms,
    introText,
    courses,
    courses2021summer: coursesAnyTerm,
    courses2021fall: coursesAnyTerm,
    courses2021winter: coursesAnyTerm,
    courses2022spring: coursesAnyTerm,
    courses2022summer: coursesAnyTerm,
    courses2022fall: coursesAnyTerm,
    courses2022winter: coursesAnyTerm,
}

export {
    customer,
}