var data = [
{"id":"ACCT304","title":"Intermediate Accounting I"},
{"id":"ACCT488","title":"Indust & Govt Intern in Acctg"},
{"id":"ANTH323","title":"Mesoamerican Archaeology"},
{"id":"ANTH352","title":"Anthropology of Human Rights"},
{"id":"ANTH450","title":"Health Disparities"},
{"id":"APMU107","title":"Private Viola I"},
{"id":"APMU110","title":"Private Flute I"},
{"id":"APMU111","title":"Private Clarinet I"},
{"id":"APMU112","title":"Private Oboe I"},
{"id":"APMU152","title":"Private Organ II"},
{"id":"APMU263","title":"Private Bassoon IV"},
{"id":"APMU266","title":"Private French Horn IV"},
{"id":"APMU307","title":"Private Viola V"},
{"id":"APMU310","title":"Private Flute V"},
{"id":"APMU312","title":"Private Oboe V"},
{"id":"APMU358","title":"Private Cello VI"},
{"id":"APMU362","title":"Private Oboe VI"},
{"id":"APMU363","title":"Private Bassoon VI"},
{"id":"APMU401","title":"Private Piano VII"},
{"id":"APMU405","title":"Private Voice VII"},
{"id":"APMU409","title":"Private String Bass VII"},
{"id":"APMU410","title":"Private Flute VII"},
{"id":"APMU413","title":"Private Bassoon VII"},
{"id":"APMU414","title":"Private Saxophone VII"},
{"id":"APMU458","title":"Private Cello VIII"},
{"id":"APMU462","title":"Private Oboe VIII"},
{"id":"APMU463","title":"Private Bassoon VIII"},
{"id":"APMU469","title":"Private Tuba VIII"},
{"id":"APMU471","title":"Private Guitar VIII"},
{"id":"APMU480","title":"Wind & Percussion Pedagogy"},
{"id":"ARED317","title":"Art in K-6 Programs"},
{"id":"ARHI412","title":"Classical Art"},
{"id":"ASIA200","title":"Introduction to Asian Studies"},
{"id":"ASIA483","title":"Honors Thesis"},
{"id":"BIOC401","title":"Lab Methods in Biol & Biotech"},
{"id":"BKNG3000","title":"Advanced Baking and Pastries"},
{"id":"CDFR224","title":"Marriage & Family Relations"},
{"id":"CDFR410","title":"Infant & Toddler Development"},
{"id":"CDFR426","title":"Techniques of Parent Education"},
{"id":"CDFR427","title":"Admin of Human Serv Programs"},
{"id":"CDFR430","title":"Poverty & Human Development"},
{"id":"CHEM105","title":"Forensic Chemistry of CSI"},
{"id":"CHEM343","title":"Physical Chemistry I Lab"},
{"id":"CHEM431","title":"Organic Molec Struc Determinat"},
{"id":"CHEM442","title":"Advanced Physical Chemistry"},
{"id":"COMM143","title":"Media Wellness"},
{"id":"COMM151","title":"Basic Lghtg: Still & Motion Im"},
{"id":"COMM249","title":"Basic Audio Recording Techniq"},
{"id":"COMM302","title":"Research in Comm Media"},
{"id":"COMM303","title":"Scriptwriting"},
{"id":"COMM315","title":"Persuasive Media Writing"},
{"id":"COMM330","title":"Instr Design/Training & Dev"},
{"id":"COMM335","title":"Comm Consulting & Project Mgmt"},
{"id":"COMM350","title":"Digital Storytelling"},
{"id":"COMM374","title":"Documentary Photography"},
{"id":"COMM403","title":"Multimedia Newswriting"},
{"id":"COMM420","title":"Media Portrayal of Crime"},
{"id":"COSC343","title":"Intro to Numerical Methods"},
{"id":"COSC352","title":"LAN Design & Installation"},
{"id":"COSC405","title":"Artificial Intelligence"},
{"id":"COSC473","title":"Software Engineer Practice"},
{"id":"CULN2082","title":"Culinary Independ Study/Spring"},
{"id":"DANC280","title":"Beginning Ballet"},
{"id":"DANC353","title":"Dance Curriculum & Instruction"},
{"id":"DVLP1000","title":"Careers in the Culinary Arts"},
{"id":"DVLP1100","title":"Personal & Professional Devel"},
{"id":"ECON343","title":"Economic History of the US"},
{"id":"ECON482","title":"Independent Study"},
{"id":"EDEX269","title":"Ident & Undrstng Child w/Acad"},
{"id":"EDEX278","title":"Ident & Undrstng Child w/Behav"},
{"id":"EDEX369","title":"Ed Emo/Behav/Lrng Dis/BrainInj"},
{"id":"EDSP102","title":"Educational Psychology"},
{"id":"EDSP468","title":"Current Topics: Ed Psychology"},
{"id":"ELED351","title":"Creative Activities in El Sch"},
{"id":"ENGL326","title":"Writing Fiction"},
{"id":"ENGL332","title":"Film Genres"},
{"id":"ENGL337","title":"Myth"},
{"id":"ENGL340","title":"The Novel"},
{"id":"ENGL360","title":"Editing & Publishing"},
{"id":"ENGL396","title":"Literature of Emerging Nations"},
{"id":"ENGL460","title":"Topics in Film"},
{"id":"ENGL493","title":"Internship"},
{"id":"ETIT330","title":"Technology in the Classroom"},
{"id":"FDED440","title":"Orient to Tchg in Urban Cntrs"},
{"id":"FDNT150","title":"Foods"},
{"id":"FNLG121","title":"Humanities Literature"},
{"id":"FRNC102","title":"Basic French II"},
{"id":"FRNC201","title":"Intermediate French"},
{"id":"FRNC230","title":"Intermed French Comp & Grammar"},
{"id":"FSMR380","title":"Application in Apparel Buying"},
{"id":"GEOS156","title":"Geology of Natural Disasters"},
{"id":"GEOS200","title":"Foundations of Geology"},
{"id":"GEOS202","title":"Quantitative Methods in Geosci"},
{"id":"GEOS353","title":"Paleontology"},
{"id":"HIST205","title":"US History Since 1877"},
{"id":"HIST221","title":"The Historian's Craft"},
{"id":"HIST301","title":"History of Greece"},
{"id":"HIST311","title":"Rise & Fall of Hitler's Empire"},
{"id":"HIST326","title":"History of Russia"},
{"id":"HIST346","title":"Recent U S History"},
{"id":"IFMG390","title":"Database Theory & Practice"},
{"id":"IFMG471","title":"Data Center & Cloud Computing"},
{"id":"JPRJ2100","title":"Bkng & Pastry Prof Portfolio 2"},
{"id":"JRNL220","title":"Writing for Media"},
{"id":"JRNL321","title":"Feature Writing"},
{"id":"JRNL430","title":"Public Opinion & Media"},
{"id":"KHSS162","title":"Scuba Diving"},
{"id":"MATH340","title":"Prin of Second School Math"},
{"id":"MATH353","title":"Theory of Numbers"},
{"id":"MATH411","title":"Univariate Data Analysis"},
{"id":"MATH422","title":"Adv Calculus II"},
{"id":"MATH457","title":"Number Theory Elem/Middle"},
{"id":"MATH460","title":"Technology in Math Instruction"},
{"id":"MGMT434","title":"Indust Qual: Stat Tools & Mgmt"},
{"id":"MGMT493","title":"Internship"},
{"id":"MUSC120","title":"Brass Ensemble"},
{"id":"MUSC123","title":"Symphony Band"},
{"id":"MUSC126","title":"Music Theater"},
{"id":"MUSC129","title":"University Symphony Orchestra"},
{"id":"MUSC130","title":"String Ensemble"},
{"id":"MUSC132","title":"Treble or Bass Chorus"},
{"id":"MUSC224","title":"Jazz Improvisation Tech I"},
{"id":"MUSC281","title":"Special Topic"},
{"id":"MUSC315","title":"Theory V"},
{"id":"NURS306","title":"Problem Solving in Nursing"},
{"id":"NURS314","title":"Health Policy and Law"},
{"id":"NURS410","title":"Health Promotion & Soc Issues"},
{"id":"NURS431","title":"Public/Community Nurs Clinical"},
{"id":"OLOC163","title":"St Abroad/Cult Exp Abroad(CEA)"},
{"id":"OLOC170","title":"St Abroad/Univ of Botswana"},
{"id":"OLOC209","title":"Visiting Student-Mansfield"},
{"id":"OLOC270","title":"Culinary St Abroad,SCA-Swiss"},
{"id":"PHIL223","title":"Philosophy of Art"},
{"id":"PHIL232","title":"Philosophies of Love"},
{"id":"PHIL240","title":"Philosophy & the Good Life"},
{"id":"PHIL270","title":"Ethics & the Environment"},
{"id":"PHIL326","title":"Existentialism"},
{"id":"PHIL480","title":"Honors Seminar in Philosophy"},
{"id":"PLSC375","title":"Crisis Mgmt & Decision Making"},
{"id":"PLSC384","title":"Political Systems-Middle East"},
{"id":"PSYC100","title":"Psyc Mjr: Curriculum & Careers"},
{"id":"PSYC102","title":"Identity & Interact Div World"},
{"id":"PSYC255","title":"Biopsychology"},
{"id":"PSYC322","title":"Violence Across the Lifespan"},
{"id":"PSYC331","title":"Environmental Psychology"},
{"id":"PSYC332","title":"Comm Psych & Prevention Sci"},
{"id":"PUBH306","title":"Rsrch Dsgn & Analys-Public Hth"},
{"id":"PUBH482","title":"Independent Study"},
{"id":"RESP326","title":"Respiratory Care Clinical I"},
{"id":"RESP432","title":"Neonatal & Pediatric Resp Care"},
{"id":"RESP493","title":"Respiratory Care Internship"},
{"id":"RLST370","title":"Religions: China & Japan"},
{"id":"SAFE104","title":"Intro to Safety in Nat Gas Ind"},
{"id":"SAFE111","title":"Prin of Industrial Safety"},
{"id":"SAFE482","title":"Independent Study"},
{"id":"SOC161","title":"Foun of Soc:Grps & Organiztns"},
{"id":"SOC231","title":"Contemporary Social Problems"},
{"id":"SOC345","title":"Interpersonal Dynamics"},
{"id":"SOC348","title":"Sociology of Work"},
{"id":"SOC391","title":"Found of Sociological Practice"},
{"id":"SOC417","title":"Global Service Learning"},
{"id":"SOC456","title":"Field Research Methods"},
{"id":"SPAN402","title":"Translation & Interpretation"},
{"id":"SPAN413","title":"Spanish Poetry/19 & 20th Cent"},
{"id":"TMWK461","title":"Teamwork & Communic Capstone"},
{"id":"VOED100","title":"Prep of Voc Professional I"},
{"id":"VOED450","title":"Voc Tech Prep of Voc Professnl"},
{"id":"WGS430","title":"Gender, Sexuality, & Sport"},
{"id":"PHYS142","title":"Physics II-Calc Lab"},
{"id":"PHYS222","title":"Mechanics I"},
{"id":"PHYS355","title":"Computer Interfacing"},
{"id":"PHYS481","title":"Special Topic"},
{"id":"PLSC300","title":"Rsrch Methods in Political Sci"},
{"id":"PLSC339","title":"Jihad Origins--Islamist Movmnt"},
{"id":"PSYC379","title":"Psychology of Human Sexuality"},
{"id":"PSYC410","title":"Historical Trends in Psych"},
{"id":"PSYC460","title":"Senior Seminar in Psychology"},
{"id":"PSYC482","title":"Independent Study"},
{"id":"PSYC493","title":"Psychology Practicum"},
{"id":"OLOC103","title":"St Abroad/U of Nancy 2"},
{"id":"OLOC109","title":"Study Abroad"},
{"id":"OLOC110","title":"St Abroad/U of Macau SAR"},
{"id":"OLOC125","title":"St Abroad/Independ Arranged"},
{"id":"OLOC136","title":"St Abroad/INTI-Malaysia"},
{"id":"OLOC138","title":"Study Abroad-Hogskolen, Norway"},
{"id":"SCI106","title":"Physical Science II"},
{"id":"SCI113","title":"Physics in Science Fiction"},
{"id":"ENGL222","title":"Technical Writing"},
{"id":"FDNT458","title":"Adv Human Nutrition"},
{"id":"FDNT466","title":"Nutrition Counseling & Ed Lab"},
{"id":"FDNT470","title":"Human Food Consump Patterns"},
{"id":"FIN366","title":"Stu Mgd Invst Port - Performan"},
{"id":"FIN410","title":"Financial Inst & Markets"},
{"id":"MEDT283","title":"Medical Technology Clinicals"},
{"id":"MEDT495","title":"Clinical Chemistry"},
{"id":"MGMT400","title":"Compensation Management"},
{"id":"EDHL308","title":"Lang for D&HH & Eng Lang Lrnrs"},
{"id":"EDHL360","title":"Gen Meth for Ed of Deaf & HHP"},
{"id":"EDHL415","title":"ASL Pedagogy"},
{"id":"RGPL424","title":"Technical Issues in GIS"},
{"id":"RGPL426","title":"Environmental Land Use Planng"},
{"id":"RGPL462","title":"Planning Policy, Implem & Admn"},
{"id":"RGPL470","title":"Housing & Community Dev Policy"},
{"id":"RLST100","title":"Intro to Religion"},
{"id":"RLST110","title":"World Religions"},
{"id":"RLST281","title":"Special Topic"},
{"id":"RLST311","title":"Eastern Philosophy"},
{"id":"RLST329","title":"Philosophy of Religion"},
{"id":"DVST150","title":"Intro to Higher Education"},
{"id":"ECED221","title":"Literature/Yng Child & Adolesc"},
{"id":"ECED250","title":"Language Development"},
{"id":"ECED280","title":"Maximizing Learning: PreK-Gr4"},
{"id":"ECON122","title":"Principles of Microeconomics"},
{"id":"ECON223","title":"Economics of Crime"},
{"id":"ECON325","title":"Monetary Economics I"},
{"id":"MKTG440","title":"Direct Marketing"},
{"id":"MKTG441","title":"Export Marketing"},
{"id":"MKTG444","title":"Green Marketing"},
{"id":"MLSC191","title":"Army ROTC Physical Fit Dev I"},
{"id":"MLSC192","title":"Army ROTC Physical Fit Dev II"},
{"id":"MLSC305","title":"Leadership & Modern Learning"},
{"id":"MUHI102","title":"Music & Literature Survey"},
{"id":"MUHI301","title":"Music History I"},
{"id":"MUHI420","title":"Music since 1900"},
{"id":"EDUC421","title":"Student Teaching"},
{"id":"FSMR456","title":"Historic Costume"},
{"id":"GEOG104","title":"World Geography/Global Context"},
{"id":"GEOG109","title":"GIS & Systems for Energy Appl"},
{"id":"GEOG222","title":"Geography of National Parks"},
{"id":"GEOG232","title":"Urban Landscapes"},
{"id":"GEOG256","title":"Geog of East & Southeast Asia"},
{"id":"GEOG334","title":"Political Geography"},
{"id":"GEOG345","title":"Biogeography for Environ Mngrs"},
{"id":"GEOG424","title":"Technical Issues in GIS"},
{"id":"GEOG440","title":"Conservation: Environ Analysis"},
{"id":"THTR132","title":"Introduction to Acting"},
{"id":"THTR231","title":"Improvisation and Creativity"},
{"id":"THTR321","title":"Stage Lighting Design"},
{"id":"THTR371","title":"Musical Theater History"},
{"id":"THTR471","title":"Musical Theater Performance"},
{"id":"COMM451","title":"Multimedia News Production"},
{"id":"COSC110","title":"Problem Solving & Struct Prog"},
{"id":"CHSS142","title":"Intro Second Social Stu Educ"},
{"id":"CHSS342","title":"Social Studies Teaching Lab"},
{"id":"CJTR3400","title":"Criminal Justice Trng PW _____"},
{"id":"CKRY3000","title":"Fish, Shellfish Prep & Cookery"},
{"id":"CMDA1070","title":"Pharmacology"},
{"id":"CMDA1530","title":"Accounting I"},
{"id":"CMDA3090","title":"Clinical Lab II (20"},
{"id":"CMDA3500","title":"Practicum I"},
{"id":"CMGT1000","title":"Sanitation Management"},
{"id":"CMGT1100","title":"Product Identification"},
{"id":"SPAN132","title":"Spanish Hospitality II"},
{"id":"SPAN220","title":"Interm Spanish Conversation"},
{"id":"SPAN382","title":"Contemporary Spain"},
{"id":"SPAN384","title":"History of Spanish Art"},
{"id":"GEOS404","title":"Newfoundland Field Workshop"},
{"id":"GEOS405","title":"American Southwest Seminar"},
{"id":"GRMN201","title":"Intermediate German"},
{"id":"GRMN351","title":"Adv German Language I"},
{"id":"GRMN361","title":"Dev German Culture & Lit I"},
{"id":"GRMN410","title":"History of German Language"},
{"id":"HAWK100","title":"HAWKS 100"},
{"id":"NMDT430","title":"Radiatn Bio & Radiatn Protect"},
{"id":"NMTT316","title":"Characterization, Pkg, & Testi"},
{"id":"NURS213","title":"Fundamentals II Clinical"},
{"id":"NURS236","title":"Fundamentals II Theory"},
{"id":"NURS281","title":"Special Topic"},
{"id":"MATH111","title":"First-year Seminar"},
{"id":"MATH115","title":"Applied Math for Business"},
{"id":"MATH122","title":"Calculus II/Natural&Social Sci"},
{"id":"MATH125","title":"Calculus I/Physics, Chem, Math"},
{"id":"MATH217","title":"Probability & Statistics"},
{"id":"MATH225","title":"Calculus III/Phys,Chem,Math"},
{"id":"CRIM205","title":"Policing & the Community"},
{"id":"BIOL201","title":"Prin of Ecology & Evolution"},
{"id":"BIOL202","title":"Prin Cell & Molecular Biology"},
{"id":"BIOL203","title":"Prin of Genetics & Development"},
{"id":"BIOL405","title":"Biol of the Cell--Critic Think"},
{"id":"BIOL420","title":"Entomology Principle/Practice"},
{"id":"BIOL451","title":"Evolutionary Biology"},
{"id":"BIOL463","title":"Limnology"},
{"id":"BIOL471","title":"Dendrology of the Eastern U.S."},
{"id":"ALI1600","title":"Basic English Grammar"},
{"id":"ALI1900","title":"Eating in America"},
{"id":"ALI4300","title":"Intercultural Communication"},
{"id":"ANTH211","title":"Cultural Anthropology"},
{"id":"ANTH233","title":"Language & Culture"},
{"id":"ANTH273","title":"Culture Area: South East Asia"},
{"id":"BLAW481","title":"Special Topic"},
{"id":"BTED309","title":"Keyboarding for Educators"},
{"id":"INDS110","title":"Color and Light"},
{"id":"INDS240","title":"3-Dimensional Dgn for Int Dsgn"},
{"id":"INDS460","title":"Portfolio"},
{"id":"INDS464","title":"Commercial Design Studio I"},
{"id":"HOSP220","title":"Food Service Operations"},
{"id":"HOSP235","title":"Tourism Management"},
{"id":"HOSP310","title":"Prof Dev in the Hospitality In"},
{"id":"HOSP358","title":"Restaurant Equipment & Design"},
{"id":"HOSP372","title":"Housekeeping & Facilities Mgmt"},
{"id":"HOSP382","title":"International Tourism"},
{"id":"HOSP408","title":"Hospitality Strategic Mgmt"},
{"id":"HOSP413","title":"Restaurant Food Prod & Service"},
{"id":"HOSP481","title":"Special Topic"},
{"id":"KHSS209","title":"Motor Behavior"},
{"id":"KHSS214","title":"Tchg Health Fitness & Gymnasti"},
{"id":"KHSS242","title":"Emergency Health Care"},
{"id":"KHSS281","title":"Special Topic"},
{"id":"KHSS320","title":"Mgmt Facilit & Events in Sport"},
{"id":"KHSS330","title":"Assessment in Physical Educ"},
{"id":"KHSS413","title":"Phys Act & Aging Across Lifesp"},
{"id":"KHSS417","title":"Contemp Iss School & Comm Hlth"},
{"id":"KHSS446","title":"Therapeutic Modalities"},
{"id":"KHSS481","title":"Special Topic"},
{"id":"LAS481","title":"Special Topic"},
{"id":"NURS450","title":"Cognitive Appr-Clin Prob Solvg"},
{"id":"NURS455","title":"Health Care Informatics"},
{"id":"ARHI424","title":"Art of India & Southeast Asia"},
{"id":"ART352","title":"Intermed Ceramics: Mold Making"},
{"id":"ART425","title":"Critical Practice"},
{"id":"ART451","title":"Adv Woodworking: Funct & Form"},
{"id":"ART452","title":"Adv Ceramics"},
{"id":"ART453","title":"Adv Sculpture"},
{"id":"ART481","title":"Special Topic"},
{"id":"APMU154","title":"Private Harp II"},
{"id":"APMU158","title":"Private Cello II"},
{"id":"APMU160","title":"Private Flute II"},
{"id":"APMU162","title":"Private Oboe II"},
{"id":"APMU164","title":"Private Saxophone II"},
{"id":"APMU171","title":"Private Guitar II"},
{"id":"APMU208","title":"Private Cello III"},
{"id":"APMU218","title":"Private Euphonium III"},
{"id":"APMU219","title":"Private Tuba III"},
{"id":"APMU221","title":"Private Guitar III"},
{"id":"APMU260","title":"Private Flute IV"},
{"id":"HIST374","title":"Blind Pigs and Brothels"},
{"id":"HIST394","title":"Intro to Classical Archaeology"},
{"id":"HIST433","title":"China 1300-1800:Late Imper Age"},
{"id":"HIST493","title":"Internship"},
{"id":"HIST498","title":"Topics in Comparative History"},
{"id":"CEU2183","title":"AI 8-Motorcycle Accident Inves"},
{"id":"CEU4360","title":"New Choices: Intr Windows/Word"},
{"id":"CEU4378","title":"Strateg Mgmt for Qlty Cust Svc"},
{"id":"CEU4384","title":"Windows NT"},
{"id":"CEU4393","title":"Cmty Emerg Resp Team (CERT)"},
{"id":"CEU4411","title":"Advanced Microsoft Excel"},
{"id":"CEU4416","title":"Intro Microsoft Windows 2000"},
{"id":"CEU4418","title":"Intermediate Microsoft Excel"},
{"id":"CEU4420","title":"Learning Organization"},
{"id":"CEU4434","title":"New Ch: Intro to Windows/Word"},
{"id":"CEU4436","title":"Microsoft Excel XP-Level II"},
{"id":"CEU4440","title":"PCH: Mental Illness"},
{"id":"CEU4446","title":"MS Word-Styles&Long Documents"},
{"id":"CEU4449","title":"Intro to MS Access XP"},
{"id":"CEU4455","title":"MS Excel-Wat If Analysis Tools"},
{"id":"CRLG101","title":"Arabic I"},
{"id":"CRLG102","title":"Chinese I"},
{"id":"CRLG114","title":"Hebrew I"},
{"id":"CRLG152","title":"Chinese II"},
{"id":"CRLG162","title":"Russian II"},
{"id":"CRLG209","title":"Korean III"},
{"id":"CRLG258","title":"Japanese IV"},
{"id":"ACCT399","title":"Cooperative Education II"},
{"id":"ACCT401","title":"Adv Accounting"},
{"id":"ANTH333","title":"The Archaeology of Early China"},
{"id":"ANTH365","title":"Native North American Religion"},
{"id":"ANTH413","title":"Archaelogy of Coasts"},
{"id":"ANTH444","title":"Medical Anthropology"},
{"id":"ANTH460","title":"Ethnographic Field School"},
{"id":"ANTH486","title":"Historic Artifacts"},
{"id":"APMU113","title":"Private Bassoon I"},
{"id":"APMU126","title":"Piano Proficiency Level I"},
{"id":"APMU271","title":"Private Guitar IV"},
{"id":"APMU304","title":"Private Harp V"},
{"id":"APMU305","title":"Private Voice V"},
{"id":"APMU309","title":"Private String Bass V"},
{"id":"APMU313","title":"Private Bassoon V"},
{"id":"APMU315","title":"Private Trumpet V"},
{"id":"APMU325","title":"Composition III"},
{"id":"APMU356","title":"Private Violin VI"},
{"id":"APMU361","title":"Private Clarinet VI"},
{"id":"APMU369","title":"Private Tuba VI"},
{"id":"APMU406","title":"Private Violin VII"},
{"id":"APMU427","title":"Capstone Jury: Primary Instrum"},
{"id":"APMU467","title":"Private Trombone VIII"},
{"id":"ARED101","title":"Introduction to Art Education"},
{"id":"ARED215","title":"Issues in Art Education"},
{"id":"ARED321","title":"Art History Inquiry for K-12"},
{"id":"ARHI101","title":"Introduction to Art"},
{"id":"ARHI409","title":"Baroque & Rococo Art"},
{"id":"ARHI411","title":"20th Century European Art"},
{"id":"ASIA106","title":"Samurai & Gongfu Heroes"},
{"id":"ASIA481","title":"Special Topics"},
{"id":"BIOC412","title":"Advanced Biochemistry Laborato"},
{"id":"BIOL113","title":"Genetics in Modern Society"},
{"id":"BKNG2000","title":"Intermediate Baking"},
{"id":"CDFR425","title":"Adolescence: Risk & Resiliency"},
{"id":"CHEM231","title":"Organic Chemistry I"},
{"id":"CHEM460","title":"Chemistry & Phys of Materials"},
{"id":"COMM206","title":"Hist & Theory of Making Games"},
{"id":"COMM340","title":"Adv Communication Graphics"},
{"id":"COMM348","title":"Animation"},
{"id":"COMM390","title":"Practicum in Communications"},
{"id":"COMM406","title":"3D Game & Sim Dsgn & Dev"},
{"id":"COMM407","title":"Television Feature Production"},
{"id":"COSC319","title":"Software Engineering Concepts"},
{"id":"COSC430","title":"Intro to Systems Programming"},
{"id":"COSC432","title":"Intro to Operating Systems"},
{"id":"DANC281","title":"Special Topic"},
{"id":"DANC355","title":"Dance Production: Admin to Cre"},
{"id":"DANC481","title":"Special Topic"},
{"id":"DISB302","title":"Cusom Eploy: Serv Coordination"},
{"id":"DISB469","title":"Ed Emo/Behav/Lrng Dis/BrainInj"},
{"id":"DVST81","title":"Special Topic"},
{"id":"DVST91","title":"Dev Math, Arithmetic Operation"},
{"id":"ECON356","title":"Intro to Econometrics"},
{"id":"ECON365","title":"Economics of Tourism"},
{"id":"ECON383","title":"Urban/Regional Economics"},
{"id":"ECON493","title":"Internship"},
{"id":"ECSP112","title":"Growth & Dev: Typical & Atypic"},
{"id":"ECSP340","title":"Intro Classroom/Behavior Mgmt"},
{"id":"EDEX435","title":"Meth&Curriculum/Severe-Profnd"},
{"id":"EDSP477","title":"Assessment of Student Learning"},
{"id":"EDSP493","title":"Ed Psychology Honor Internship"},
{"id":"ELED257","title":"Pedagogy I"},
{"id":"ELR281","title":"Special Topic"},
{"id":"ELR482","title":"Independent Study"},
{"id":"ENGL323","title":"Tchg Lit & Rdng in Second Sch"},
{"id":"ENGL324","title":"Teaching & Evaluating Writing"},
{"id":"ENGL335","title":"Literary Nonfiction"},
{"id":"ENGL341","title":"Poetry"},
{"id":"ENGL342","title":"Short Fiction"},
{"id":"ENGL343","title":"Drama"},
{"id":"ENGL361","title":"Environmental Literature"},
{"id":"ENGL483","title":"English Honors Thesis"},
{"id":"ENVE498","title":"Environmental Engineering"},
{"id":"EOPT250","title":"High-Vacuum Technology"},
{"id":"EXSP4000","title":"Culinary Externship"},
{"id":"EXSP4400","title":"Culinary Dietetics Externship"},
{"id":"FCSE433","title":"Study Tour"},
{"id":"FDNT143","title":"Curr Issues in Nutr & Wellness"},
{"id":"FDNT151","title":"Foods Lab"},
{"id":"FNLG281","title":"Special Topic"},
{"id":"FNLG481","title":"Special Topic"},
{"id":"FRNC481","title":"Special Topic"},
{"id":"FSMR281","title":"Special Topic"},
{"id":"FSMR303","title":"Visual Merchandising"},
{"id":"GEOS106","title":"Exploring the Universe Lab"},
{"id":"GEOS150","title":"Geology of National Parks"},
{"id":"GEOS203","title":"Surficial Processes"},
{"id":"GEOS204","title":"Historical Geology"},
{"id":"GEOS302","title":"Structural Geology"},
{"id":"GEOS303","title":"Field Geology"},
{"id":"GEOS310","title":"Environmental Geology"},
{"id":"GEOS341","title":"Planetary Geology"},
{"id":"GEOS342","title":"Stellar Astronomy"},
{"id":"GEOS363","title":"Volcanology"},
{"id":"HIST218","title":"Right in Your Own Backyard"},
{"id":"HIST295","title":"Intro to Historical Studies"},
{"id":"HIST340","title":"Colonial America"},
{"id":"HIST348","title":"Top Secret Amer: NNatnl Sec St"},
{"id":"IFMG254","title":"Computer Tech Virtualization"},
{"id":"INDS482","title":"Independent Study"},
{"id":"JPRJ1100","title":"Bkng & Pastry Prof Portfolio 1"},
{"id":"JRNL120","title":"Journalistic Tech/Prof Writing"},
{"id":"JRNL223","title":"Photojournalism"},
{"id":"JRNL327","title":"Layout, Design & Production"},
{"id":"JRNL344","title":"Issues & Problems"},
{"id":"JRNL492","title":"Problem-Solving in PR"},
{"id":"KHSS142","title":"Found Health Phys Ed & Sport"},
{"id":"KHSS143","title":"Physical Well-Being"},
{"id":"KHSS146","title":"Foundations Exercise Science"},
{"id":"MATH342","title":"Adv Math for Applications"},
{"id":"MATH343","title":"Intro to Numerical Methods"},
{"id":"MATH363","title":"Math Statistics I"},
{"id":"MATH416","title":"Time Series Analysis"},
{"id":"MATH446","title":"Probabilistic Models Oper Rsch"},
{"id":"MATH450","title":"Topics in Applied Comp Math"},
{"id":"MATH459","title":"Technology in Elem/Mid Math"},
{"id":"MATH471","title":"Algebra for Elem/Middle Tchrs"},
{"id":"MGMT454","title":"International Competitiveness"},
{"id":"MKTG351","title":"Self-Marketing"},
{"id":"MUSC152","title":"Class Voice II"},
{"id":"MUSC159","title":"Class Brass I"},
{"id":"MUSC212","title":"Theory Skills IV"},
{"id":"MUSC313","title":"Instrumental Conducting"},
{"id":"MUSC403","title":"Practicum in String Pedagogy"},
{"id":"MUSC405","title":"Piano Pedagogy"},
{"id":"NURS331","title":"Care of Child & FamilyClinical"},
{"id":"NURS332","title":"Maternal-Neonatal Health"},
{"id":"NURS333","title":"Maternal-Neontal Hlth Clinical"},
{"id":"NURS433","title":"Psychiatric/Mental Hlth Clinic"},
{"id":"OLOC167","title":"St Abroad/U of Zagreb-Theater"},
{"id":"OLOC172","title":"St Abroad/Kyungpook National U"},
{"id":"OLOC207","title":"Visiting Student-Kutztown"},
{"id":"OLOC275","title":"IUP Culinary Repeat--no credit"},
{"id":"OLOC292","title":"Criminal Justice Collaborative"},
{"id":"PHIL325","title":"Early Modern European Phil"},
{"id":"PHIL421","title":"Theory of Knowledge"},
{"id":"PHIL450","title":"Philosophy of Law"},
{"id":"PHIL493","title":"Internship"},
{"id":"PLSC422","title":"International Law & Organiztns"},
{"id":"PLSC481","title":"Special Topic"},
{"id":"PSYC315","title":"Experimental Dev Psychology"},
{"id":"PSYC325","title":"Psychological Test & Measures"},
{"id":"PSYC342","title":"Human Cogn: Memory&Thinking"},
{"id":"PSYC345","title":"Intro to Human Cognition"},
{"id":"QBUS215","title":"Business Statistics"},
{"id":"RESP101","title":"Profession of Respiratory Care"},
{"id":"RESP330","title":"Cardiopulmonary Resuscitation"},
{"id":"RESP333","title":"Respiratory Care Clinical II"},
{"id":"RESP429","title":"Respiratory Care Leadership"},
{"id":"RLST378","title":"Hinduism"},
{"id":"RLST388","title":"Interpreting the Bible"},
{"id":"SAFE100","title":"The Science of Living Safely"},
{"id":"SAFE361","title":"Air & Water Polution"},
{"id":"SAFE430","title":"Recog-Eval&Control/Occ Hazz II"},
{"id":"SOC320","title":"Sociological Theory"},
{"id":"SOC341","title":"Sociology of Education"},
{"id":"SOC352","title":"Sociology of Religion"},
{"id":"SOC362","title":"Racial & Ethnic Minorities"},
{"id":"SOC410","title":"Men and Masculinities"},
{"id":"SOC448","title":"Social Welfare Policy"},
{"id":"SOC493","title":"Internship in Sociology"},
{"id":"SPLP111","title":"Intro Communication Disorders"},
{"id":"SPLP222","title":"Intro to Audiology"},
{"id":"SPLP251","title":"Anat&Physio/Spch & Swallowing"},
{"id":"SPLP406","title":"Clinical Mgmt of Articu & Lang"},
{"id":"SPLP408","title":"Organic Disorders"},
{"id":"SPLP410","title":"Observation Comm Disorders II"},
{"id":"VOED302","title":"Career Ed Career/Tech Classrm"},
{"id":"VOED404","title":"Research Career & Technical Ed"},
{"id":"PHYS151","title":"Medical Physics Lecture"},
{"id":"PHYS260","title":"Intro to Nanoscience and Techn"},
{"id":"PHYS490","title":"Solid State Physics"},
{"id":"PLSC348","title":"Top Sec Amer: Natnl Sec St"},
{"id":"PLSC359","title":"Const Law & Civil Liberties"},
{"id":"PSYC374","title":"Stress & Coping"},
{"id":"PSYC376","title":"Psychology of Health Behavior"},
{"id":"OLOC101","title":"Faculty Led St Abr_______(_cr)"},
{"id":"OLOC113","title":"St Abroad/Sheffield Hallam U"},
{"id":"OLOC116","title":"St Abroad/U of Worcester"},
{"id":"OLOC131","title":"St Abroad/Helsinki"},
{"id":"OLOC134","title":"St Abroad/U Valparaiso Chile"},
{"id":"OLOC140","title":"St Abroad/Opole University"},
{"id":"ENGL203","title":"Intro to Language Studies"},
{"id":"ENGL208","title":"Introduction to Film Studies"},
{"id":"FDNT250","title":"Quantity Foods/Hlthcare & Schl"},
{"id":"FDNT355","title":"Medical Nutrition Therapy I"},
{"id":"FDNT362","title":"Experimental Foods"},
{"id":"FDNT364","title":"Methods of Tchg Food & Nutritn"},
{"id":"FDNT422","title":"Public Hlth Nutrition & Epidem"},
{"id":"FDNT463","title":"Nutrition Counseling"},
{"id":"FDNT493","title":"Internship"},
{"id":"FIN281","title":"Special Topic"},
{"id":"FIN365","title":"Stu Mgd Invst Port - Valuation"},
{"id":"FIN401","title":"Energy Finance"},
{"id":"FIN420","title":"Investment Analysis"},
{"id":"FIN425","title":"Financial Derivatives"},
{"id":"MEDT285","title":"IMAG/Nuclear Med Tech Clinical"},
{"id":"MEDT497","title":"Clinical Immunohematology"},
{"id":"MEDT498","title":"Clinical Immunology/Serology"},
{"id":"EDHL215","title":"Intermed American Sign Lang"},
{"id":"EDHL417","title":"Adv American Sign Language"},
{"id":"RGPL232","title":"Urban Landscapes"},
{"id":"RGPL341","title":"Climatology"},
{"id":"RGPL343","title":"Fresh Water Resources"},
{"id":"RGPL468","title":"Planning Theory"},
{"id":"RLST164","title":"Biblical Hebrew II"},
{"id":"RLST300","title":"Methodologies of Religious St"},
{"id":"DVST110","title":"Intro Critical Rdng & Thinking"},
{"id":"ECED112","title":"Childhood Development Birth Ag"},
{"id":"ECED220","title":"Diff Literacy Instr Child Lit"},
{"id":"ECED451","title":"Lit for Dev Reader Grades 2-4"},
{"id":"ECON143","title":"Financial Wellness"},
{"id":"MKTG482","title":"Independent Study"},
{"id":"MLSC102","title":"Fund of Military Science"},
{"id":"MUHI101","title":"Introduction to Music"},
{"id":"EDUC431","title":"Student Teaching/Fam&Cons Sci"},
{"id":"EDUC440","title":"Prof Sem: Tchr as Leadr & Rsch"},
{"id":"EDUC456","title":"Tchg Math in Secondary Sch"},
{"id":"FSMR493","title":"Internship"},
{"id":"GEOG103","title":"Global Cities: Issues Plan/Dev"},
{"id":"GEOG230","title":"Cultural Geography"},
{"id":"GEOG261","title":"Geography of Wine"},
{"id":"GEOG412","title":"Research Seminar"},
{"id":"GEOG418","title":"GIS Crime Map & Soc Sci Analys"},
{"id":"GEOG419","title":"GIS Environmental Applic"},
{"id":"GEOG432","title":"Geography of Crime"},
{"id":"GEOG435","title":"Geography of Energy"},
{"id":"GEOG460","title":"Unmanned Aerial Sys Sci & Appl"},
{"id":"SUST481","title":"Special Topics"},
{"id":"THTR122","title":"Costume Technology"},
{"id":"THTR140","title":"Foundations of Performance"},
{"id":"THTR310","title":"Theater Criticism"},
{"id":"THTR341","title":"Acting Styles"},
{"id":"THTR361","title":"Performance of Caring"},
{"id":"THTR362","title":"Performance for Social Change"},
{"id":"THTR372","title":"Musical Theater Auditioning"},
{"id":"THTR481","title":"Special Topic"},
{"id":"THTR483","title":"Honors Thesis"},
{"id":"COMM460","title":"Emerging Trends in Comm Tech"},
{"id":"COMP1000","title":"Introduction to Computers"},
{"id":"CJTR1001","title":"Criminal Justice Trng FT-___"},
{"id":"CKRY1000","title":"Farinaceous Prod & Veg Cookery"},
{"id":"CKRY3100","title":"American Cuisine"},
{"id":"CMDA2030","title":"Spreadsheet Applications"},
{"id":"CMDA2050","title":"Medical Billing & Coding I"},
{"id":"SPAN131","title":"Spanish Hospitality I"},
{"id":"SPAN354","title":"Commercial Spanish"},
{"id":"SPAN383","title":"Geography & History of Spain"},
{"id":"GEOS402","title":"Northern Rockies Field Wrkshop"},
{"id":"GEOS475","title":"Data Interpretation"},
{"id":"GEOS481","title":"Special Topic"},
{"id":"GEOS492","title":"Soils and Soil Geochemistry"},
{"id":"GMGR1000","title":"Introduction to Garde Manger"},
{"id":"GRMN221","title":"Conversation III"},
{"id":"GRMN281","title":"Special Topic"},
{"id":"GRMN352","title":"Adv German Language II"},
{"id":"GRMN367","title":"19th-Century German Literature"},
{"id":"GRMN370","title":"Age of Goethe I"},
{"id":"MUSC420","title":"Professional Practices"},
{"id":"NMDT429","title":"Nuclear Medicine Instrumentatn"},
{"id":"NMDT433","title":"Intro Tomographic Imaging"},
{"id":"NURS214","title":"Health Assessment"},
{"id":"LIBR281","title":"Special Topic"},
{"id":"MATH126","title":"Calculus II/Physics,Chem,Math"},
{"id":"MATH171","title":"Intro Linear Algebra"},
{"id":"MATH241","title":"Differential Equations"},
{"id":"CRIM102","title":"Survey of Criminology"},
{"id":"CRIM210","title":"Criminal Law"},
{"id":"CRIM394","title":"Crime & Delinquency Prevention"},
{"id":"CRIM400","title":"Theoretical Criminology"},
{"id":"BIOL241","title":"Intro Medical Microbiology"},
{"id":"BIOL261","title":"Ornithology"},
{"id":"BIOL271","title":"Evolution"},
{"id":"BIOL301","title":"Fundamentals of Epidemiology"},
{"id":"BIOL364","title":"Immunology"},
{"id":"BIOL402","title":"Advanced Human Anatomy"},
{"id":"BIOL409","title":"Pharmacology Principles & Appl"},
{"id":"BIOL431","title":"Ichthyology"},
{"id":"BIOL474","title":"Spring Flora of the NE U.S."},
{"id":"BIOL476","title":"Parasitology"},
{"id":"ALI2000","title":"ACE Tutoring"},
{"id":"ALI2400","title":"Listening for Comprehension"},
{"id":"ALI2800","title":"Intro to English Literacy"},
{"id":"ALI3800","title":"Using English with Computers"},
{"id":"ANTH222","title":"Biological Anthropology"},
{"id":"ANTH271","title":"Cultural Area: Africa"},
{"id":"ANTH315","title":"North American Archaeology"},
{"id":"BTED101","title":"Computer Literacy"},
{"id":"INDS105","title":"Intro to Interior Design"},
{"id":"INDS323","title":"Building Systems II Mechanical"},
{"id":"INDS481","title":"Special Topic"},
{"id":"HNRC483","title":"Honors Thesis /H/"},
{"id":"HOSP330","title":"Applic of Food Product & Serv"},
{"id":"HOSP352","title":"Destination Management"},
{"id":"HOSP367","title":"Festival Management"},
{"id":"HOSP393","title":"Explore Hospitality Field Exp"},
{"id":"HOSP402","title":"Beverage Management"},
{"id":"HOSP404","title":"Brew Pub & Brewery Operations"},
{"id":"KHSS252","title":"Intro to Driving Task"},
{"id":"KHSS270","title":"Sport Communication"},
{"id":"KHSS286","title":"Personal Training Practicum"},
{"id":"KHSS325","title":"School & Community Health"},
{"id":"KHSS343","title":"Physiology of Exercise"},
{"id":"KHSS344","title":"Adapted Phys Activity & Sport"},
{"id":"KHSS354","title":"Appl of Driver Ed Instr Modes"},
{"id":"KHSS377","title":"Athl Trng Clinical Practicm II"},
{"id":"KHSS412","title":"Phys Activity & Stress Mgmt"},
{"id":"KHSS437","title":"Coaching Disability Sport"},
{"id":"KHSS442","title":"Sr Sem: Hlth&PhysEd, Recreat I"},
{"id":"KHSS477","title":"Athl Trng Clinic Practicum IV"},
{"id":"KHSS492","title":"Health & Fitness Instruction"},
{"id":"KORE102","title":"Elementary Korean II"},
{"id":"LAS350","title":"Mayas: Culture, Lit, & Numbers"},
{"id":"ARHI493","title":"Internship"},
{"id":"ART113","title":"3-Dimension Design"},
{"id":"ART213","title":"Woodworking: Funct & Form"},
{"id":"ART214","title":"Ceramics"},
{"id":"ART221","title":"Principles of Illustration"},
{"id":"ART482","title":"Independent Study"},
{"id":"APMU157","title":"Private Viola II"},
{"id":"APMU167","title":"Private Trombone II"},
{"id":"APMU257","title":"Private Viola IV"},
{"id":"HIST351","title":"Hist of Latin Amer,National Pd"},
{"id":"HIST355","title":"African Hist I: Antiquity-1600"},
{"id":"HIST436","title":"Japan 1500-1800:Early Mod Japn"},
{"id":"HIST480","title":"Senior Seminar"},
{"id":"HIST487","title":"Honors Colloq in History /H/"},
{"id":"CDFR493","title":"Internship"},
{"id":"CEU2179","title":"AI-6 Lamp Examination"},
{"id":"CEU4326","title":"PCH: Budg & Fin Recordkeeping"},
{"id":"CEU4365","title":"Orienting and Training Skills"},
{"id":"CEU4368","title":"Managing Performance Skills"},
{"id":"CEU4387","title":"Beginning Microsoft Access"},
{"id":"CEU4388","title":"Beginning Microsoft PowerPoint"},
{"id":"CEU4395","title":"Intro to Microsoft Access 2000"},
{"id":"CEU4396","title":"PCH: Mental Illness"},
{"id":"CEU4400","title":"Intro to Microsoft Word 2000"},
{"id":"CEU4408","title":"Intermediate MS PowerPoint"},
{"id":"CEU4410","title":"Advanced Microsoft Access"},
{"id":"CEU4421","title":"Intro to MS FrontPage 2000"},
{"id":"CEU4431","title":"Intermediate Microsoft Excel"},
{"id":"CEU4435","title":"Intro to Microsoft Excel XP"},
{"id":"CEU4438","title":"PCH: First Aid"},
{"id":"CEU4450","title":"MS PowerPoint-Electronic Pres"},
{"id":"CRLG109","title":"Korean I"},
{"id":"CRLG112","title":"Russian I"},
{"id":"CRLG151","title":"Arabic II"},
{"id":"CRLG212","title":"Russian III"},
{"id":"CRLG214","title":"Hebrew III"},
{"id":"CRLG251","title":"Arabic IV"},
{"id":"CRLG263","title":"Swedish IV"},
{"id":"ACCT202","title":"Accounting Principles II"},
{"id":"ACCT412","title":"Adv Cost Accounting"},
{"id":"ACCT475","title":"Accounting/Oil & Gas Industry"},
{"id":"ACCT493","title":"Internship in Accounting"},
{"id":"ANTH340","title":"Anthropology of Aging"},
{"id":"ANTH457","title":"Applied Anthropology"},
{"id":"ANTH480","title":"Anthropology Seminar"},
{"id":"ANTH482","title":"Independent Study"},
{"id":"ANTH491","title":"Zooarcheology"},
{"id":"ANTH493","title":"Internship in Anthropology"},
{"id":"ANTH497","title":"Forensic Anthropology"},
{"id":"APMU301","title":"Private Piano V"},
{"id":"APMU316","title":"Private French Horn V"},
{"id":"APMU352","title":"Private Organ VI"},
{"id":"APMU367","title":"Private Trombone VI"},
{"id":"APMU370","title":"Private Percussion VI"},
{"id":"APMU408","title":"Private Cello VII"},
{"id":"APMU411","title":"Private Clarinet VII"},
{"id":"APMU417","title":"Private Trombone VII"},
{"id":"APMU418","title":"Private Euphonium VII"},
{"id":"APMU466","title":"Private French Horn VIII"},
{"id":"APMU470","title":"Private Percussion VIII"},
{"id":"ARED320","title":"Art Critic & Aesthetics K-12"},
{"id":"ARED424","title":"Special Populations in Art Ed"},
{"id":"ARHI408","title":"Italian Renaissance"},
{"id":"BCOM342","title":"Intercultural Business Comm"},
{"id":"BIOC481","title":"Special Topic"},
{"id":"BIOL115","title":"Biotic Diversity of North Amer"},
{"id":"BIOL118","title":"History of Pain"},
{"id":"CDFR281","title":"Special Topics"},
{"id":"CHEM102","title":"College Chemistry II"},
{"id":"CHEM325","title":"Analytical Chemistry I"},
{"id":"CHEM341","title":"Physical Chemistry I"},
{"id":"CHEM421","title":"Adv Instrumental Analysis"},
{"id":"COMM345","title":"Television Criticism"},
{"id":"COMM349","title":"Radio Production"},
{"id":"COMM360","title":"Digital Sports Production"},
{"id":"COMM408","title":"Media Field Studies"},
{"id":"COSC355","title":"Computer Graphics"},
{"id":"COSC429","title":"Digital Forensics"},
{"id":"COSC454","title":"Information Assurance Administ"},
{"id":"CRIM493","title":"Internship"},
{"id":"CULN3082","title":"Culinary Independ Study/Summer"},
{"id":"DANC270","title":"Beginning Ballroom & Tap Dance"},
{"id":"DISB403","title":"Cusom Employ: Assess & Job Dev"},
{"id":"DISB404","title":"Custom Employ: Sys Instr & Fad"},
{"id":"DISB493","title":"Internship"},
{"id":"DVLP2000","title":"Employment Stategies"},
{"id":"ECON335","title":"Public Finance"},
{"id":"ECON345","title":"International Trade"},
{"id":"ECON456","title":"Advanced Econometrics"},
{"id":"EDEX103","title":"Special Education Technology"},
{"id":"EDEX301","title":"Inclusive Secondary Setting"},
{"id":"EDEX321","title":"Tchg Lang Arts to Pers w/Dis"},
{"id":"EDEX340","title":"Behavior Management in Spec Ed"},
{"id":"EDEX378","title":"Ed/Mtnal, Phys, Dev & Mult Dis"},
{"id":"EDEX415","title":"Preschool Ed for Child w/Dis"},
{"id":"EDSP373","title":"Psychology of Adolescent Ed"},
{"id":"ELR426","title":"Case Studies/Employ Relations"},
{"id":"ELR481","title":"Special Topic"},
{"id":"ENGL327","title":"Writing Creative Nonfiction"},
{"id":"ENGL328","title":"Introduction to Linguistics"},
{"id":"ENGL350","title":"Gender & Sexual Orient in Lit"},
{"id":"ENGL386","title":"Regional Literature in English"},
{"id":"ENGL436","title":"Major American Authors"},
{"id":"ENGL480","title":"Seminar: English & Amer Litera"},
{"id":"ENVE461","title":"Water and Wastewater Treatment"},
{"id":"ENVE471","title":"Solid/Hazard Waste Mangement"},
{"id":"EOPT150","title":"Fund/Photonics & Laser Safety"},
{"id":"FDNT145","title":"Intro to Nutrition"},
{"id":"FRNC101","title":"Basic French I"},
{"id":"FRNC301","title":"Women in French Novel"},
{"id":"FRNC431","title":"Advance French Conversation"},
{"id":"FRNC432","title":"French Phonetics & Phonology"},
{"id":"FSMR112","title":"Fund of Clothing Construction"},
{"id":"FSMR262","title":"Fashion Forecasting"},
{"id":"GEOS370","title":"Oceanography"},
{"id":"HIST202","title":"West Civilization since 1600"},
{"id":"HIST206","title":"History of East Asia"},
{"id":"HIST207","title":"History of the Middle East"},
{"id":"HIST231","title":"History of Jerusalem"},
{"id":"HIST304","title":"Medieval Europe: 1000-1300"},
{"id":"HIST305","title":"Renaissance & Reformation"},
{"id":"HIST306","title":"Early Modern Europe"},
{"id":"HIST313","title":"Europe Since 1945"},
{"id":"HIST345","title":"Amer in War & Depressn 1914-45"},
{"id":"IFMG101","title":"Computer Literacy"},
{"id":"IFMG360","title":"Information Storage and Mgmt"},
{"id":"JRNL466","title":"Community Journalism"},
{"id":"JRNL490","title":"Public Relations Writing"},
{"id":"MATH320","title":"Math Early Childhood"},
{"id":"MATH427","title":"Intro to Topology"},
{"id":"MATH430","title":"Seminar in Tchg Sec Sch Math"},
{"id":"MATH445","title":"Determ Models Operations Rsrch"},
{"id":"MATH456","title":"Geometry Elem/Middle Level"},
{"id":"MATH477","title":"Abstract Algebra II"},
{"id":"MGMT440","title":"Intro to Energy Management"},
{"id":"MGMT472","title":"Organizationl Entrepreneurship"},
{"id":"MIDL408","title":"Literacy in the Content Areas"},
{"id":"MKTG431","title":"Business-to-Business Marketing"},
{"id":"MUSC112","title":"Theory Skills II"},
{"id":"MUSC121","title":"Chamber Singers"},
{"id":"MUSC128","title":"Opera/Musical Product Ensemble"},
{"id":"MUSC136","title":"Advanced Jazz Ensemble"},
{"id":"MUSC180","title":"Tech for General Muscianship"},
{"id":"NURS412","title":"Nursing Management"},
{"id":"PHIL130","title":"Intro to Biomedical Ethics"},
{"id":"PHIL360","title":"Philosophy of Mind"},
{"id":"PHIL482","title":"Independent Study"},
{"id":"PLSC376","title":"Public Sector Bdgtg & Fin Mgmt"},
{"id":"PNAF482","title":"Independent Study"},
{"id":"PSYC225","title":"Abnormal Psychology"},
{"id":"PSYC290","title":"Rsrch Design & Analysis I"},
{"id":"PSYC311","title":"Child Psychology"},
{"id":"PSYC312","title":"Adult Development & Aging"},
{"id":"PSYC313","title":"Non-normative Dev in Adulthood"},
{"id":"PSYC314","title":"Child & Adoles Psychopathology"},
{"id":"QBUS450","title":"Data Science for Business"},
{"id":"RLST401","title":"Topic/Abrahamic Traditions"},
{"id":"RLST402","title":"Topics in Asian Religions"},
{"id":"RLST493","title":"Internship"},
{"id":"SAFE101","title":"Intro Occupat Safety & Hlth"},
{"id":"SAFE310","title":"Env Safety & Hlth Reg & Sustai"},
{"id":"SOC340","title":"Sociology of Industry"},
{"id":"SOC361","title":"Social Stratification"},
{"id":"SPAN421","title":"Modern Hispanic Short Story"},
{"id":"SPAN481","title":"Special Topic"},
{"id":"SPAN483","title":"Spanish Honors Thesis"},
{"id":"SPLP312","title":"Adv Audiology & Hearing Disord"},
{"id":"SPLP420","title":"Undergraduate Clinic"},
{"id":"THTR489","title":"Design/Technology/Mgmt Studio"},
{"id":"VOED102","title":"Instr Plan Career & Tech Class"},
{"id":"VOED201","title":"Stu w/Spec Needs Career & Tech"},
{"id":"WGS200","title":"Intro Women's & Gender Studies"},
{"id":"WGS281","title":"Special Topic"},
{"id":"PHYS101","title":"Energy & Our Environment"},
{"id":"PHYS121","title":"Physics I Lab"},
{"id":"PHYS122","title":"Physics II Lab"},
{"id":"PHYS161","title":"Medical Physics Lab"},
{"id":"PHYS223","title":"Mechanics II"},
{"id":"PHYS441","title":"Classical Mechanics"},
{"id":"PLSC280","title":"Comp Govt I:West Political Sys"},
{"id":"PLSC350","title":"The Presidency"},
{"id":"PSYC350","title":"Physiological Psychology"},
{"id":"PSYC378","title":"Psychology of Death & Dying"},
{"id":"PSYC388","title":"Forensic Psychology"},
{"id":"PSYC411","title":"Psychology of Women"},
{"id":"PSYC435","title":"Adv Topics--Social Psychology"},
{"id":"OLOC107","title":"St Abroad/U of Greenwich"},
{"id":"OLOC117","title":"St Abroad/U of Zagreb - Art"},
{"id":"OLOC120","title":"National Student Exchange A"},
{"id":"OLOC123","title":"St Abroad/U of Ljubljana-Music"},
{"id":"OLOC132","title":"St Abroad/Leeds Beckett UK"},
{"id":"ENGL265","title":"Law and Literature"},
{"id":"ENGL313","title":"Rhetorical Trends & Traditions"},
{"id":"ENGL319","title":"American Lit 1940-Present"},
{"id":"FDNT213","title":"Life Cycle Nutrition"},
{"id":"FDNT402","title":"Community Nutrition"},
{"id":"FDNT445","title":"Advanced Sports Nutrition"},
{"id":"FIN315","title":"Fina Analys Using Elect Sprdsh"},
{"id":"FIN360","title":"Insurance & Risk Management"},
{"id":"MEDT496","title":"Clinical Hematology/Coagulatn"},
{"id":"RGPL350","title":"Intro to Community Planning"},
{"id":"RGPL412","title":"Community Planning Practicum"},
{"id":"RGPL454","title":"Planning Design Studio II"},
{"id":"RGPL481","title":"Special Topic"},
{"id":"RLST114","title":"Biblical Hebrew I"},
{"id":"RLST120","title":"Comparative Religious Ethics"},
{"id":"RLST245","title":"Women and Religion"},
{"id":"DVST170","title":"Career Exploration"},
{"id":"ECED180","title":"Orientation to ECED/P-6 Progrm"},
{"id":"ECED200","title":"Intro to Early Childhood Ed"},
{"id":"ECON239","title":"Economics of Sports"},
{"id":"MLSC392","title":"ARMY ROTC Physical Fit Dev VI"},
{"id":"MLSC492","title":"Army ROTC Phys Fit Dev VIII"},
{"id":"MUHI324","title":"The Classical Era"},
{"id":"MUHI421","title":"Topics in American Music"},
{"id":"MUHI425","title":"Nineteenth Century Music"},
{"id":"FSMR480","title":"Seminar: Fashion Merchandising"},
{"id":"FSMR482","title":"Independent Study"},
{"id":"GEOG314","title":"Map&Photograph Interpretation"},
{"id":"GEOG341","title":"Climatology"},
{"id":"GEOG411","title":"History of Geography"},
{"id":"GEOG415","title":"Remote Sensing"},
{"id":"GEOG425","title":"GPS Concepts & Techniques"},
{"id":"GEOG444","title":"Energy Develop & Compliance"},
{"id":"GEOG475","title":"Spatial Analysis Techniques"},
{"id":"THTR161","title":"Intro to Theatre: Teamwork"},
{"id":"THTR223","title":"Makeup for the Stage"},
{"id":"THTR281","title":"Special Topic"},
{"id":"THTR331","title":"Improvisation & Creativity"},
{"id":"THTR342","title":"Acting Shakespeare"},
{"id":"THTR486","title":"Practicum in Production"},
{"id":"COSC210","title":"Object Orient & GUI Program"},
{"id":"COSC281","title":"Special Topic"},
{"id":"CHIN101","title":"Elementary Chinese I"},
{"id":"CHIN481","title":"Special Topic"},
{"id":"CHIN482","title":"Independent Study"},
{"id":"CMDA1010","title":"Records Management"},
{"id":"CMDA1050","title":"Document Formatting"},
{"id":"CMDA1510","title":"Personal Finance"},
{"id":"CMDA1520","title":"Medical Ethics"},
{"id":"CMDA2010","title":"English III"},
{"id":"CMDA3010","title":"Clinical Lab I"},
{"id":"CMDA3501","title":"Practicum II"},
{"id":"CMGT1200","title":"Hospitality Mathematics"},
{"id":"CMGT2100","title":"Wine Apprec & Beverage Mgmt"},
{"id":"SPAN260","title":"Intro to Hispanic Literature"},
{"id":"SPAN342","title":"Span Cultrs 19th Cent - Pres"},
{"id":"GEOS401","title":"Northern Rockies Seminar"},
{"id":"GRMN362","title":"Dev German Culture & Lit II"},
{"id":"HIST196","title":"Explorations in US History"},
{"id":"HIST200","title":"Intro to History"},
{"id":"MUSC417","title":"Orchestration"},
{"id":"MUSC481","title":"Special Topic"},
{"id":"NMDT427","title":"Nuclear Scintigraphy"},
{"id":"LIBR151","title":"Intro to Information Literacy"},
{"id":"LIBR201","title":"Internet & Multimedia"},
{"id":"LIBR251","title":"Info Access in the Digital Age"},
{"id":"LLRN200","title":"Co-curricular Activity Lab II"},
{"id":"CRIM235","title":"Survey of Juvenile Just & Law"},
{"id":"CRIM321","title":"Cybersecurity & Loss Preventn"},
{"id":"CRIM344","title":"Terrorism"},
{"id":"CRIM401","title":"Contemporary Issues in Crim"},
{"id":"CRIM410","title":"Race, Ethnic, Soc Stru & Crime"},
{"id":"CRIM450","title":"Women and Crime"},
{"id":"BIOL411","title":"Forensic Biol Lab Operations"},
{"id":"BIOL456","title":"Ecological Toxicology"},
{"id":"BIOL479","title":"Neurobiology of Addiction"},
{"id":"BIOL482","title":"Independent Study"},
{"id":"ALI1000","title":"ACE Tutoring"},
{"id":"ALI2100","title":"Write from the Start"},
{"id":"ALI2300","title":"Speaking Out II"},
{"id":"ALI3200","title":"Reading Stories"},
{"id":"ALI3400","title":"Interactive Listening"},
{"id":"ALI4000","title":"ACE Tutoring"},
{"id":"ALI4500","title":"Explorations of Culture II"},
{"id":"ANTH240","title":"Introduction to Global Health"},
{"id":"BTED293","title":"Pract: Comp & Info Tech (CIT)"},
{"id":"INDS313","title":"Bldg Sys I Material & Finishes"},
{"id":"HNRC102","title":"Honors Core II /H/"},
{"id":"HOSP265","title":"Hospitality Cost Management"},
{"id":"HOSP280","title":"Special Event Management"},
{"id":"HOSP281","title":"Special Topic"},
{"id":"HOSP299","title":"Cooperative Education I"},
{"id":"HOSP403","title":"Wine & Wine Service"},
{"id":"HOSP450","title":"Training, Devlp, & Supervision"},
{"id":"KHSS216","title":"Instruc of Fund Movement Skill"},
{"id":"KHSS221","title":"Human Structure & Function"},
{"id":"KHSS351","title":"Manage Budgets & Tech in Sport"},
{"id":"KHSS376","title":"Athl Trng Clinical Practicum I"},
{"id":"KHSS410","title":"Exercise Prescription"},
{"id":"KHSS441","title":"Psychosocial Impl Hlth Phys Ed"},
{"id":"KHSS445","title":"Business Practices in Sport"},
{"id":"KHSS472","title":"Epidemiology of Phys Activity"},
{"id":"ARHI420","title":"Art Museums, Librar & Vis Resc"},
{"id":"ARHI481","title":"Special Topic"},
{"id":"ART313","title":"Intermed Ceramics: Wheel Throw"},
{"id":"ART356","title":"Intermediate Graphic Design II"},
{"id":"ART423","title":"Drawing: Materials & Process"},
{"id":"ART457","title":"Adv Print Media"},
{"id":"APMU201","title":"Private Piano III"},
{"id":"APMU206","title":"Private Violin III"},
{"id":"APMU211","title":"Private Clarinet III"},
{"id":"APMU217","title":"Private Trombone III"},
{"id":"APMU252","title":"Private Organ IV"},
{"id":"APMU256","title":"Private Violin IV"},
{"id":"APMU258","title":"Private Cello IV"},
{"id":"HIST366","title":"African-American Women"},
{"id":"HIST395","title":"Intro to Historical Methods"},
{"id":"HIST497","title":"Topics in Non-Western History"},
{"id":"CEU2180","title":"AI-7 Commercial Veh Accidt Inv"},
{"id":"CEU4222","title":"Intro to Computers: Beginners"},
{"id":"CEU4350","title":"Intro to Microsoft Access 2000"},
{"id":"CEU4381","title":"PennDOT Learning Organization"},
{"id":"CEU4382","title":"Internet Explorer"},
{"id":"CEU4392","title":"ICS/EOC Intervace"},
{"id":"CEU4394","title":"PCH: Gerontology"},
{"id":"CEU4397","title":"Intro to Microsoft Excel 2000"},
{"id":"CEU4419","title":"Microsoft Access"},
{"id":"CEU4423","title":"Microsoft Access 2000-Level II"},
{"id":"CEU4424","title":"Intro to Comp for Beginners"},
{"id":"CEU4425","title":"Microsoft Excel 2000-Level II"},
{"id":"CEU4443","title":"MS Word-Collaboration Tools"},
{"id":"CRLG215","title":"Swahili III"},
{"id":"ACCT200","title":"Foundations of Accounting"},
{"id":"ACCT431","title":"Auditing"},
{"id":"ACCT482","title":"Independent Study"},
{"id":"ANTH420","title":"Environmental Anthropology"},
{"id":"ANTH425","title":"Archaeological Theory & Res Ds"},
{"id":"ANTH485","title":"Anthropology Study Odyssey"},
{"id":"APMU103","title":"Private Harpsichord I"},
{"id":"APMU114","title":"Private Saxophone I"},
{"id":"APMU118","title":"Private Euphonium I"},
{"id":"APMU151","title":"Private Piano II"},
{"id":"APMU265","title":"Private Trumpet IV"},
{"id":"APMU268","title":"Private Euphonium IV"},
{"id":"APMU319","title":"Private Tuba V"},
{"id":"APMU321","title":"Private Guitar V"},
{"id":"APMU357","title":"Private Viola VI"},
{"id":"APMU412","title":"Private Oboe VII"},
{"id":"APMU425","title":"Composition IV"},
{"id":"APMU464","title":"Private Saxophone VIII"},
{"id":"ARED322","title":"Studio Inquiry for K-12 Progrm"},
{"id":"ARHI417","title":"Byzantine Art"},
{"id":"BCOM221","title":"Business Technical Writing"},
{"id":"BCOM321","title":"Business & Interpersonal Comm"},
{"id":"BIOL103","title":"Life on Earth"},
{"id":"BIOL150","title":"Human Anatomy"},
{"id":"BKNG1000","title":"Introduction to Baking"},
{"id":"BKPS1001","title":"Artisan Bread"},
{"id":"BKPS2007","title":"Artisan Choc & Sugar Show Piec"},
{"id":"BLAW235","title":"Legal Environment of Business"},
{"id":"BLAW440","title":"Business Negotiations"},
{"id":"CDFR321","title":"Presch Ed: Play & Dev App Prac"},
{"id":"CHEM255","title":"Biochemistry/Health Sciences"},
{"id":"CHEM281","title":"Special Topic"},
{"id":"CHEM411","title":"Adv Inorganic Chemistry"},
{"id":"CHEM435","title":"Current Topics-Organic Chem"},
{"id":"CHEM490","title":"Chemistry Seminar III"},
{"id":"COMM101","title":"Intro Communic, Media, & Cult"},
{"id":"COMM205","title":"Mkg Presentations w/Media"},
{"id":"COMM230","title":"Global Media & Communication"},
{"id":"COMM270","title":"Sport Communication"},
{"id":"COMM306","title":"2D Digital Game Development"},
{"id":"COMM325","title":"Gender in Media"},
{"id":"COMM354","title":"Broadcast Regulation"},
{"id":"COMM380","title":"Hist of African-Amer in Film"},
{"id":"COMM395","title":"Career Plan in Comm Media"},
{"id":"COMM414","title":"Music, Media & Culture"},
{"id":"COSC341","title":"Intro to Database Mgmt Systems"},
{"id":"COSC356","title":"Network Security"},
{"id":"COSC420","title":"Modern Programming Languages"},
{"id":"COSC460","title":"Theory of Computation"},
{"id":"COSC481","title":"Special Topic"},
{"id":"DANC250","title":"Beginning Modern Dance"},
{"id":"DANC351","title":"Choreography"},
{"id":"DVST83","title":"Introduction to College Math"},
{"id":"EDEX110","title":"Intro /Special Needs PreK-Gr8"},
{"id":"ELED481","title":"Special Topic"},
{"id":"ELR480","title":"Intro to Negotiatn & Confl Res"},
{"id":"ENGL347","title":"Playwriting"},
{"id":"ENGL418","title":"Young Adult Literature"},
{"id":"ENGL430","title":"Major British Authors"},
{"id":"ENGL442","title":"Cross-Cultural Communication"},
{"id":"ENGL462","title":"Topics in American Literature"},
{"id":"ENGL485","title":"Hnors English Portfolio"},
{"id":"ENVE301","title":"Environment Aquatic Chemistry"},
{"id":"EOPT125","title":"Introduction to Electronics"},
{"id":"EOPT126","title":"Electronics II/Electro-Optics"},
{"id":"EOPT210","title":"Detection and Measurement"},
{"id":"EXSP4200","title":"International Culinary Externs"},
{"id":"FCSE101","title":"Personal & Family Mgmt"},
{"id":"FCSE312","title":"Housing and Culture"},
{"id":"FCSE416","title":"Problems in Family Finance"},
{"id":"FDED442","title":"Interpreting Urban/Field Exper"},
{"id":"FDNT110","title":"Careers in Food & Nutrition"},
{"id":"FIN481","title":"Special Topic"},
{"id":"FRNC463","title":"French Culture & Civilization"},
{"id":"FSMR158","title":"Fashion Show Production"},
{"id":"FSMR180","title":"Introduction to Fashion"},
{"id":"FSMR280","title":"Introduction to Apparel Buying"},
{"id":"FSMR385","title":"Ready-to-Wear Analysis"},
{"id":"GEOS102","title":"The Dynamic Earth Lab"},
{"id":"GEOS104","title":"Oceans & Atmospheres Lab"},
{"id":"GEOS352","title":"Stratigraphy"},
{"id":"GEOS355","title":"Sedimentology"},
{"id":"GEOS362","title":"Plate Tectonics"},
{"id":"HIST204","title":"US History to 1877"},
{"id":"HIST219","title":"Historians & the Public"},
{"id":"HIST232","title":"Stalin/Hitler-the Terror State"},
{"id":"HIST312","title":"Europe 1914-1945"},
{"id":"HIST322","title":"French Revolution & Napoleon"},
{"id":"HIST330","title":"History Islamic Civilization"},
{"id":"HIST339","title":"Jihad Origins of Islamist Mov."},
{"id":"IFMG201","title":"Internet & Multimedia"},
{"id":"IFMG300","title":"Info System: Theory & Practice"},
{"id":"IFMG305","title":"Micro Database Systems"},
{"id":"IFMG368","title":"E-Commerce Security"},
{"id":"IFMG382","title":"Audit and Control"},
{"id":"IFMG455","title":"Business Data Mining"},
{"id":"IFMG482","title":"Independent Study"},
{"id":"JAPN101","title":"Elementary Japanese I"},
{"id":"JRNL126","title":"Intro to Public Relations"},
{"id":"JRNL250","title":"Women & the Press"},
{"id":"JRNL325","title":"Public Relations Campaigns"},
{"id":"JRNL345","title":"Sports Journalism"},
{"id":"MATH330","title":"Tchg Math in Elementary School"},
{"id":"MATH421","title":"Adv Calculus I"},
{"id":"MGMT492","title":"Internship/Small Business"},
{"id":"MGMT495","title":"Business Policy"},
{"id":"MIDL221","title":"Literature for Middle Level"},
{"id":"MIDL312","title":"Science Instr & Assess/Gr 4-8"},
{"id":"MIDL315","title":"Classroom Mgmt & Adolesc Dev"},
{"id":"MKTG430","title":"International Marketing"},
{"id":"MKTG436","title":"Retail Management"},
{"id":"MUSC115","title":"Theory I"},
{"id":"MUSC116","title":"Theory II"},
{"id":"MUSC131","title":"University Wind Ensemble"},
{"id":"MUSC135","title":"Jazz Ensemble"},
{"id":"MUSC137","title":"Piano Accompanying"},
{"id":"MUSC138","title":"Vocal Repertoire Ensemble"},
{"id":"MUSC163","title":"Class Guitar I"},
{"id":"MUSC211","title":"Theory Skills III"},
{"id":"MUSC215","title":"Theory III"},
{"id":"MUSC240","title":"Technology in Music Classroom"},
{"id":"MUSC335","title":"Music-Stu w/ Disab Inclusv Set"},
{"id":"MUSC353","title":"French Diction"},
{"id":"MUSC406","title":"Vocal Pedagogy"},
{"id":"MUSC408","title":"Marching Band Techniques"},
{"id":"NURS337","title":"Adult Health I Clinical"},
{"id":"OLOC169","title":"St Abroad/Nagoya Gakuin Univ"},
{"id":"OLOC201","title":"Visiting Student-Bloomsburg"},
{"id":"OLOC202","title":"Visiting Student-California"},
{"id":"OLOC203","title":"Visiting Student-Cheney"},
{"id":"OLOC208","title":"Visiting Student-Lock Haven"},
{"id":"OLOC210","title":"Visiting Student-Millersville"},
{"id":"OLOC211","title":"Visiting Student-Shippensburg"},
{"id":"PHIL122","title":"Contemporary Moral Issues"},
{"id":"PHIL320","title":"Ethical Theory"},
{"id":"PHIL481","title":"Special Topic"},
{"id":"PLSC370","title":"Practice of Public Administrtn"},
{"id":"PLSC372","title":"Constitutn Issues/Homeland Sec"},
{"id":"PLSC383","title":"Political Systems-Asia"},
{"id":"PLSC404","title":"Women & Politics"},
{"id":"PSYC150","title":"Intro to Applied Psychology"},
{"id":"PSYC291","title":"Rsrch Design & Analysis II"},
{"id":"RESP433","title":"Respiratory Care Clinical IV"},
{"id":"RLST360","title":"African Religions"},
{"id":"RLST374","title":"Buddhism"},
{"id":"SAFE204","title":"Princ of Safety in Nat Gas Ind"},
{"id":"SAFE212","title":"Hazard Prevention Mgmt I"},
{"id":"SAFE215","title":"Safety, Hlth & Environ Communc"},
{"id":"SAFE330","title":"Recog-Eval-&Control/Occ Hazz I"},
{"id":"SAFE331","title":"Fund of Industrial Hygiene"},
{"id":"SAFE335","title":"Industrial & Environ Stressors"},
{"id":"SOC314","title":"Sociology of Native Americans"},
{"id":"SOC335","title":"Alcohol & Drug Abuse"},
{"id":"SOC336","title":"Sociology of the Family"},
{"id":"SOC357","title":"Soc. of Aging and Life-Course"},
{"id":"SOC442","title":"Medical Sociology"},
{"id":"SOC458","title":"Political Sociology"},
{"id":"SPLP122","title":"Clinical Phonology"},
{"id":"SPLP242","title":"Speech Science"},
{"id":"SPLP342","title":"Speech Science II/Neuroscience"},
{"id":"SPLP412","title":"Org & Admin/Spch,Lang&Hrg Prog"},
{"id":"THTR493","title":"Internship"},
{"id":"VOED203","title":"Incorp Rdng & Comm Strateg"},
{"id":"VOED301","title":"Integ Math & Sci Career & Tech"},
{"id":"VOED400","title":"Prep of Voc Professional II"},
{"id":"VOED402","title":"Sp Topic Voc Pedagogical Prep"},
{"id":"VOED403","title":"Assess & Eval/Career & Tech Ed"},
{"id":"WGS481","title":"Special Topic"},
{"id":"PHYS132","title":"Physics II-Calc Lecture"},
{"id":"PHYS281","title":"Special Topic"},
{"id":"PHYS475","title":"Physics of Semicond Devices I"},
{"id":"PLSC260","title":"Contemporary Political Ideas"},
{"id":"PLSC282","title":"International Relations"},
{"id":"PSYC389","title":"Psychology of Music"},
{"id":"PSYC390","title":"Indust Organizational Psych"},
{"id":"OLOC106","title":"St Abroad/Kyung Hee U, Korea"},
{"id":"OLOC111","title":"St Abroad/University of Essex"},
{"id":"OLOC112","title":"St Abroad/Kansai Gaidai U"},
{"id":"OLOC122","title":"St Abroad/U of Ljubljana-Art"},
{"id":"OLOC155","title":"St Abroad/ICMS-Sydney, Austral"},
{"id":"OLOC158","title":"St Abroad/U of Opole-Art"},
{"id":"SCI117","title":"Chemistry for Everyone Lab"},
{"id":"SDR111","title":"Basic Biological Lab Operation"},
{"id":"ENGL121","title":"Humanities Literature"},
{"id":"ENGL226","title":"Survey of Global Lit Sinc 1900"},
{"id":"ENGL310","title":"Public Speaking"},
{"id":"FDNT255","title":"Nutri Assess & Med Terminology"},
{"id":"FDNT484","title":"Senior Seminar"},
{"id":"FIAR401","title":"Creativity & Innov in the Arts"},
{"id":"FIN300","title":"Personal Finance"},
{"id":"FIN424","title":"International Finance Mgmt"},
{"id":"MEDT494","title":"Clinical Microbiology"},
{"id":"MEDT499","title":"Clinical Seminar"},
{"id":"MGMT350","title":"International Business"},
{"id":"MGMT375","title":"Social Entrepreneurship"},
{"id":"MGMT405","title":"Organizational Staffing"},
{"id":"EDEX493","title":"Internship/Field Training"},
{"id":"EDHL314","title":"Deaf Culture"},
{"id":"EDHL317","title":"Sign Language in Educ Settings"},
{"id":"EDHL440","title":"Prof Sem: Tchr,Rsrch,Diagn,Adv"},
{"id":"EDHL451","title":"Rdgn/Deaf,HH & Engl Lang Lrnrs"},
{"id":"EDHL481","title":"Special Topic"},
{"id":"RGPL316","title":"Intro to Geog Info Systems"},
{"id":"RGPL453","title":"Planning Design Studio I"},
{"id":"RGPL483","title":"Regional Plng Honors Thesis"},
{"id":"RGPL498","title":"Community Planning Practicum"},
{"id":"ECED310","title":"Sci, Health & Safety PreK-Gr4"},
{"id":"MKTG442","title":"Social Cause Mktg for Non-Prof"},
{"id":"MKTG446","title":"Marketing Ethics"},
{"id":"MKTG450","title":"Marketing Strategy"},
{"id":"MKTG493","title":"Internship"},
{"id":"MUHI223","title":"Musical Cult. Around the World"},
{"id":"EDUC408","title":"Reading in the Content Area"},
{"id":"EDUC441","title":"Student Teaching"},
{"id":"EDUC442","title":"School Law"},
{"id":"FSMR481","title":"Special Topic"},
{"id":"GEOG213","title":"Cartography and Map Design"},
{"id":"GEOG313","title":"Cartography II"},
{"id":"GEOG404","title":"Transportation Planning"},
{"id":"GEOG409","title":"Spat Analy App in Energy Sectr"},
{"id":"GEOG482","title":"Independent Study"},
{"id":"GEOG485","title":"GIS Application Development"},
{"id":"GEOG493","title":"Internship"},
{"id":"GEOG498","title":"Research Seminar"},
{"id":"GEOG499","title":"Independent Study"},
{"id":"THTR130","title":"Stage Voice"},
{"id":"THTR215","title":"Theatre Hist & Lit: Comedy"},
{"id":"COMM471","title":"Advanced Image Editing"},
{"id":"COMM475","title":"Senior Portfolio Presentation"},
{"id":"COSC201","title":"Internet & Multimedia"},
{"id":"COSC310","title":"Data Structures and Algorithms"},
{"id":"CHIN102","title":"Elementary Chinese II"},
{"id":"CJTR1100","title":"Criminal Justice Trng - PT I"},
{"id":"CJTR3200","title":"Criminal Justice Trng PW _____"},
{"id":"CKRY1100","title":"Stocks, Soups & Sauces"},
{"id":"CKRY2001","title":"Nutn Cuisine-Poultry Prep/Cook"},
{"id":"CMDA1030","title":"English I"},
{"id":"CMDA1040","title":"Medical Terminology I / At&Pys"},
{"id":"CMDA1060","title":"English II"},
{"id":"CMDA2040","title":"Database Concepts"},
{"id":"CMDA3000","title":"Patient Care Lab I"},
{"id":"CMDA3030","title":"Medical Billing & Coding II"},
{"id":"CMDA3050","title":"Medical Scribe/Editor"},
{"id":"CMGT1301","title":"Nutrition"},
{"id":"CMGT2300","title":"Menu Design"},
{"id":"SOWK238","title":"Introduction to Social Work"},
{"id":"SPAN201","title":"Intermediate Spanish"},
{"id":"SPAN350","title":"Advanced Spanish Conversation"},
{"id":"SPAN362","title":"Survey of Peninsular Lit"},
{"id":"SPAN364","title":"Survey Spanish-American Lit"},
{"id":"GEOS490","title":"Field Studies in Geoscience"},
{"id":"GRMN322","title":"Adv Converstation II"},
{"id":"GRMN371","title":"Age of Goethe II"},
{"id":"GRMN381","title":"German Drama in English Transl"},
{"id":"GRMN481","title":"Special Topic"},
{"id":"GRMN493","title":"Internship (Germany)"},
{"id":"HAWK103","title":"Becoming a Leader!"},
{"id":"HAWK111","title":"Service Learning"},
{"id":"NMTT312","title":"Basic Nanofabrication Process"},
{"id":"NURS101","title":"Disaster Awareness"},
{"id":"NURS212","title":"Fundamentals I Theory"},
{"id":"LLRN100","title":"Co-curricular Activity Lab I"},
{"id":"MATH107","title":"Business Mathematics"},
{"id":"CRIM354","title":"White Collar Crime"},
{"id":"BIOL210","title":"Principles of Plant Biology"},
{"id":"BIOL211","title":"Investigative Biol. Forensics"},
{"id":"BIOL221","title":"Envrionmental Health & Protect"},
{"id":"BIOL270","title":"Food Protection & Safety"},
{"id":"BIOL272","title":"Consrv of Plant & Animal Res"},
{"id":"BIOL281","title":"Special Topic"},
{"id":"BIOL323","title":"Intro Toxicology & Risk Assess"},
{"id":"BIOL410","title":"Molecular Biology Topics"},
{"id":"BIOL425","title":"Herpetology"},
{"id":"BIOL480","title":"Biology Seminar"},
{"id":"BIOL481","title":"Special Topic"},
{"id":"BIOL490","title":"Field Studies in Biology"},
{"id":"ALI1700","title":"Vocabulary for Conversation"},
{"id":"ALI2700","title":"World of Words"},
{"id":"ALI3000","title":"ACE Tutoring"},
{"id":"ALI3100","title":"Basic Writing"},
{"id":"ALI3210","title":"Personal Narratives"},
{"id":"ALI3700","title":"Vocabulary: American Idioms"},
{"id":"ALI4100","title":"Writing for Academic Purposes"},
{"id":"ANTH110","title":"Contemporary Anthro"},
{"id":"ANTH272","title":"Cultural Area: China"},
{"id":"ANTH314","title":"Contem Native Amer. Cultures"},
{"id":"ANTH318","title":"Museum Methods"},
{"id":"BTED412","title":"Methods in Bus & Info Tech II"},
{"id":"INDS118","title":"Interior Design Graphics"},
{"id":"INDS205","title":"Color Theory & Application"},
{"id":"INDS281","title":"Special Topic"},
{"id":"INDS380","title":"Hist-Interior Dsgn & Arch II"},
{"id":"INDS405","title":"Interior Design Prof Practice"},
{"id":"INDS465","title":"Commercial Design Studio II"},
{"id":"HNRC202","title":"Honors Core: Sciences /H/"},
{"id":"HOSP259","title":"Hospitality Purchasing"},
{"id":"HOSP326","title":"Theme Park/Attraction Mangemen"},
{"id":"HOSP346","title":"Catering for Special Events"},
{"id":"HOSP433","title":"Educational Study Tour"},
{"id":"HOSP470","title":"Hospitality Business Model"},
{"id":"KHSS213","title":"Rec Sports & Lifetime Activity"},
{"id":"KHSS225","title":"Social Issues in Sport"},
{"id":"KHSS261","title":"Water Safety Instructor"},
{"id":"KHSS285","title":"Group/Indiv Exercise Leadershp"},
{"id":"KHSS335","title":"Athletic Coaching"},
{"id":"KHSS349","title":"Applied Pediatric Exercise Lab"},
{"id":"KHSS350","title":"Health Aspects of Aging"},
{"id":"KHSS375","title":"Physio Basis Strg Trng & Cond"},
{"id":"KHSS414","title":"Exercise Electrocardiography"},
{"id":"KHSS416","title":"Funct Trng/Strength & Conditng"},
{"id":"KHSS436","title":"Correct Exer/Hlth & Fit Practi"},
{"id":"KHSS493","title":"Internship"},
{"id":"KORE101","title":"Elementary Korean I"},
{"id":"KORE201","title":"Intermediate Korean"},
{"id":"LAS281","title":"Special Topic"},
{"id":"LAS480","title":"Latin American Studies Seminar"},
{"id":"ART112","title":"Fundamentals of Drawing"},
{"id":"ART211","title":"Painting"},
{"id":"ART217","title":"Print Media"},
{"id":"ART448","title":"Brand Design"},
{"id":"APMU161","title":"Private Clarinet II"},
{"id":"APMU166","title":"Private Fr Horn II"},
{"id":"APMU168","title":"Private Euphonium II"},
{"id":"APMU169","title":"Private Tuba II"},
{"id":"APMU210","title":"Private Flute III"},
{"id":"APMU216","title":"Private French Horn III"},
{"id":"APMU220","title":"Private Percussion III"},
{"id":"APMU254","title":"Private Harp IV"},
{"id":"APMU255","title":"Private Voice IV"},
{"id":"HIST365","title":"Hist Black Amer Since Emancip"},
{"id":"HIST390","title":"Hist of Women: World Cultures"},
{"id":"HIST422","title":"Hist Museums & Historic Sites"},
{"id":"HIST437","title":"Modern Japan 1850-Present"},
{"id":"HIST481","title":"Special Topic"},
{"id":"HIST499","title":"Topics in Public History"},
{"id":"CEU2109","title":"PA Mun Pol Off Driv Prof Instr"},
{"id":"CEU2162","title":"Field Training Officer"},
{"id":"CEU2177","title":"AI-4 Persp Grid Mapping Eviden"},
{"id":"CEU2178","title":"AI-5 Tire Dynamics & Exam"},
{"id":"CEU4363","title":"Communication Skills"},
{"id":"CEU4401","title":"Slvg Prob&Making the Rght Dec"},
{"id":"CEU4409","title":"Advanced Microsoft Word"},
{"id":"CEU4426","title":"Microsoft Word 2000-Level II"},
{"id":"CEU4429","title":"Beginning MS Excel"},
{"id":"CEU4433","title":"Beginning Microsoft Powerpoint"},
{"id":"CEU4442","title":"Microsoft Word XP-Level II"},
{"id":"CEU4448","title":"Most fr Web Browsing Utilities"},
{"id":"CRLG113","title":"Swedish I"},
{"id":"CRLG115","title":"Swahili I"},
{"id":"CRLG155","title":"Hindi II"},
{"id":"CRLG164","title":"Hebrew II"},
{"id":"CRLG281","title":"Special Topic"},
{"id":"ACCT305","title":"Intermediate Accounting II"},
{"id":"ACCT421","title":"Federal Tax I"},
{"id":"ANTH350","title":"Anthropology of Gender"},
{"id":"ANTH484","title":"Special Methods in Archaeology"},
{"id":"ANTH487","title":"Geoarchaelogy"},
{"id":"ANTH490","title":"Appl Spatial Meth Archaeology"},
{"id":"APMU115","title":"Private Trumpet I"},
{"id":"APMU261","title":"Private Clarinet IV"},
{"id":"APMU267","title":"Private Trombone IV"},
{"id":"APMU269","title":"Private Tuba IV"},
{"id":"APMU308","title":"Private Cello V"},
{"id":"APMU318","title":"Private Euphonium V"},
{"id":"APMU365","title":"Private Trumpet VI"},
{"id":"APMU368","title":"Private Euphonium VI"},
{"id":"APMU371","title":"Private Guitar VI"},
{"id":"APMU419","title":"Private Tuba VII"},
{"id":"APMU421","title":"Private Guitar VII"},
{"id":"APMU454","title":"Private Harp Viii"},
{"id":"APMU456","title":"Private Violin VIII"},
{"id":"ARED482","title":"Independent Study"},
{"id":"ARHI100","title":"Arts of the 20th Century"},
{"id":"BIOC402","title":"Advanced Biochemistry"},
{"id":"BIOC482","title":"Independent Study"},
{"id":"BIOL101","title":"Basic Biology"},
{"id":"BIOL104","title":"Human Biol: How the Body Works"},
{"id":"BIOL105","title":"Cell Biology"},
{"id":"BIOL106","title":"Human Genetics & Health"},
{"id":"BIOL123","title":"Persp in Cell & Molecular Biol"},
{"id":"BKPS1004","title":"Pies, Tarts, & French Pastries"},
{"id":"BKPS2001","title":"Pastillage and Confections"},
{"id":"BLAW336","title":"Law of Business Organizations"},
{"id":"CDFR310","title":"Child Observation & Assessment"},
{"id":"CDFR420","title":"Infant Toddler Mental Health"},
{"id":"CDFR428","title":"Family Dynamics"},
{"id":"CDFR429","title":"Teaching in Community Settings"},
{"id":"CHEM103","title":"Intro--Chemistry/Health Scienc"},
{"id":"CHEM114","title":"Adv General Chemistry II"},
{"id":"CHEM390","title":"Chemistry Seminar II"},
{"id":"COMM201","title":"Internet & Multimedia"},
{"id":"COMM240","title":"Communications Graphics"},
{"id":"COMM251","title":"Televison Production"},
{"id":"COMM305","title":"Electron Media Program & Sales"},
{"id":"COMM351","title":"Adv Video Production"},
{"id":"COMM445","title":"App & Tech of Motion Pictures"},
{"id":"COSC427","title":"Intro to Cryptography"},
{"id":"COSC444","title":"Tools & 4th Generation Lang"},
{"id":"COSC480","title":"Seminar: Technical Topics"},
{"id":"COSC493","title":"Internship in Computer Science"},
{"id":"COUN253","title":"Counseling in School Settings"},
{"id":"CRIM481","title":"Special Topic"},
{"id":"DANC210","title":"Danc and Somatics"},
{"id":"DISB201","title":"Custom Employ: Disab Awareness"},
{"id":"DISB340","title":"Behavioral Mgmt in Special Ed"},
{"id":"DISB378","title":"Ed/Mtnal, Phys, Dev & Mult Dis"},
{"id":"DISB440","title":"Ethical & Profession Behaviors"},
{"id":"DVST92","title":"Dev Mathematics, Elemental Top"},
{"id":"ECON338","title":"Poverty in Africa"},
{"id":"ECON360","title":"Health Economics"},
{"id":"ECON361","title":"Environmental Economics"},
{"id":"ECON462","title":"Energy Economics"},
{"id":"ECON481","title":"Special Topic"},
{"id":"ECSP440","title":"Tchr/Researchr & Advoc PreK-G4"},
{"id":"EDEX114","title":"Atypcl Dev Infnts, Child, Adol"},
{"id":"EDEX231","title":"Tchg Cont Area Subj Pers w/Dis"},
{"id":"EDSP281","title":"Special Topic"},
{"id":"EDSP479","title":"Interpreting & Critiq Ed Res"},
{"id":"ELED312","title":"Tchg Elementary Science"},
{"id":"ELED357","title":"Pedagogy II"},
{"id":"ELR499","title":"Independent Study"},
{"id":"ENGL336","title":"Language, Gender & Society"},
{"id":"ENGL398","title":"Global Genres"},
{"id":"ENGL415","title":"English Lang Studies for Tchrs"},
{"id":"ENGL420","title":"Writers' Studio"},
{"id":"ENGL426","title":"ESL Methods & Materials"},
{"id":"ENGL463","title":"Topics in Global Lit & Film"},
{"id":"ENGL466","title":"Topics in Theory"},
{"id":"ENGL481","title":"Special Topic"},
{"id":"ENVE101","title":"Intro--Environmental Engineer"},
{"id":"ENVE200","title":"Foundations of Geology"},
{"id":"EOPT120","title":"Wave Optics"},
{"id":"EOPT130","title":"Introduction to Optics"},
{"id":"EOPT220","title":"Introduction to Lasers"},
{"id":"EOPT240","title":"Fiber Optics"},
{"id":"FCSE143","title":"Financial Wellness"},
{"id":"FCSE413","title":"Problems in Consumer Economics"},
{"id":"FDED481","title":"Special Topic"},
{"id":"FRNC220","title":"intermediate French Conversatn"},
{"id":"FRNC281","title":"Special Topic"},
{"id":"FRNC354","title":"Business French"},
{"id":"FRNC375","title":"French Lit Medieval-1700"},
{"id":"FSMR215","title":"Textiles"},
{"id":"FSMR357","title":"Global Iss/Textiles & Apparel"},
{"id":"GEOS103","title":"Oceans & Atmospheres"},
{"id":"GEOS323","title":"Geophysics"},
{"id":"HIST240","title":"Zombies: Clt Hist Death/Dis/Tc"},
{"id":"HIST251","title":"United States Military History"},
{"id":"HIST329","title":"History of Byzantine Empire"},
{"id":"HIST331","title":"Modern Middle East"},
{"id":"HIST337","title":"History of Modern Japan"},
{"id":"HIST341","title":"American Revolution"},
{"id":"IFMG352","title":"LAN Design & Installation"},
{"id":"IFMG480","title":"Distrib Business Info Systems"},
{"id":"JAPN481","title":"Special Topics"},
{"id":"JPRJ1000","title":"Culinary Professnl Portfolio 1"},
{"id":"JPRJ2000","title":"Culinary Professnl Portfolio 2"},
{"id":"JRNL102","title":"Basic Journalism Skills"},
{"id":"JRNL281","title":"Special Topic"},
{"id":"JRNL425","title":"Entertainment PR"},
{"id":"JRNL427","title":"Publications"},
{"id":"MATH272","title":"Intro Math Proofs II"},
{"id":"MATH281","title":"Special Topic"},
{"id":"MATH309","title":"Discrete Mathematics"},
{"id":"MATH350","title":"History of Mathematics"},
{"id":"MATH355","title":"Foundations of Geometry I"},
{"id":"MATH413","title":"Methods/Tchg Math-Middle Level"},
{"id":"MATH423","title":"Complex Variables"},
{"id":"MATH458","title":"Logic & Logic Games Elem/Mid"},
{"id":"MATH481","title":"Special Topic"},
{"id":"MATH493","title":"Internship"},
{"id":"MGMT432","title":"Business & Society"},
{"id":"MGMT452","title":"Comparative Management"},
{"id":"MGMT459","title":"Seminar: International Mgmt"},
{"id":"MGMT461","title":"Business Leadership Theory"},
{"id":"MIDL422","title":"Diagnostic & Remedial Reading"},
{"id":"MKTG421","title":"Marketing Research"},
{"id":"MKTG434","title":"Bus Logistics:Tech Analy/Applc"},
{"id":"MUSC114","title":"Analysis of Popular Music"},
{"id":"MUSC125","title":"Marching Band"},
{"id":"MUSC133","title":"Woodwind Ensemble"},
{"id":"MUSC157","title":"Class Percussion I"},
{"id":"MUSC217","title":"Keyboard Harmony"},
{"id":"MUSC219","title":"Music Education Practicum"},
{"id":"MUSC351","title":"Italian Diction"},
{"id":"MUSC354","title":"German Diction"},
{"id":"NURS340","title":"Nursing Pharmacology"},
{"id":"NURS434","title":"Public/Community Nursing"},
{"id":"NURS437","title":"Adult Health II Clinical"},
{"id":"NURS440","title":"Nursing Management Clinical"},
{"id":"OLOC174","title":"St Abroad/Shanghai Normal Univ"},
{"id":"OLOC291","title":"PARA/EMS Paramedic"},
{"id":"PHIL330","title":"Philosophy of Science"},
{"id":"PHIL420","title":"Metaphysics"},
{"id":"PHIL460","title":"Philosophy of Language"},
{"id":"PLSC371","title":"Issues Public Administration"},
{"id":"PLSC387","title":"Political Systems: Latin Ameri"},
{"id":"PLSC388","title":"Dimensions of Nationl Security"},
{"id":"PLSC389","title":"International Dev Strategies"},
{"id":"PNAF281","title":"Special Topic"},
{"id":"PNAF481","title":"Special Topic"},
{"id":"PSYC101","title":"General Psychology"},
{"id":"PSYC245","title":"Learning and Cognition"},
{"id":"PSYC300","title":"Psyc Research II: Quantitative"},
{"id":"PSYC320","title":"Personality"},
{"id":"QBUS380","title":"Intro to Management Science"},
{"id":"QBUS401","title":"Forecasting Meth for Business"},
{"id":"REAL382","title":"Real Estate Fundamentals"},
{"id":"REAL383","title":"Real Estate Practice"},
{"id":"RESP441","title":"Resp Care Professional I"},
{"id":"RLST380","title":"Islam"},
{"id":"SAFE488","title":"Internship"},
{"id":"SOC269","title":"Social Deviance"},
{"id":"SOC454","title":"Dimensions/Rural Public Health"},
{"id":"SPAN412","title":"Spanish Novel/19 & 20th Cent"},
{"id":"SPAN450","title":"Conversation Forum"},
{"id":"SPAN453","title":"Spanish Phonetics & Phonemics"},
{"id":"SPLP275","title":"Language Science"},
{"id":"SPLP422","title":"Ctrl Auditory Proc Disord-Educ"},
{"id":"THTR487","title":"Acting Studio"},
{"id":"UVWD493","title":"T.H.I.S. Internship"},
{"id":"WGS301","title":"Asian Amer & Asian Diaspor Lit"},
{"id":"WGS400","title":"Feminist Theories"},
{"id":"WGS482","title":"Independent Study"},
{"id":"PHYS100","title":"Prelude to Physics"},
{"id":"PHYS111","title":"Physics I Lecture"},
{"id":"PHYS131","title":"Physics I-Calc Lecture"},
{"id":"PHYS141","title":"Physics I-Calc Lab"},
{"id":"PHYS231","title":"Electronics"},
{"id":"PHYS323","title":"Electricity & Magnetism II"},
{"id":"PHYS331","title":"Modern Physics"},
{"id":"PHYS345","title":"Optics"},
{"id":"PHYS350","title":"Intermed Experiment Physics I"},
{"id":"PHYS472","title":"Nuclear Physics"},
{"id":"PLSC101","title":"World Politics"},
{"id":"PLSC111","title":"American Government"},
{"id":"PLSC251","title":"State & Local Political System"},
{"id":"PLSC344","title":"Terrorism"},
{"id":"PLSC351","title":"Legislative Process"},
{"id":"PLSC356","title":"Mass Media in Amer Politics"},
{"id":"PLSC358","title":"Judicial Process"},
{"id":"PLSC362","title":"American Political Thought"},
{"id":"PSYC450","title":"Counseling Skills"},
{"id":"PSYC455","title":"Adv Topic/Biological Psycholog"},
{"id":"OLOC118","title":"St Abroad/Karlstad University"},
{"id":"SCI101","title":"Fundamentals of Physics"},
{"id":"SCI102","title":"Fundamentals of Chemistry"},
{"id":"SCI104","title":"Fund Environmental Biology"},
{"id":"SDR121","title":"Chemical Recog & ID Techniques"},
{"id":"ENGL101","title":"Composition I"},
{"id":"ENGL314","title":"Speech & Comm/2nd Engl Clsrm"},
{"id":"FDNT410","title":"Food, Nutrition & Aging"},
{"id":"FDNT430","title":"Prof Topics Food & Nutrition"},
{"id":"FDNT455","title":"Medical Nutrition Therapy II"},
{"id":"FDNT459","title":"Adv Human Metab: Macronutrient"},
{"id":"FDNT464","title":"Food & Nutrition Rsrch Methods"},
{"id":"FDNT482","title":"Independent Study"},
{"id":"FIAR101","title":"Introduction to the Arts"},
{"id":"FIAR281","title":"Special Topic"},
{"id":"FIN143","title":"Financial Wellness"},
{"id":"FIN310","title":"Fundamentals of Finance"},
{"id":"FIN323","title":"Retirement Planning"},
{"id":"FIN400","title":"Estate Planning"},
{"id":"FIN422","title":"Seminar in Finance"},
{"id":"MEDT284","title":"IMAG/Echocardio Clinicals"},
{"id":"MGMT275","title":"Intro to Entrepreneurship"},
{"id":"MGMT325","title":"Small Business Management"},
{"id":"MGMT401","title":"Management Develop & Training"},
{"id":"RGPL203","title":"Planning History"},
{"id":"RGPL213","title":"Cartography and Map Design"},
{"id":"RGPL314","title":"Map & Photograph Interpretatn"},
{"id":"RGPL352","title":"Planning Methods"},
{"id":"RGPL455","title":"Advanced Remote Sensing"},
{"id":"RGPL493","title":"Internship"},
{"id":"RLST200","title":"Religion & Culture-Interaction"},
{"id":"RLST312","title":"Archaeology & Bible"},
{"id":"DVST160","title":"Learning Strategies"},
{"id":"DVST250","title":"Peer Educ:Theory,Prac&Assessmt"},
{"id":"DVST281","title":"Special Topic"},
{"id":"ECED215","title":"The Developing Child: K-4th"},
{"id":"ECON121","title":"Principles of Macroeconomics"},
{"id":"ECON281","title":"Special Topic"},
{"id":"MKTG443","title":"New Product Design & Marketing"},
{"id":"MUHI281","title":"Special Topic"},
{"id":"MUHI302","title":"Music History II"},
{"id":"FSMR468","title":"Supp Chain Mgmt/Textiles & App"},
{"id":"GEOG101","title":"Geog/Human Environ Interaction"},
{"id":"GEOG219","title":"Global Positioning & Reg Plang"},
{"id":"GEOG253","title":"Geography of Europe"},
{"id":"GEOG342","title":"Physiography"},
{"id":"GEOG343","title":"Fresh Water Resources"},
{"id":"GEOG445","title":"Energy Develop & Compliance II"},
{"id":"GEOG455","title":"Advanced Remote Sensing"},
{"id":"GEOG481","title":"Special Topic"},
{"id":"GEOG488","title":"Geospatial Intellig Capstone"},
{"id":"SUST281","title":"Special Topics"},
{"id":"THTR111","title":"Foundations of Theater"},
{"id":"THTR212","title":"History & Lit: Renaissance"},
{"id":"THTR225","title":"Theater Graphics"},
{"id":"THTR226","title":"Stage Management"},
{"id":"THTR261","title":"Simulation Performance"},
{"id":"THTR311","title":"Dramaturgy"},
{"id":"THTR313","title":"American Thtr Between the Wars"},
{"id":"THTR480","title":"Theater Seminar"},
{"id":"COMM493","title":"Internship"},
{"id":"COSC300","title":"Assembly Language Programming"},
{"id":"CHIN281","title":"Special Topic"},
{"id":"CHSS281","title":"Special Topics"},
{"id":"CJTR1000","title":"Criminal Justice Training - FT"},
{"id":"CJTR1002","title":"Criminal Justice Trng FT-___"},
{"id":"CJTR1400","title":"Criminal Justice Trng PT IV"},
{"id":"CJTR3300","title":"Criminal Justice Trng PW _____"},
{"id":"CMDA2060","title":"Medical Terminology III/Atmy&P"},
{"id":"CMDA3020","title":"Clinical Pharmacology"},
{"id":"CMDA3040","title":"Medical Office Procedures"},
{"id":"CMGT2200","title":"Supervisory Management"},
{"id":"SPAN390","title":"Tchg El Content in Spanish"},
{"id":"GEOS409","title":"Geology of Shale Gas Field Wrk"},
{"id":"GEOS470","title":"Research Methods in Geoscience"},
{"id":"GRMN254","title":"Business German"},
{"id":"GRMN364","title":"Intro to German Literature II"},
{"id":"GRMN369","title":"Contemporary German Literature"},
{"id":"HAWK110","title":"Meet N Greets"},
{"id":"HAWK114","title":"Crimson Coffee Hour"},
{"id":"HAWK204","title":"Diversity/Social Equity"},
{"id":"HIST201","title":"West Civilization before 1600"},
{"id":"MUSC475","title":"Music Lab (Recitals)"},
{"id":"NMDT428","title":"Radiation Physics"},
{"id":"NMDT431","title":"In-Vivo/Vitro Non-Imaging"},
{"id":"NMDT434","title":"Clinical Nuclear Medicine"},
{"id":"NMTT315","title":"Materials Modification"},
{"id":"NURS143","title":"Healthy People-Promot Wellness"},
{"id":"MATH100","title":"Intermediate Algebra"},
{"id":"CRIM323","title":"Cybersecurity & the Law"},
{"id":"CRIM420","title":"Media Portrayal of Crime"},
{"id":"BIOL200","title":"Medical Terminology"},
{"id":"BIOL205","title":"Ecological Methods"},
{"id":"BIOL331","title":"Animal Developmental Biology"},
{"id":"BIOL430","title":"Gene Edit Tools/Med & Biotech"},
{"id":"BIOL455","title":"Animal Behavior"},
{"id":"ALI1100","title":"Intro to English Writing"},
{"id":"ALI1400","title":"Listening for Life"},
{"id":"ALI1800","title":"Basic English Literacy"},
{"id":"ALI2600","title":"Intro to English Grammar"},
{"id":"ALI3500","title":"Explorations of Culture I"},
{"id":"ALI4200","title":"Reading Academic Themes"},
{"id":"ALI4220","title":"Book Talk"},
{"id":"ALI4400","title":"Listening to Acad. English I"},
{"id":"ALI4600","title":"Intermediate Grammar II"},
{"id":"ALI4700","title":"Academic Vocabulary"},
{"id":"ALI4800","title":"Electronic Literacy"},
{"id":"ANTH274","title":"Cultural Area: Latin America"},
{"id":"ANTH316","title":"Anthropology of Religion"},
{"id":"BTED201","title":"Internet & Multimedia"},
{"id":"BTED281","title":"Special Topics"},
{"id":"BTED442","title":"Trng Meth/Bus & Info Tech Supp"},
{"id":"BTED470","title":"Tech Applications for Educ"},
{"id":"BTED482","title":"Independent Study"},
{"id":"IMAG480","title":"Medical Imaging Seminar"},
{"id":"INDS230","title":"Presentation for Interior Dsgn"},
{"id":"INDS305","title":"Interior Lighting"},
{"id":"HNRC201","title":"Honors Core III /H/"},
{"id":"HNRC499","title":"Honors Senior Synthesis /H/"},
{"id":"HOSP130","title":"Food Service Sanitation"},
{"id":"HOSP150","title":"Prin of Hospitality Management"},
{"id":"HOSP256","title":"Human Res In Hospitality Indus"},
{"id":"HOSP285","title":"Service, Menu Plng, & Fac Dsgn"},
{"id":"HOSP300","title":"Critiquing Commercial Restrnts"},
{"id":"HOSP320","title":"Hospitality Marketing"},
{"id":"HOSP343","title":"Fund-Raising for Spec Events"},
{"id":"HOSP400","title":"Hotel Revenue Management"},
{"id":"KHSS287","title":"Aquatic Fitness Instruction"},
{"id":"KHSS315","title":"Biomechanics"},
{"id":"KHSS317","title":"Basketball Officiating"},
{"id":"KHSS341","title":"Evaluation in Health & Phys Ed"},
{"id":"KHSS345","title":"Survey-Ortho Injuries/Sport Ex"},
{"id":"KHSS347","title":"Physiology of Exercise Laborat"},
{"id":"KHSS353","title":"Driver Ed Program Management"},
{"id":"KHSS385","title":"Gen Medic Conditions/Athl Trng"},
{"id":"KHSS415","title":"Well Coach/Lifestyle Beh Mgmt"},
{"id":"KHSS460","title":"Law & Issues in Managing Sport"},
{"id":"KHSS480","title":"Prof Issues/Athletic Training"},
{"id":"LAS370","title":"Latinos and Diasporas"},
{"id":"NURS482","title":"Independent Study"},
{"id":"ARHI423","title":"Art of Japan"},
{"id":"ARHI482","title":"Independent Study"},
{"id":"ART355","title":"Intermediate Graphic Design I"},
{"id":"ART421","title":"Adv Drawing"},
{"id":"ART424","title":"Drawing: Ideation and Concept"},
{"id":"APMU159","title":"Private String Bass II"},
{"id":"APMU170","title":"Private Percussion II"},
{"id":"APMU213","title":"Private Bassoon III"},
{"id":"APMU214","title":"Private Saxophone III"},
{"id":"HIST391","title":"Film as History"},
{"id":"HIST421","title":"Archival Studies"},
{"id":"HIST475","title":"Hist of American Constitutiona"},
{"id":"HIST482","title":"Independent Study"},
{"id":"HIST483","title":"Honors Thesis"},
{"id":"HIST495","title":"Topics in US History"},
{"id":"CDFR481","title":"Special Topic"},
{"id":"CDFR482","title":"Independent Study"},
{"id":"CEU2135","title":"Basic Instructor Devel Act 120"},
{"id":"CEU2146","title":"ACT 120 POLICE ACADEMY"},
{"id":"CEU2169","title":"High Risk Entry Tactics"},
{"id":"CEU2170","title":"PA Mun Police Dr Prof"},
{"id":"CEU2172","title":"Act 120 IUP Police Acad Recert"},
{"id":"CEU2174","title":"TRNG FOR LAW ENF ON AIRPLANES"},
{"id":"CEU2175","title":"Leadership Wkshp for EMS Pers"},
{"id":"CEU2176","title":"AI-3 Traffic Accident Photogr"},
{"id":"CEU4369","title":"Conflict Management Skills"},
{"id":"CEU4374","title":"Intro to Microsoft Windows"},
{"id":"CEU4391","title":"Advanced Microsoft PowerPoint"},
{"id":"CEU4399","title":"ACCESS II"},
{"id":"CEU4402","title":"Intermediate Microsoft Access"},
{"id":"CEU4451","title":"ADV MS PowerPoint Features"},
{"id":"CEU4452","title":"MS Excel-Charts & Graphs"},
{"id":"CRLG108","title":"Japanese I"},
{"id":"CRLG111","title":"Portuguese I"},
{"id":"CRLG163","title":"Swedish II"},
{"id":"CRLG201","title":"Arabic III"},
{"id":"CRLG265","title":"Swahili IV"},
{"id":"CRLG491","title":"Critical Language I"},
{"id":"ACE103","title":"Digital Instructional Technlgy"},
{"id":"ANTH470","title":"Environmental Archaeology"},
{"id":"ANTH489","title":"Prehist Tech Eastern Nrth Amer"},
{"id":"APMU102","title":"Private Organ I"},
{"id":"APMU104","title":"Private Harp I"},
{"id":"APMU108","title":"Private Cello I"},
{"id":"APMU109","title":"Private String Bass I"},
{"id":"APMU119","title":"Private Tuba I"},
{"id":"APMU120","title":"Private Percussion I"},
{"id":"APMU127","title":"Piano Proficiency Level II"},
{"id":"APMU317","title":"Private Trombone V"},
{"id":"APMU351","title":"Private Piano VI"},
{"id":"APMU354","title":"Private Harp VI"},
{"id":"APMU355","title":"Private Voice VI"},
{"id":"APMU364","title":"Private Saxophone VI"},
{"id":"APMU402","title":"Private Organ VII"},
{"id":"APMU459","title":"Private String Bass VIII"},
{"id":"APMU468","title":"Private Euphonium VIII"},
{"id":"ARED281","title":"Special Topic"},
{"id":"ARHI205","title":"Ancient to Medieval"},
{"id":"ARHI207","title":"Renaissance through Modern Art"},
{"id":"ARHI321","title":"History of World Ceramics"},
{"id":"ARHI410","title":"19th Century European Painting"},
{"id":"ASIA281","title":"Special Topic"},
{"id":"BCOM231","title":"Speech Communication-Professnl"},
{"id":"BIOC311","title":"Biochemistry Lab I"},
{"id":"BIOL107","title":"Intro to Forensic Biology"},
{"id":"BKPS1003","title":"Petit Fours, Cookies & Cakes"},
{"id":"BLAW442","title":"Law of Property Oil/Gas Lsg II"},
{"id":"BUSN101","title":"Business Success Seminar"},
{"id":"CDFR315","title":"Intro to Early Intervention"},
{"id":"CDFR322","title":"Early Care & Education"},
{"id":"CDFR411","title":"Family & Community"},
{"id":"CHEM101","title":"College Chemistry I"},
{"id":"CHEM111","title":"General Chemistry I"},
{"id":"CHEM314","title":"Intermed Inorganic Chemistry"},
{"id":"CHEM332","title":"Organic Chemistry II"},
{"id":"CHEM401","title":"Advanced Chemistry Lab"},
{"id":"CHEM481","title":"Special Topic"},
{"id":"CMGT2400","title":"Intro to Hospitality Business"},
{"id":"COMM261","title":"Teamwork & Comm Skills"},
{"id":"COMM401","title":"Media Programming & Promotion"},
{"id":"COSC362","title":"Unix Systems"},
{"id":"COSC365","title":"Web Architecture & Applic Dev"},
{"id":"COSC424","title":"Compiler Construction"},
{"id":"COSC482","title":"Independent Study"},
{"id":"DANC485","title":"Dance Studio"},
{"id":"ECON334","title":"Managerial Economics"},
{"id":"ECON346","title":"International Finance"},
{"id":"ECON362","title":"Global Poverty and Health"},
{"id":"ECON422","title":"Microeconomic Analysis"},
{"id":"ECSP314","title":"Creative Exp & Play/PreK-Gr4"},
{"id":"EDEX222","title":"Meth Tch Lng Arts to Stu w/Dis"},
{"id":"EDEX424","title":"Str Assess & Instr/Expos Tests"},
{"id":"EDSP257","title":"Understand/Taking Stand Tests"},
{"id":"ELED222","title":"Reading for Elementary School"},
{"id":"ENGL333","title":"Psycholinguistics"},
{"id":"ENGL344","title":"Ethnic American Literature"},
{"id":"ENGL348","title":"African American Literature"},
{"id":"ENGL354","title":"Classical Lit in Translation"},
{"id":"ENGL385","title":"Adv Studies in Women's Lit"},
{"id":"ENGL461","title":"Topics in British Literature"},
{"id":"ENGL482","title":"Independent Study"},
{"id":"ENVE201","title":"Fluid Mechanics"},
{"id":"ENVE211","title":"Statics and Solid Mechanics"},
{"id":"EOPT105","title":"Computer Interfacing in EOPT"},
{"id":"FCSE315","title":"Consumer Econ & Family Finance"},
{"id":"FCSE350","title":"Teaching Family Life Education"},
{"id":"FCSE482","title":"Independent Study"},
{"id":"FDED441","title":"Field Experiences Urban Ed"},
{"id":"FIN482","title":"Independent Study"},
{"id":"FIN493","title":"Finance Internship"},
{"id":"FRNC441","title":"Adv French Grammar"},
{"id":"FRNC460","title":"Studies French Literature"},
{"id":"FRNC482","title":"Independent Study"},
{"id":"FRNC493","title":"Internship in France"},
{"id":"FSMR212","title":"Adv Clothing Construction"},
{"id":"FSMR433","title":"Study Tour"},
{"id":"FSMR434","title":"Quality Control in Textiles"},
{"id":"GEOS105","title":"Exploring the Universe"},
{"id":"GEOS119","title":"Geology of Energy Resources"},
{"id":"GEOS281","title":"Special Topic"},
{"id":"GEOS345","title":"Igneous & Metamorphic Petrolog"},
{"id":"GEOS356","title":"Coastal Processes & Geology"},
{"id":"GEOS371","title":"Meteorology"},
{"id":"HIST215","title":"Researcher as Detective"},
{"id":"HIST265","title":"Hist of Power: Uses & Abuses"},
{"id":"HIST307","title":"History of Europe 1815-1914"},
{"id":"HIST310","title":"Making Italy Modern"},
{"id":"HIST320","title":"History of England to 1688"},
{"id":"HIST325","title":"History of Germany: 1849-pres"},
{"id":"HIST332","title":"History of Early China"},
{"id":"HIST343","title":"Civil War & Reconstruction"},
{"id":"IFMG210","title":"Intro to Front-End Bus Applica"},
{"id":"IFMG230","title":"Intro to Back-End Bus Applic"},
{"id":"IFMG456","title":"Bus Int, Info, Big Data Analy"},
{"id":"IFMG460","title":"Analysis and Logical Design"},
{"id":"IFMG468","title":"Information Tech (IT) Security"},
{"id":"IFMG493","title":"MIS Internship"},
{"id":"ITAL102","title":"Basic Italian II"},
{"id":"JAPN102","title":"Elementary Japanese II"},
{"id":"JAPN201","title":"Intermediate Japanese"},
{"id":"JRNL215","title":"Media Convergence/Journalism"},
{"id":"JRNL310","title":"Writing for Online Media"},
{"id":"JRNL400","title":"Professional Preparation"},
{"id":"JRNL450","title":"Advertising Writing"},
{"id":"JRNL493","title":"Internship"},
{"id":"KHSS135","title":"Careers in Kines, Hlth & Sport"},
{"id":"KHSS175","title":"Prevention & Care of Injuries"},
{"id":"MATH417","title":"Statistical Applications"},
{"id":"MATH418","title":"Sampling Survey Theory & Appl"},
{"id":"MATH482","title":"Independent Study"},
{"id":"MGMT481","title":"Special Topic"},
{"id":"MKTG432","title":"Business & Society"},
{"id":"MKTG433","title":"Advertising"},
{"id":"MUSC113","title":"Theory I Practicum"},
{"id":"MUSC122","title":"University Chorale"},
{"id":"MUSC127","title":"Percussion Ensemble"},
{"id":"MUSC134","title":"University Chorus"},
{"id":"MUSC139","title":"Piano Ensemble"},
{"id":"MUSC151","title":"Class Voice I"},
{"id":"MUSC154","title":"Class Piano II"},
{"id":"MUSC155","title":"Class Strings I"},
{"id":"MUSC190","title":"Intro to Music Education"},
{"id":"MUSC333","title":"Instrumental Methods"},
{"id":"OLOC165","title":"St Abroad/Yarmouk University"},
{"id":"OLOC168","title":"St Abroad/Sichuan University"},
{"id":"OLOC175","title":"St Abroad-UHI Millennium"},
{"id":"OLOC176","title":"St Abroad-Paris MBA Institute"},
{"id":"OLOC204","title":"Visiting Student-Clarion"},
{"id":"OLOC205","title":"Visiting Student-East Strouds"},
{"id":"OLOC212","title":"Visiting Student-Slippery Rock"},
{"id":"OLOC280","title":"Collaborative/Domestic FT"},
{"id":"OLOC281","title":"Collaborative/Domestic HT"},
{"id":"OLOC283","title":"Medical Technology Clinicals"},
{"id":"OLOC286","title":"IMAG/Sonography"},
{"id":"OLOC290","title":"CMDA Clinic Medical Assistant"},
{"id":"PHIL110","title":"Reasoning and the Law"},
{"id":"PHIL281","title":"Special Topic"},
{"id":"PHIL350","title":"Human Experience of TIme"},
{"id":"PHIL483","title":"Philosophy Honors Thesis"},
{"id":"PLSC482","title":"Independent Study"},
{"id":"PNAF131","title":"Intro to Pan-African Studies"},
{"id":"PNAF493","title":"Pan-African Studies Internship"},
{"id":"PSYC301","title":"Psyc Rsrch II: Qual Rsrch Meth"},
{"id":"RESP328","title":"Appl Concepts in Resp Care"},
{"id":"RESP334","title":"Resp Care Instrument & App"},
{"id":"RESP335","title":"Patient Care-Emergency Skills"},
{"id":"RESP451","title":"Resp Care Professional II"},
{"id":"RLST365","title":"Native North American Religion"},
{"id":"RLST482","title":"Independent Study"},
{"id":"SAFE211","title":"Prin Industrial Safety II"},
{"id":"SAFE220","title":"Hazard Material/Emergency Mgmt"},
{"id":"SAFE311","title":"Fire Protection"},
{"id":"SAFE435","title":"Ethics & Professionalism"},
{"id":"SOC337","title":"Society, Globalization & Risk"},
{"id":"SOC450","title":"Health Disparities"},
{"id":"SOC460","title":"Social Research Methods I"},
{"id":"SPAN482","title":"Independent Study"},
{"id":"SPLP311","title":"Aural Rehabilitation"},
{"id":"SPLP334","title":"Language Development"},
{"id":"SPLP401","title":"Comm & Soc Comp/Child w/Autism"},
{"id":"SPLP481","title":"Special Topic"},
{"id":"VOED103","title":"Strat Tchg Career & Tech Educ"},
{"id":"VOED405","title":"Prof Sem Career & Tech Educ"},
{"id":"PHYS105","title":"Physics of Light & Sound"},
{"id":"PHYS342","title":"Thermal & Statistical Physics"},
{"id":"PHYS401","title":"Theoretical Physics"},
{"id":"PLSC283","title":"American Foreign Policy"},
{"id":"PLSC360","title":"Classical Political Thought"},
{"id":"PLSC361","title":"Modern Political Thought"},
{"id":"PSYC373","title":"Adolescent Psychology"},
{"id":"PSYC377","title":"Interpers Skills & Grp Dynamic"},
{"id":"PSYC415","title":"Adv Topics-Dev Psychology"},
{"id":"PSYC480","title":"Honors Seminar in Psychology"},
{"id":"OLOC108","title":"St Abroad/Inst Shipboard Educ"},
{"id":"OLOC119","title":"St Abroad/Adam Michiewicz U"},
{"id":"SCI105","title":"Physical Science I"},
{"id":"SDR131","title":"Principles of Radiation"},
{"id":"SDR486","title":"Selected Topics in SDR"},
{"id":"ENGL122","title":"Intro to English Studies"},
{"id":"ENGL220","title":"Advanced Composition"},
{"id":"ENGL221","title":"Creative Writing"},
{"id":"ENGL227","title":"Introduction to Legal Writing"},
{"id":"ENGL307","title":"Contemporary British Lit"},
{"id":"FDNT212","title":"Nutrition"},
{"id":"FDNT245","title":"Sports Nutrition"},
{"id":"FDNT281","title":"Special Topic"},
{"id":"FDNT415","title":"Sustainable Nutrition"},
{"id":"FDNT465","title":"Nutrition Counseling & Educ"},
{"id":"FIN320","title":"Corporate Finance"},
{"id":"MGMT403","title":"Small Business Planning"},
{"id":"MGMT428","title":"Seminar:  Management"},
{"id":"EDEX481","title":"Special Topic"},
{"id":"EDHL307","title":"Speech for Deaf & Hard of Hrng"},
{"id":"EDHL361","title":"Method for Ed of Deaf & HHP II"},
{"id":"EDHL465","title":"Parent-Pre-Sch Prog for D&HHP"},
{"id":"RGPL103","title":"Global Cities: Issues Plan/Dev"},
{"id":"RGPL333","title":"Trade & Transportation"},
{"id":"RGPL404","title":"Transportation Planning"},
{"id":"RGPL410","title":"Cmnty Particip & Civic Engage"},
{"id":"RGPL415","title":"Remote Sensing"},
{"id":"DVST200","title":"Academic Transition Seminar"},
{"id":"ECED425","title":"Meth of Tchg & Asmt Lan Arts"},
{"id":"ECED480","title":"Tchr as Researcher & Advocate"},
{"id":"ECED499","title":"Advocacy and Collaboration"},
{"id":"ECON101","title":"Basic Economics"},
{"id":"ECON326","title":"Monetary Economics II"},
{"id":"MKTG445","title":"Social Media Marketing"},
{"id":"MKTG448","title":"Brand Design"},
{"id":"MLSC101","title":"Intro to Military Science"},
{"id":"MLSC203","title":"Fund Tactical Oper&Ldrshp Tech"},
{"id":"MLSC291","title":"Army ROTC Physical Fit Dev III"},
{"id":"MLSC292","title":"Army ROTC Physical Fit Dev IV"},
{"id":"MLSC407","title":"Mgmt Mil Complex & Mil Int Law"},
{"id":"MLSC491","title":"Army ROTC Physical Fit Dev VII"},
{"id":"MUHI333","title":"Hist of Popular Mus Since 1945"},
{"id":"EDUC250","title":"Labyrinth Seminar"},
{"id":"EDUC281","title":"Special Topic"},
{"id":"EDUC342","title":"Pre-Student Tchg Clin Exp II"},
{"id":"EDUC451","title":"Tchg Science in Second Sch"},
{"id":"EDUC481","title":"Special Topic"},
{"id":"EDUC482","title":"Independent Study"},
{"id":"GEOG254","title":"Geog of Russia/C Eurasia/E Eur"},
{"id":"GEOG255","title":"Geography of Africa"},
{"id":"GEOG281","title":"Special Topic"},
{"id":"GEOG333","title":"Trade & Transportation"},
{"id":"GEOG431","title":"Geog of American Indians"},
{"id":"GEOG484","title":"Field Study/Geog & Soc Studies"},
{"id":"THTR116","title":"Fund of Theatrical Design"},
{"id":"THTR240","title":"Acting I"},
{"id":"THTR322","title":"Costume Design"},
{"id":"THTR323","title":"Sound Design"},
{"id":"THTR347","title":"Playwriting"},
{"id":"COMM470","title":"Media Management and Sales"},
{"id":"COMM481","title":"Special Topic"},
{"id":"COMP2000","title":"Computer Accounting Applicatns"},
{"id":"COSC101","title":"Computer Literacy"},
{"id":"COSC105","title":"Fund of Computer Science"},
{"id":"CHHS281","title":"Special Topic"},
{"id":"CHIN201","title":"Intermediate Chinese"},
{"id":"CHSS121","title":"University Foundations"},
{"id":"CHSS481","title":"Special Topics"},
{"id":"CJTR3100","title":"Criminal Justice Trng PW _____"},
{"id":"CKRY2100","title":"Beef & Veal Prep & Cookery"},
{"id":"CMDA1000","title":"Keyboarding"},
{"id":"CMDA1500","title":"Basic Mathematics"},
{"id":"CMDA2020","title":"Medical Terminology II/Atmy&P"},
{"id":"CMGT2001","title":"Purchasing"},
{"id":"SPAN101","title":"Elementary Spanish I"},
{"id":"SPAN102","title":"Elementary Spanish II"},
{"id":"SPAN230","title":"Inter Spanish Comp & Grammar"},
{"id":"SPAN244","title":"Modern Mexico"},
{"id":"SPAN385","title":"Survey of Spanish Literature"},
{"id":"GEOS406","title":"Amer Southwest Field Workshop"},
{"id":"GEOS480","title":"Geoscience Seminar"},
{"id":"GRMN220","title":"Intermediate German Conversatn"},
{"id":"GRMN363","title":"Intro to German Literature I"},
{"id":"GRMN372","title":"Childhood Enchantment"},
{"id":"GRMN482","title":"Independent Study"},
{"id":"HAWK102","title":"Transfer Pre Orientation"},
{"id":"HAWK105","title":"Hawks Attack!"},
{"id":"HAWK200","title":"College Connections"},
{"id":"HAWK202","title":"Finding Your Success"},
{"id":"HBUS101","title":"Contemporary Business Issues"},
{"id":"HIST197","title":"Explorations in European Histo"},
{"id":"MUSC493","title":"Internship"},
{"id":"NMTT311","title":"Materials, Safety & Equip Over"},
{"id":"NURS202","title":"Foundations of Child Health"},
{"id":"LIBR152","title":"Info Iss & Resources for Hlth"},
{"id":"MATH110","title":"Elementary Functions"},
{"id":"MATH153","title":"Elements of Algebra"},
{"id":"MATH271","title":"Intro Math Proofs I"},
{"id":"CRIM101","title":"Crime & Justice Systems"},
{"id":"CRIM215","title":"Survey of Courts & Cr Just Sys"},
{"id":"CRIM374","title":"Environmental Crime & Justice"},
{"id":"CRIM403","title":"Dilemmas in Crim & Crim Justic"},
{"id":"BIOL310","title":"App Entomology & Zoonoses"},
{"id":"BIOL362","title":"Ecology"},
{"id":"BIOL469","title":"Circadian Rhythms and Sleep"},
{"id":"BIOL477","title":"Neurobiology"},
{"id":"ALI1300","title":"Speaking Out"},
{"id":"ALI2500","title":"Exploring American Life II"},
{"id":"ANTH213","title":"World Archaeology"},
{"id":"ANTH281","title":"Special Topic"},
{"id":"BTED132","title":"Computer Keyboard & Formatting"},
{"id":"INDS315","title":"Residential Design Studio"},
{"id":"HNRC281","title":"Special Topic /H/"},
{"id":"HOSP212","title":"Club Operations Management"},
{"id":"HOSP411","title":"Contemp Issues in Hospitality"},
{"id":"HOSP460","title":"Labor & Employee Relations"},
{"id":"HOSP494","title":"Internship"},
{"id":"KHSS217","title":"Tchg Middle School Physical Ed"},
{"id":"KHSS251","title":"Found Safety & Emerg Hlth Care"},
{"id":"KHSS316","title":"Instr of Child Health Concepts"},
{"id":"KHSS318","title":"Preprofessional Experience I"},
{"id":"KHSS365","title":"Ortho Injury Assessment/ATHL"},
{"id":"KHSS370","title":"Adapted Health & Physical Educ"},
{"id":"KHSS380","title":"Organiz & Administration/ATHL"},
{"id":"KHSS411","title":"Physical Fitness Appraisal"},
{"id":"KHSS420","title":"Health Ed & Prom at Workplace"},
{"id":"KHSS476","title":"Athl Trng Clinic Practicum III"},
{"id":"NURS493","title":"Nursing Internship"},
{"id":"ART215","title":"Sculpture"},
{"id":"ART316","title":"Intermediate Jewelry & Metals"},
{"id":"APMU156","title":"Private Violin II"},
{"id":"APMU163","title":"Private Bassoon II"},
{"id":"APMU202","title":"Private Organ III"},
{"id":"APMU209","title":"Private String Bass III"},
{"id":"APMU251","title":"Private Piano IV"},
{"id":"HIST356","title":"African Hist II: 1600-Present"},
{"id":"HIST362","title":"Hist of Americ Diplomacy 1900+"},
{"id":"HIST372","title":"Early American Working Class"},
{"id":"HIST423","title":"Oral History Practice"},
{"id":"CEU2141","title":"Accident Investigation II"},
{"id":"CEU4361","title":"Intro to Microsoft Powerpoint"},
{"id":"CEU4367","title":"Introduction to the Internet"},
{"id":"CEU4377","title":"Realizing Leadership Potential"},
{"id":"CEU4386","title":"Beginning Microsoft Excel"},
{"id":"CEU4389","title":"Intermediate Microsoft Word"},
{"id":"CEU4405","title":"Multi-Hazard Safety Sch Prog"},
{"id":"CEU4407","title":"Health Ministry in Action"},
{"id":"CEU4412","title":"Capstone Course"},
{"id":"CEU4414","title":"Skills for Effect Leadership"},
{"id":"CEU4422","title":"Skills for Effective Leadrshp"},
{"id":"CEU4427","title":"Beginning MS Access"},
{"id":"CEU4430","title":"Advanced Microsoft Access"},
{"id":"CEU4439","title":"PCH: First Aid & CPR"},
{"id":"CEU4444","title":"MS Word-Mail Merge"},
{"id":"CEU4445","title":"MS Word-Automation Techniques"},
{"id":"CEU4447","title":"Conducting Research Using WWW"},
{"id":"CEU4453","title":"MS Excel Refresher Course"},
{"id":"CEU4454","title":"MS Excel-Pivot Tables/Charts"},
{"id":"CEU4456","title":"MS Word Refresher"},
{"id":"CEU4457","title":"Working with Tables"},
{"id":"CRLG161","title":"Portuguese II"},
{"id":"CRLG165","title":"Swahili II"},
{"id":"CRLG208","title":"Japanese III"},
{"id":"CRLG259","title":"Korean IV"},
{"id":"CRLG262","title":"Russian IV"},
{"id":"CRLG264","title":"Hebrew IV"},
{"id":"ACCT201","title":"Accounting Principles I"},
{"id":"ACCT303","title":"Financial System Analysis"},
{"id":"ACCT422","title":"Federal Tax II"},
{"id":"ACCT432","title":"Forensic & Internal Accounting"},
{"id":"ACCT441","title":"Acct for Govt & Non-Profit Org"},
{"id":"ANTH370","title":"Latinos and Diasporas"},
{"id":"ANTH456","title":"Ethnographic Research Methods"},
{"id":"ANTH483","title":"Honors Thesis"},
{"id":"APMU105","title":"Private Voice I"},
{"id":"APMU106","title":"Private Violin I"},
{"id":"APMU116","title":"Private French Horn I"},
{"id":"APMU117","title":"Private Trombone I"},
{"id":"APMU153","title":"Private Harpsichord II"},
{"id":"APMU262","title":"Private Oboe IV"},
{"id":"APMU264","title":"Private Saxophone IV"},
{"id":"APMU314","title":"Private Saxophone V"},
{"id":"APMU320","title":"Private Percussion V"},
{"id":"APMU366","title":"Private French Horn VI"},
{"id":"APMU404","title":"Private Harp VII"},
{"id":"APMU416","title":"Private French Horn VII"},
{"id":"APMU420","title":"Private Percussion VII"},
{"id":"APMU460","title":"Private Flute VIII"},
{"id":"APMU465","title":"Private Trumpet VIII"},
{"id":"ARED318","title":"Art in 7-12 Programs"},
{"id":"ARED423","title":"Comm-Based & Museum Art Educ"},
{"id":"ARHI224","title":"Introduction to Asian Art"},
{"id":"ARHI407","title":"Medieval Art"},
{"id":"ARHI413","title":"Senior Seminar"},
{"id":"BIOC480","title":"Biochemistry Seminar I"},
{"id":"BIOL117","title":"Understand HIV Biology & Aids"},
{"id":"BIOL119","title":"Emerging Diseases"},
{"id":"BIOL155","title":"Human Physiology & Anatomy"},
{"id":"BKPS1000","title":"Baking Theory and Practice"},
{"id":"BKPS1081","title":"Special Project Course"},
{"id":"CDFR218","title":"Child Development"},
{"id":"CDFR323","title":"Family Issues"},
{"id":"CHEM100","title":"Preparatory Chemistry"},
{"id":"CHEM113","title":"Adv General Chemistry I"},
{"id":"CHEM290","title":"Chemistry Seminar I"},
{"id":"CHEM351","title":"Biochemistry"},
{"id":"CHEM493","title":"Internship"},
{"id":"CNSD150","title":"Improving Soc & Emotnl Intel"},
{"id":"COMM150","title":"Media Aesthetics"},
{"id":"COMM281","title":"Special Topic"},
{"id":"COMM355","title":"Editing for Video and Film"},
{"id":"COMM375","title":"Mass Media & Behavior"},
{"id":"COMM440","title":"Multimedia Production"},
{"id":"COSC345","title":"Computer Networks"},
{"id":"COSC465","title":"Distributed Process & Web Serv"},
{"id":"CRIM482","title":"Independent Study"},
{"id":"DANC150","title":"Fundamentals of Dance"},
{"id":"DISB369","title":"Ed Emo/Behav/Lrng Dis/BrainInj"},
{"id":"DVST93","title":"Dev Math, Elements of Algebra"},
{"id":"ECON331","title":"Industrial Organization"},
{"id":"ECON339","title":"Economic Development I"},
{"id":"ECON421","title":"Macroeconomic Analysis"},
{"id":"EDEX440","title":"Ethical & Profession Behavior"},
{"id":"EDEX458","title":"Transition for Youth w/Disabil"},
{"id":"EDSP378","title":"Learning"},
{"id":"EDSP476","title":"Found of Behavior Analysis"},
{"id":"ELED281","title":"Special Topic"},
{"id":"ELED482","title":"Independent Study"},
{"id":"ELR314","title":"Heatlh Policy and Law"},
{"id":"ENGL329","title":"History of English Language"},
{"id":"ENGL330","title":"Structure of English"},
{"id":"ENGL387","title":"Irish Literature"},
{"id":"ENGL434","title":"Shakespeare"},
{"id":"ENGL440","title":"Major Figures in Film"},
{"id":"ENVE311","title":"Water Resources Engineering"},
{"id":"EXSP4100","title":"Baking & Pastry Externship"},
{"id":"FCSE450","title":"Tchg Voc & Family Consu Sci Ed"},
{"id":"FRNC370","title":"Intro to French Literature"},
{"id":"FRNC462","title":"French Lang&Linguistics"},
{"id":"FSMR195","title":"Comp Aid Design/Fashion Prof"},
{"id":"FSMR252","title":"Aesthetics of Fashion"},
{"id":"FSMR258","title":"Fashion Brand Merchandising"},
{"id":"GEOS101","title":"The Dynamic Earth"},
{"id":"GEOS152","title":"Physical Resources of Earth"},
{"id":"GEOS201","title":"Foundations of Geology"},
{"id":"GEOS301","title":"Mineralogy"},
{"id":"GEOS311","title":"Geochemistry"},
{"id":"GEOS312","title":"Hydrogeology"},
{"id":"HIST220","title":"Visual Sources in History"},
{"id":"HIST303","title":"Medieval Europe: 400-1000"},
{"id":"HIST323","title":"France, 1815 to the Present"},
{"id":"HIST327","title":"Soviet & Contemporary Russia"},
{"id":"HIST334","title":"History of Modern China"},
{"id":"HIST338","title":"History of Iran"},
{"id":"HIST342","title":"The Early Republic"},
{"id":"IFMG110","title":"Business Spreadsheet Comp"},
{"id":"IFMG250","title":"Business Systems Technology"},
{"id":"IFMG414","title":"Internet of Things"},
{"id":"IFMG431","title":"Python Programming"},
{"id":"IFMG481","title":"Special Topic"},
{"id":"ITAL202","title":"Intermediate Italian IV"},
{"id":"JAPN281","title":"Special Topics"},
{"id":"JRNL105","title":"Journalism & Mass Media"},
{"id":"JRNL243","title":"History of American Press"},
{"id":"JRNL328","title":"News Reporting"},
{"id":"JRNL375","title":"World News Coverage"},
{"id":"JRNL482","title":"Independent Study"},
{"id":"JRNL483","title":"Honors Thesis"},
{"id":"MATH371","title":"Linear Algebra"},
{"id":"MATH476","title":"Abstract Algebra I"},
{"id":"MGMT437","title":"Supply Chain Modeling & Analys"},
{"id":"MGMT462","title":"Applied Bus Leadership Skills"},
{"id":"MGMT482","title":"Independent Study"},
{"id":"MIDL222","title":"Reading Instr & Assess/Gr 4-8"},
{"id":"MIDL310","title":"Instr Theory & Plan/Middle Lvl"},
{"id":"MIDL321","title":"21st CentryLit/Diverse Lrnrs"},
{"id":"MKTG320","title":"Principles of Marketing"},
{"id":"MUSC111","title":"Theory Skills I"},
{"id":"MUSC124","title":"Concert Band"},
{"id":"MUSC140","title":"Popular Music Ensemble"},
{"id":"MUSC161","title":"Class Woodwinds I"},
{"id":"MUSC312","title":"Choral Conducting"},
{"id":"NURS316","title":"Evidence-Based Practice/Nursng"},
{"id":"NURS334","title":"Transitions in Prof Nursing"},
{"id":"NURS336","title":"Adult Health I"},
{"id":"NURS436","title":"Adult Health II"},
{"id":"OLOC161","title":"St Abroad/North South Univ"},
{"id":"OLOC166","title":"St Abroad/Amer Intercontintl U"},
{"id":"OLOC173","title":"St Abroad/Tempre U of Tech"},
{"id":"OLOC206","title":"Visiting Student-Edinboro"},
{"id":"OLOC213","title":"Visiting Student-West Chester"},
{"id":"OLOC271","title":"IUP Culinary Arts Collab"},
{"id":"OLOC282","title":"Collaborative/Domestic 3QT"},
{"id":"OLOC284","title":"IMAG/Echocardiography Clinical"},
{"id":"PHIL101","title":"Critical Thinking"},
{"id":"PHIL221","title":"Symbolic Logic"},
{"id":"PHIL323","title":"Political Philosophy"},
{"id":"PHIL324","title":"Ancient Greek Philosophy"},
{"id":"PLSC480","title":"Seminar: Political Science"},
{"id":"PSYC235","title":"Social Psychology"},
{"id":"PSYC335","title":"Experimental Social Psychology"},
{"id":"PSYC341","title":"Conditioning & Learning"},
{"id":"QBUS481","title":"Special Topic"},
{"id":"RESP329","title":"Respiratory Care Equipment"},
{"id":"RESP426","title":"Respiratory Care Clinical III"},
{"id":"RESP430","title":"Cardiopulmonary Diagnostics"},
{"id":"RLST375","title":"Religions of India"},
{"id":"RLST385","title":"Christianity"},
{"id":"RLST481","title":"Special Topic"},
{"id":"RLST483","title":"Honors Thesis"},
{"id":"RLST485","title":"Topic: Feminist St of Religion"},
{"id":"SAFE345","title":"Systems Safety Analysis"},
{"id":"SAFE412","title":"Hazard Prevention Mgmt II"},
{"id":"SAFE493","title":"Internship"},
{"id":"SOC151","title":"Principles of Sociology"},
{"id":"SOC363","title":"Sociology of Gender"},
{"id":"SOC392","title":"Clinical Sociological Practice"},
{"id":"SOC421","title":"Sociology of Mass Media"},
{"id":"SOC427","title":"Soc Persp on Partner Violence"},
{"id":"SOC428","title":"Child Abuse"},
{"id":"SOC461","title":"Social Research Methods II"},
{"id":"SOC481","title":"Special Topic"},
{"id":"SOC482","title":"Independent Study"},
{"id":"SPAN410","title":"Medieval Literature"},
{"id":"SPAN411","title":"Golden Age Spanish"},
{"id":"SPAN430","title":"20th Cent Spanish-Amer Prose"},
{"id":"SPAN493","title":"Internship"},
{"id":"SPLP254","title":"Class Mgmt of Lang Disorders"},
{"id":"SPLP310","title":"Observation Comm Disorders I"},
{"id":"SPLP482","title":"Independent Study"},
{"id":"VOED395","title":"Voc Ed Writing Experience"},
{"id":"PHYS112","title":"Physics II Lecture"},
{"id":"PHYS451","title":"Electricity & Magnetism"},
{"id":"PHYS461","title":"Quantum Mechanics I"},
{"id":"PLSC281","title":"Special Topic"},
{"id":"PLSC355","title":"Intergovernmental Relations"},
{"id":"PSYC359","title":"Sensation and Perception"},
{"id":"OLOC104","title":"St Abroad/Korea-Sejong"},
{"id":"OLOC105","title":"St Abroad/U of Duisburg-Best"},
{"id":"OLOC114","title":"St Abroad/Inst Commercl Nancy"},
{"id":"OLOC115","title":"St Abroad/Nagoya U of Frgn Stu"},
{"id":"OLOC124","title":"St Abroad/ISEP"},
{"id":"OLOC126","title":"St Abroad/METU"},
{"id":"OLOC127","title":"St Abroad/U of Zagreb-Music"},
{"id":"OLOC135","title":"St Abroad/U of Nurenberg"},
{"id":"OLOC151","title":"St Abroad/U of Nancy 2-Summer"},
{"id":"SCI103","title":"Fund of Earth & Space Science"},
{"id":"SCI107","title":"Chemistry for Everyone"},
{"id":"SCI201","title":"Great Ideas in Science"},
{"id":"ENGL202","title":"English Composition II"},
{"id":"ENGL210","title":"British Literature to 1660"},
{"id":"ENGL213","title":"British & Amer Lit Since 1900"},
{"id":"ENGL225","title":"Intro to Literature By Women"},
{"id":"ENGL281","title":"Special Topic"},
{"id":"ENGL308","title":"Critical Theory"},
{"id":"ENGL309","title":"Dramaturgy"},
{"id":"ENGL321","title":"Persuasive Speech & Writing"},
{"id":"FDNT481","title":"Special Topic"},
{"id":"FIN426","title":"Seminar--Financial Planning"},
{"id":"MGMT402","title":"Seminar: Human Resource Mgmt"},
{"id":"EDEX460","title":"Family Perspectives on Disabil"},
{"id":"EDEX482","title":"Independent Study"},
{"id":"EDHL114","title":"Intro to Deaf & Hard of Hearng"},
{"id":"EDHL329","title":"Tchg Collaborative Practicum I"},
{"id":"EDHL330","title":"Collaborative Practicum II"},
{"id":"RGPL313","title":"Cartography II"},
{"id":"RGPL358","title":"Planning History & Theory"},
{"id":"RGPL421","title":"Regional GIS Management"},
{"id":"RGPL440","title":"Conservation: Environ Analysis"},
{"id":"ECED117","title":"Relationships in a Diverse Soc"},
{"id":"ECED351","title":"Lit for Emerg Reader PreK-Grd1"},
{"id":"ECED411","title":"Social Studies/PreK-Gr4"},
{"id":"MKTG437","title":"Services Marketing"},
{"id":"MKTG481","title":"Special Topic"},
{"id":"MLSC204","title":"Nat Security&Milit Topography"},
{"id":"MLSC391","title":"Army ROTC Physical Fit Dev B"},
{"id":"MLSC408","title":"Seminar: Mil Analysis & Mgmt"},
{"id":"EDUC221","title":"Smr Ess Skills Peer Mentoring"},
{"id":"EDUC242","title":"Pre-Student Tchg Clin Exp I"},
{"id":"EDUC452","title":"Tchg English & Comm in Sec Sch"},
{"id":"EDUC453","title":"Tchg For Lang in Second Sch"},
{"id":"GEOG102","title":"Geography of US & Canada"},
{"id":"GEOG105","title":"Our Digital Earth"},
{"id":"GEOG252","title":"Geography of Latin America"},
{"id":"GEOG331","title":"Population Geography"},
{"id":"GEOG336","title":"Social Geography"},
{"id":"GEOG352","title":"Planning Methods"},
{"id":"GEOG421","title":"Enterprise GIS Management"},
{"id":"GEOG462","title":"Planning Policy, Implem & Admn"},
{"id":"SUST201","title":"Intro Sustainability Studies"},
{"id":"THTR101","title":"Introduction to Theatre"},
{"id":"THTR131","title":"Stage Movement"},
{"id":"THTR213","title":"History & Lit: Modern & Contmp"},
{"id":"THTR214","title":"Theatre Hist & Lit: Tragedy"},
{"id":"THTR340","title":"Acting II"},
{"id":"THTR373","title":"Musical Theater Scene Study"},
{"id":"THTR484","title":"Directing Studio"},
{"id":"COMM477","title":"Freelancing & Entrepren in Med"},
{"id":"COSC220","title":"Enterprise Computing"},
{"id":"CJTR1081","title":"Criminal Justice Training"},
{"id":"CJTR1300","title":"Criminal Justice Trng - PT III"},
{"id":"CJTR3000","title":"Criminal Justice Trng PW _____"},
{"id":"CKRY2201","title":"Pork, Lamb, & Game Prep & Cook"},
{"id":"CMDA1020","title":"Interpersonal Dynamics"},
{"id":"CMDA2000","title":"Computer Integration Technique"},
{"id":"CMDA3060","title":"Patient Care Lab II"},
{"id":"SPAN281","title":"Special Topic"},
{"id":"SPAN340","title":"Hispanic Civ through 19th Cent"},
{"id":"SPAN344","title":"Span-Amer Cult. 19th Cen - Prs"},
{"id":"GEOS403","title":"Newfoundland Seminar"},
{"id":"GRMN101","title":"Elementary German I"},
{"id":"GRMN102","title":"Elementary German II"},
{"id":"GRMN256","title":"Scientific German"},
{"id":"GRMN321","title":"Adv Conversation I"},
{"id":"GRMN368","title":"20th-Century German Literature"},
{"id":"GRMN390","title":"Tchg Elem Content Frnch & Grmn"},
{"id":"HAWK101","title":"HAWKS 101"},
{"id":"HAWK112","title":"Six O'Clock Series"},
{"id":"HAWK113","title":"Just for Fun!"},
{"id":"HAWK201","title":"Your Journey Starts Now!"},
{"id":"HAWK203","title":"Financial Literacy/PNC Pres"},
{"id":"HIST106","title":"Samurai & Gongfu Heroes"},
{"id":"HIST198","title":"Explorations in Global History"},
{"id":"MUSC411","title":"Music Composition I"},
{"id":"MUSC412","title":"Music Composition II"},
{"id":"MUSC413","title":"Music Composition III"},
{"id":"MUSC482","title":"Independent Study"},
{"id":"NMDT432","title":"Radiopharmaceuticals"},
{"id":"NMTT313","title":"Thin Films - Nanofabrication"},
{"id":"NURS102","title":"Disaster Prep & Hlth Issues"},
{"id":"NURS211","title":"Fundamentals I Clinical"},
{"id":"MATH101","title":"Foundations of Math"},
{"id":"MATH108","title":"Applic of Business Mathematics"},
{"id":"MATH121","title":"Calculus I/ NatSci,SocSci,Bus"},
{"id":"MATH150","title":"Strategy Success College Math"},
{"id":"MATH151","title":"Elements of Math I"},
{"id":"MATH152","title":"Elements of Math II"},
{"id":"MATH214","title":"Probability & Stats/Bus Mjrs"},
{"id":"CRIM225","title":"Survey of Corrections"},
{"id":"CRIM255","title":"Law, Social Control, & Society"},
{"id":"CRIM281","title":"Special Topic"},
{"id":"CRIM306","title":"Criminological Rsrch Methods"},
{"id":"CRIM384","title":"Violence & Victimology"},
{"id":"CRIM391","title":"Subst Use & Abuse in Crim Just"},
{"id":"BIOL220","title":"General Zoology"},
{"id":"BIOL240","title":"Human Physiology"},
{"id":"BIOL250","title":"Principles of Microbiology"},
{"id":"BIOL401","title":"Lab Methods in Bio & Biotech"},
{"id":"BIOL450","title":"Field Bio at Pymatuning Lab Ec"},
{"id":"BIOL475","title":"Mammalogy"},
{"id":"BIOL483","title":"Honors Thesis"},
{"id":"BIOL484","title":"Honors Seminar"},
{"id":"BIOL493","title":"Internship"},
{"id":"ALI1500","title":"Exploring American Life I"},
{"id":"ALI2200","title":"Start Reading"},
{"id":"ALI2350","title":"Speaking Clearly"},
{"id":"ALI3300","title":"Conversation Practice"},
{"id":"ALI3350","title":"Speaking Clearly II"},
{"id":"ALI3600","title":"Intermediate English Grammar I"},
{"id":"ALI4210","title":"Self-Directed Reading"},
{"id":"ANTH310","title":"Voyages of Discovery"},
{"id":"ANTH320","title":"Archaeological Field School"},
{"id":"BTED401","title":"Web Design"},
{"id":"INDS218","title":"Computer Tech/Interior Design"},
{"id":"INDS470","title":"Interior Design Capstone"},
{"id":"HNRC101","title":"Honors Core I /H/"},
{"id":"HOSP101","title":"Intro to Hospitality Industry"},
{"id":"HOSP245","title":"Multicultural Mgmt in Hosptly"},
{"id":"HOSP250","title":"Resort Management"},
{"id":"HOSP260","title":"Hotel Operations Management"},
{"id":"HOSP341","title":"Prof Wedding Plan & Consulting"},
{"id":"HOSP347","title":"Meeting and Convention Mgmt"},
{"id":"HOSP482","title":"Independent Study"},
{"id":"KHSS215","title":"Tchg Rhythmic Activity & Dance"},
{"id":"KHSS218","title":"Ins. Tact. Skls & Fit Concepts"},
{"id":"KHSS263","title":"Aquatics"},
{"id":"KHSS426","title":"Health Science Instruction"},
{"id":"KHSS450","title":"Curriculum & Prog in Sex Ed"},
{"id":"KHSS465","title":"Sport Management Capstone"},
{"id":"LAS482","title":"Independent Study"},
{"id":"LAS493","title":"Internship"},
{"id":"LDSP361","title":"Foundations of Leadership"},
{"id":"LGBT200","title":"Intro to LGBT & Q Studies"},
{"id":"ARHI427","title":"Japanese Narrative Art"},
{"id":"ART111","title":"Figure Drawing"},
{"id":"ART216","title":"Jewelry and Metals"},
{"id":"ART281","title":"Special Topic"},
{"id":"ART454","title":"Adv Painting"},
{"id":"ART455","title":"Adv Graphic Design I"},
{"id":"ART456","title":"Advanced Graphic Design II"},
{"id":"ART493","title":"Internship"},
{"id":"APMU225","title":"Composition II"},
{"id":"HIST369","title":"Women in America"},
{"id":"HIST379","title":"History in the Digital Age"},
{"id":"HIST420","title":"Intro to Public History"},
{"id":"HIST434","title":"Modern China 1800-Present"},
{"id":"HIST496","title":"Topics in European History"},
{"id":"CEU2038","title":"Lethal Weapons-Recertification"},
{"id":"CEU2131","title":"Accident Investigation I"},
{"id":"CEU2171","title":"Act 120 Police Academy"},
{"id":"CEU4370","title":"Internet II"},
{"id":"CEU4385","title":"Microsoft Outlook"},
{"id":"CEU4390","title":"Intermediate Microsoft Excel"},
{"id":"CEU4404","title":"Pract Appr to OSHA Compliance"},
{"id":"CEU4415","title":"PCH: Unusual Incidents in PCHs"},
{"id":"CEU4417","title":"Intermediate Microsoft Word"},
{"id":"CEU4428","title":"Intermediate MS Access"},
{"id":"CEU4437","title":"Sfty & Hlth Update for PC Faci"},
{"id":"CRLG158","title":"Japanese II"},
{"id":"CRLG213","title":"Swedish III"},
{"id":"ACCT311","title":"Cost Accounting"},
{"id":"ACCT461","title":"Accounting Systems"},
{"id":"ANTH325","title":"Archaeological Lab Methods"},
{"id":"ANTH415","title":"Cultural Resource Management"},
{"id":"ANTH430","title":"Anthropology of Food"},
{"id":"ANTH481","title":"Special Topic"},
{"id":"ANTH488","title":"Geophys. Appl in Archaeology"},
{"id":"ANTH492","title":"Soil Science: Arch & Geoenv Ap"},
{"id":"ANTH496","title":"Human Osteology"},
{"id":"APMU101","title":"Private Piano I"},
{"id":"APMU121","title":"Private Guitar I"},
{"id":"APMU125","title":"Composition I"},
{"id":"APMU270","title":"Private Percussion IV"},
{"id":"APMU302","title":"Private Organ V"},
{"id":"APMU306","title":"Private Violin V"},
{"id":"APMU311","title":"Private Clarinet V"},
{"id":"APMU359","title":"Private String Bass VI"},
{"id":"APMU360","title":"Private Flute VI"},
{"id":"APMU407","title":"Private Viola VII"},
{"id":"APMU415","title":"Private Trumpet VII"},
{"id":"APMU451","title":"Private Piano VIII"},
{"id":"APMU452","title":"Private Organ VIII"},
{"id":"APMU455","title":"Private Voice VIII"},
{"id":"APMU457","title":"Private Viola VIII"},
{"id":"APMU461","title":"Private Clarinet VIII"},
{"id":"ARHI281","title":"Special Topic"},
{"id":"ARHI418","title":"African Art"},
{"id":"ASIA482","title":"Independent Study"},
{"id":"BIOC301","title":"Foundations of Biochemistry"},
{"id":"BIOC490","title":"Biochem Seminar II"},
{"id":"BIOL116","title":"Human Genetics & Health Lab"},
{"id":"BKPS1002","title":"Viennoiserie & Quick Breads"},
{"id":"BKPS2000","title":"Artisan Chocolates"},
{"id":"BKPS2002","title":"Sugar Showpieces"},
{"id":"BKPS2003","title":"Custards and Frozen Desserts"},
{"id":"BKPS2004","title":"Specialty Cake Deccorating"},
{"id":"BLAW441","title":"Law of Property Oil/Gas Lsg I"},
{"id":"CHEM112","title":"General Chemistry II"},
{"id":"CHEM326","title":"Analytical Chemistry II"},
{"id":"CHEM444","title":"Adv Physical Chemistry Lab"},
{"id":"CHEM461","title":"Modern Diffraction"},
{"id":"CHEM498","title":"Problems in Chemistry"},
{"id":"CMGT3000","title":"American Table Service"},
{"id":"COMM207","title":"Online Media Production"},
{"id":"COMM271","title":"Beginning Photography"},
{"id":"COMM371","title":"Advanced Photography"},
{"id":"COMM446","title":"3D Model & Anim/Games & Sim"},
{"id":"COSC380","title":"Smnr on Comp Profess & Ethic"},
{"id":"COSC410","title":"Computer Architecture"},
{"id":"CRIM470","title":"Comparative Study of Justice"},
{"id":"CULN1082","title":"Culinary Independ Study/Fall"},
{"id":"DANC102","title":"Introduction to Dance"},
{"id":"DANC260","title":"Beginning Jazz Dance"},
{"id":"DANC290","title":"Ethnic Dance"},
{"id":"DVST70","title":"Rdng Skills for College Study"},
{"id":"ECON483","title":"Economics Honors Thesis"},
{"id":"EDEX0.369","title":"Ed Emo/Behav/Lrng Dis/BrainInj"},
{"id":"EDEX111","title":"Intro to Exceptional Persons"},
{"id":"EDEX223","title":"Reading Methods and Strategies"},
{"id":"EDEX300","title":"Ed Stu w/Dis in Inclu El Clsrm"},
{"id":"EDEX323","title":"Instr of Engl Lang w/Spec Need"},
{"id":"EDEX425","title":"Meth&Curriculum/Mild-Moderate"},
{"id":"EDSP423","title":"Educ Programming: Gifted Lrnrs"},
{"id":"ENGL325","title":"Writing Poetry"},
{"id":"ENGL349","title":"English Bible as Literature"},
{"id":"ENGL421","title":"Digital Writing"},
{"id":"ENGL424","title":"Second Language Acquisition"},
{"id":"ENGL437","title":"Major Global Authors"},
{"id":"ENGL450","title":"Film Theory"},
{"id":"ENGL484","title":"Topics in English Studies"},
{"id":"EOPT110","title":"Geometric Optics"},
{"id":"EOPT260","title":"Industrial Applic of Lasers"},
{"id":"FRNC376","title":"French Literature 1700-1900"},
{"id":"FRNC390","title":"Tchg Elem Content Frnch & Grmn"},
{"id":"FRNC483","title":"French Honors Thesis"},
{"id":"GEOS151","title":"Age of Dinosaurs"},
{"id":"GEOS154","title":"Human Exploration of Space"},
{"id":"GEOS155","title":"Geology of Climate Change"},
{"id":"GEOS324","title":"Geology of Oil and Gas"},
{"id":"GEOS354","title":"Geomorphology"},
{"id":"HIST230","title":"Queer Global History"},
{"id":"HIST281","title":"Special Topic"},
{"id":"HIST302","title":"History of Rome"},
{"id":"IFMG465","title":"Enterp Resource Plan Tech Fund"},
{"id":"IFMG475","title":"Project Mgmt & Implementation"},
{"id":"ITAL101","title":"Basic Italian I"},
{"id":"ITAL201","title":"Intermediate Italian III"},
{"id":"ITAL482","title":"Independent Study"},
{"id":"JPRJ3000","title":"Culinary Professn Portfiolio 3"},
{"id":"JRNL261","title":"Intro to the Magazine Industry"},
{"id":"JRNL337","title":"Editing"},
{"id":"JRNL347","title":"Journalism Law & Ethics"},
{"id":"JRNL348","title":"Opinion Writing"},
{"id":"JRNL361","title":"Presentation Making"},
{"id":"JRNL481","title":"Special Topic"},
{"id":"KHSS145","title":"Foundations Exercise Science"},
{"id":"KHSS185","title":"Intro to Athletic Training"},
{"id":"MATH317","title":"Prob & Stats Elem/Middle Level"},
{"id":"MATH341","title":"Differential Equations"},
{"id":"MATH364","title":"Math Statistics II"},
{"id":"MATH412","title":"Multivariate Statistics"},
{"id":"MATH420","title":"Pattrns & Func Elem/Middle Lvl"},
{"id":"MATH447","title":"Modeling and Simulation"},
{"id":"MATH448","title":"Intro to Financial Mathematics"},
{"id":"MATH461","title":"Discrete Math/Middle Lvl Tchrs"},
{"id":"MATH480","title":"Mathematics Seminar"},
{"id":"MGMT451","title":"International Management"},
{"id":"MIDL311","title":"Soc St instr & Assess/Gr 4-8"},
{"id":"MIDL425","title":"Methods Tchg Lang Arts Gr 4-8"},
{"id":"MKTG321","title":"Consumer Behavior"},
{"id":"MKTG350","title":"International Business"},
{"id":"MKTG420","title":"Marketing Management"},
{"id":"MKTG435","title":"Profession Selling&Sales Mgmt"},
{"id":"MUSC110","title":"Fundamentals of Theory"},
{"id":"MUSC153","title":"Class Piano I"},
{"id":"MUSC216","title":"Theory IV"},
{"id":"MUSC311","title":"Fundamental of Conducting"},
{"id":"MUSC331","title":"Elementary Methods"},
{"id":"MUSC337","title":"General/Choral Methods"},
{"id":"NURS311","title":"Intro to Nursing Informatics"},
{"id":"NURS312","title":"Professional Nursing"},
{"id":"NURS330","title":"Care of Child & Family"},
{"id":"NURS414","title":"Hlth Policy & Patient Advocacy"},
{"id":"NURS432","title":"Psychiatric/Mental Health"},
{"id":"OLOC160","title":"St Abroad/Central Paulista U"},
{"id":"OLOC162","title":"St Abroad/Italy (SAI)"},
{"id":"OLOC164","title":"St Abroad/Tamkang University"},
{"id":"OLOC285","title":"IMAG/Nuclear Med Tech Clinical"},
{"id":"PHIL100","title":"Introduction to Philosophy"},
{"id":"PHIL390","title":"Philosophy of Human Nature"},
{"id":"PLSC405","title":"Sexuality and Law"},
{"id":"PLSC465","title":"Intelligence Process & Policy"},
{"id":"PLSC493","title":"Internship"},
{"id":"PSYC200","title":"Psychological Research"},
{"id":"PSYC215","title":"Developmental Psychology"},
{"id":"PSYC280","title":"Psychological Inquiry"},
{"id":"PUBH122","title":"Foundations of Public Health"},
{"id":"PUBH481","title":"Special Topic"},
{"id":"PUBH493","title":"Internship in Public Health"},
{"id":"QBUS482","title":"Independent Study"},
{"id":"RESP327","title":"Pharmacology"},
{"id":"RESP336","title":"Cardio Eval & Clinic Correlatn"},
{"id":"RESP425","title":"Clinical Case Studies"},
{"id":"RESP435","title":"Resp Care Across Continuum"},
{"id":"RLST345","title":"Women in the Bible"},
{"id":"RLST403","title":"Topic/Indigenous Religions"},
{"id":"RLST480","title":"Seminar in Religious Studies"},
{"id":"SAFE299","title":"Experience in Coop Educ I"},
{"id":"SAFE347","title":"Ergonomics"},
{"id":"SOC251","title":"Sociology of Human Sexuality"},
{"id":"SOC281","title":"Special Topic"},
{"id":"SOC300","title":"Using Soc: Career & Community"},
{"id":"SOC333","title":"Delinquency & Youth"},
{"id":"SOC387","title":"Social and Cultural Change"},
{"id":"SOC452","title":"Disability and Society"},
{"id":"SOC483","title":"Sociology Honors Thesis"},
{"id":"SPAN420","title":"Modern Hispanic Theater"},
{"id":"SPAN431","title":"Spanish-American Poetry"},
{"id":"SPLP281","title":"Special Topic"},
{"id":"THTR495","title":"Senior Thesis"},
{"id":"UVWD480","title":"T.H.I.S. Seminar"},
{"id":"VOED101","title":"Intro Career & Technical Educ"},
{"id":"VOED202","title":"Indu Linkages Career/Tech Prg"},
{"id":"PHYS482","title":"Independent Study"},
{"id":"PLSC250","title":"Public Policy"},
{"id":"PLSC270","title":"Fund of Homeland Security"},
{"id":"PLSC285","title":"Comp Govt II: Non-West Pol Sys"},
{"id":"PLSC354","title":"Metropolitan Problems"},
{"id":"PSYC360","title":"Sensory Perception"},
{"id":"PSYC371","title":"Human Motivation"},
{"id":"PSYC372","title":"Drugs & Behavior"},
{"id":"PSYC375","title":"Mass Media & Behavior"},
{"id":"PSYC380","title":"Gender and Violence"},
{"id":"PSYC481","title":"Special Topic"},
{"id":"PSYC483","title":"Psychology Honors Thesis"},
{"id":"OLOC102","title":"St Abroad/UDLA Puebla"},
{"id":"OLOC121","title":"National Student Exchange B"},
{"id":"OLOC133","title":"St Abroad/University of Taipei"},
{"id":"OLOC157","title":"St Abroad/ETEN"},
{"id":"SCI281","title":"Special Topic"},
{"id":"ENGL100","title":"Basic Writing"},
{"id":"ENGL211","title":"British Literature 1660-1900"},
{"id":"ENGL212","title":"American Literature to 1900"},
{"id":"FDNT471","title":"Integ Nutn-Comp & Alt Hlthcare"},
{"id":"FIN324","title":"Principles of Investments"},
{"id":"MEDT286","title":"IMAG/Sonography Clinicals"},
{"id":"MGMT234","title":"Intro Quality Control"},
{"id":"MGMT300","title":"Human Resource Management"},
{"id":"MGMT310","title":"Principles of Management"},
{"id":"MGMT311","title":"Human Behavior in Organization"},
{"id":"MGMT330","title":"Production & Operations Mgmt"},
{"id":"MGMT425","title":"Franchising Methods & Mgmt"},
{"id":"EDHL115","title":"Intro American Sign Language"},
{"id":"RGPL281","title":"Special Topic"},
{"id":"RGPL342","title":"Physiography"},
{"id":"RGPL345","title":"Biogeography for Environ Mgrs"},
{"id":"RGPL458","title":"Planning Law"},
{"id":"RGPL482","title":"Independent Study"},
{"id":"RLST260","title":"American Religious Dev"},
{"id":"ECON330","title":"Labor Economics"},
{"id":"MKTG439","title":"Internet Marketing"},
{"id":"MLSC306","title":"Adv Lder Plan & Mod Combat Op"},
{"id":"MUHI322","title":"Medieval & Renaissance Music"},
{"id":"MUHI323","title":"The Baroque Era"},
{"id":"EDUC455","title":"Tchg Soc Study - Secondary Sch"},
{"id":"EDUC461","title":"Student Teaching"},
{"id":"EDUC471","title":"Student Teaching"},
{"id":"EDUC499","title":"Multicultural/Multiethnic Ed"},
{"id":"GEOG231","title":"Economic Geography"},
{"id":"GEOG251","title":"Geography of Pennsylvania"},
{"id":"GEOG257","title":"Geog of South & Southwest Asia"},
{"id":"GEOG316","title":"Intro to Geographic Info Sys"},
{"id":"GEOG454","title":"GIS Analysis of Public Health"},
{"id":"GEOG463","title":"Unman Aerial Sys-Remote & Data"},
{"id":"GEOG464","title":"Land Use Policy"},
{"id":"THTR120","title":"Stagecraft"},
{"id":"THTR206","title":"Classic Theater II"},
{"id":"THTR211","title":"History & Literature-Classical"},
{"id":"THTR221","title":"Basic Stage Lighting"},
{"id":"THTR320","title":"Scene Design"},
{"id":"THTR350","title":"Directing"},
{"id":"THTR482","title":"Independent Study"},
{"id":"COMM449","title":"Adv Audio Recording Techniques"},
{"id":"COMM480","title":"Seminar: Communications Media"},
{"id":"COMM482","title":"Independent Study"},
{"id":"COSC143","title":"Cyber Wellness"},
{"id":"COSC216","title":"Intro to Cyber Security"},
{"id":"CHSS343","title":"App Prac/Second Eng Lang Arts"},
{"id":"CJTR1200","title":"Criminal Justice Trng - PT II"},
{"id":"CMDA3080","title":"Professional Career Planning"},
{"id":"SPAN223","title":"Intrm Spanish Conv & Gram/HS"},
{"id":"SPAN330","title":"Adv Spanish Comp and Grammar"},
{"id":"SPAN389","title":"Theory & Pract Spanish Lang"},
{"id":"GEOS407","title":"Carbonate Geology Seminar"},
{"id":"GEOS408","title":"Carbonate Geology Field Wrkshp"},
{"id":"GEOS482","title":"Independent Study"},
{"id":"GEOS493","title":"Internship"},
{"id":"GERN493","title":"Internship"},
{"id":"GMGR3000","title":"Advanced Garde Manger"},
{"id":"GRMN230","title":"Intermed German Comp & Grammar"},
{"id":"HAWK104","title":"Crimson Common Hours"},
{"id":"HAWK106","title":"Career Game Plan"},
{"id":"MUSC414","title":"Music Composition IV"},
{"id":"NMTT314","title":"Adv Lithography & Dielectrics"},
{"id":"MATH105","title":"College Algebra"},
{"id":"MATH216","title":"Probability & Stats/NatSciMjrs"},
{"id":"BIOL300","title":"Genetics & Medicine & Nutr"},
{"id":"BIOL313","title":"Forensic Analysis of DNA"},
{"id":"BIOL342","title":"Comparative Vertebrate Anatomy"},
{"id":"BIOL352","title":"Comparative Animal Physiology"},
{"id":"BIOL462","title":"Vertebrate Endocrinology"},
{"id":"BIOL466","title":"Principles of Virology"},
{"id":"BIOL473","title":"Seedless Vasc Plts:Ferns/Flora"},
{"id":"ADVT170","title":"Career Exploration"},
{"id":"ALI1200","title":"Start Reading"},
{"id":"ALI4350","title":"Improving Speaking"},
{"id":"ANTH244","title":"Basic Archaeology"},
{"id":"ANTH250","title":"Human Origins"},
{"id":"BTED411","title":"Methods in Bus & Info Tech I"},
{"id":"BTED481","title":"Special Topics"},
{"id":"IMAG101","title":"Careers in Medical Imagin"},
{"id":"INDS210","title":"Human Factors in Interior Dsgn"},
{"id":"INDS219","title":"Kitchen and Bath Design"},
{"id":"INDS370","title":"History-Interior Dsgn & Arch I"},
{"id":"HNRC481","title":"Special Topic /H/"},
{"id":"HOSP325","title":"Hotel Sales"},
{"id":"HOSP335","title":"Legal Issues in Hospitality"},
{"id":"HOSP350","title":"Intro to the Casino Industry"},
{"id":"KHSS256","title":"Appl Human Struct & Conditiong"},
{"id":"KHSS280","title":"Aquatic Management & Operation"},
{"id":"KHSS292","title":"Intro to Sport Management"},
{"id":"KHSS319","title":"Field Practicum I"},
{"id":"KHSS333","title":"Psychology of Coaching"},
{"id":"KHSS346","title":"Prev & Acute Care Skills/ATHL"},
{"id":"KHSS373","title":"Bus Admn/Fitness Industry"},
{"id":"KHSS448","title":"Thera Exer for Athl Inj Mgmt"},
{"id":"KHSS482","title":"Independent Study"},
{"id":"LDSP461","title":"Leadership Capstone"},
{"id":"LDSP482","title":"Experiential Applic/Ldrship"},
{"id":"LGBT400","title":"Queer Theory"},
{"id":"ARHI425","title":"Arts of China"},
{"id":"ART114","title":"Color & 2-D Design"},
{"id":"ART218","title":"Graphic Design"},
{"id":"ART400","title":"Professional Practices"},
{"id":"ART460","title":"Advanced Jewelry and Metals"},
{"id":"APMU155","title":"Private Voice II"},
{"id":"APMU165","title":"Private Trumpet II"},
{"id":"APMU204","title":"Private Harp III"},
{"id":"APMU205","title":"Private Voice III"},
{"id":"APMU207","title":"Private Viola III"},
{"id":"APMU212","title":"Private Oboe III"},
{"id":"APMU215","title":"Private Trumpet III"},
{"id":"APMU227","title":"Capstone Jury Secondary Instr"},
{"id":"APMU253","title":"Private Harpsichord IV"},
{"id":"APMU259","title":"Private String Bass IV"},
{"id":"HIST350","title":"Hist Latin Amer, Colonial Prd"},
{"id":"HIST360","title":"History of Pennsylvania"},
{"id":"HIST363","title":"Thought&Cult Early America"},
{"id":"HIST373","title":"Modern American Working Class"},
{"id":"HIST385","title":"People in Nature: Intro to Env"},
{"id":"CEU2054","title":"Lethal Weapons (Basic)"},
{"id":"CEU2107","title":"PA Mun Police Off Dr Profic"},
{"id":"CEU2182","title":"Basic Crime Scene Investigatn"},
{"id":"CEU4327","title":"PCH: Recreation"},
{"id":"CEU4364","title":"Communication Skills"},
{"id":"CEU4379","title":"Bldg Strong Cust Svc Skills"},
{"id":"CEU4380","title":"PCH: Staff Supervision"},
{"id":"CEU4383","title":"Microsoft Word (Beginning)"},
{"id":"CEU4398","title":"Intro to Microsoft Office 2000"},
{"id":"CEU4403","title":"Caregivers: Giving/Taking Care"},
{"id":"CEU4413","title":"Basic Computer Training"},
{"id":"CEU4432","title":"Beginning Microsoft Word"},
{"id":"CEU4441","title":"Intro to Microsoft Word X{"},
{"id":"CRLG105","title":"Hindi I"},
{"id":"CRLG159","title":"Korean II"},
{"id":"CRLG202","title":"Chinese III"},
{"id":"CRLG252","title":"Chinese IV"},
{"id":"CRLG481","title":"Special Topic"},
{"id":"CRLG492","title":"Critical Lang II"},
]
module.exports={data}