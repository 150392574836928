<template>
  <div v-on:touchstart="onTouchStart">
    <div id="searchCourseBar">
      <div class="search-banner">
        <v-text-field
          id="searchInput"
          color="primary"
          autofocus
          class="pa-0 ma-0"
          append-icon="mdi-magnify"
          hide-details
          solo
          dense
          filled
          flat
          v-model="searchQuery"
          @input="processSearchQuery"
          @emptied="processSearchQuery"
          @focus="onSearchInpitFocus"
          :error-messages="serachErrorMessage"
          hint="Search for course id, title, etc."
          persistent-hint
        ></v-text-field>
      </div>
      <div class="search-hint" id="search-hint">
        <div>Search by course name, number, or description</div>
        <v-icon size="299">$bigMagnify</v-icon>
      </div>
    </div>

    <InfiniteScroll
      v-if="true"
      :list="filterF2(coursesToSelect, activeFilter)"
      :height="listHeight()"
      id="infiniteScroll"
    >
      <template v-slot:default="{ item }">
        <v-list-item dense class="pa-0 ma-0 underline">
          <v-container class="pa-0 ma-0">
            <v-row
              no-gutters
              class="d-flex align-content-space-around flex-wrap align-center justify-center py-2 course-tile"
              @click.stop="toggleDetails($event, item.id)"
            >
              <v-col
                cols="2"
                class="d-flex align-content-space-around flex-wrap pa-0 justify-center"
              >
                <div class="status-badge zero-count" v-if="item.seats == 0">
                  <div class="badge-text">Full</div>
                </div>
                <div
                  class="status-badge low-count"
                  v-if="item.seats < 4 && item.seats > 0"
                >
                  <div class="badge-tile badge-text">
                    <div>{{ item.seats }}</div>
                    <div>left</div>
                  </div>
                </div>
                <div class="count-badge" v-if="item.seats > 4"></div>
              </v-col>
              <v-col cols="3" class="course-id">
                {{ item.id }}
              </v-col>
              <v-col cols="6" class="course-title">
                {{ item.title }}
              </v-col>
              <v-col cols="1" v-if="item.description" class="pl-2">
                <v-icon class="drop-pull-icon">{{
                  isShowDetails(item.id) ? "$pullUp" : "$dropDown"
                }}</v-icon>
              </v-col>
              <v-col cols="1" v-else class="pl-2"> </v-col>
            </v-row>
            <v-row
              v-if="isShowDetails(item.id)"
              no-gutters
              class="d-flex align-content-space-around flex-wrap align-center justify-center"
            >
              <v-col
                cols="2"
                class="d-flex align-content-space-around flex-wrap pa-0 justify-center py-3"
              >
              </v-col>
              <v-col
                cols="10"
                class="d-flex align-content-space-around flex-wrap pa-0 justify-center pt-3 pb-5 pr-3"
              >
                <div class="text-details">{{ item.description }}</div>
              </v-col>
            </v-row>
            <v-row v-if="isShowDetails(item.id)" no-gutters class="pb-3">
              <v-col cols="12">
                <Button
                  look="btnAddWP"
                  @click="selectForReservation(item)"
                  height="39"
                  width="244"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-list-item>
      </template>
    </InfiniteScroll>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapGetters,
  // mapGetters
} from "vuex";
import { searchAll } from "../utils";
import InfiniteScroll from "@/components/InfiniteScroll";
import logdown from "logdown";
import lodash from "lodash";
import Button from "@/components/Button";

const logger = logdown("CourseSelector");
// logger.state.isEnabled = true;

export default {
  name: "CourseSelector",

  components: { InfiniteScroll, Button },

  props: ["selectedTerm", "selectedSlot"],

  computed: {
    ...mapGetters({
      appMode: "getAppMode",
      config: "getConfig",
    }),
    ...mapState({
      coursesToSelect: (state) => state.termCourses,
      appWindowHeight: (state) => state.appWindowHeight,
    }),
  },

  data: () => ({
    searchBarHeight: 52 + 16,
    courseCount: 100,
    searchQuery: "",
    activeFilter: "",
    serachErrorMessage: "",
    soloSearch: true,
    singleLineBanner: true,
    showDetails: {},
    screenInfo: "",
  }),

  methods: {
    onTouchStart(event) {
      logger.debug("onTouchStart", event);
      const searchInput = document.getElementById("searchInput");
      if (searchInput) {
        searchInput.blur();
      }
    },
    listHeight: function() {
      const searchBar = document.getElementById("searchCourseBar");
      const searchBarHeight = searchBar
        ? searchBar.getBoundingClientRect().height
        : this.searchBarHeight;
      var height = this.appWindowHeight;
      const appBarHeight = document
        .getElementById("myappbar")
        .getBoundingClientRect().height;
      const footerHeight = document
        .getElementById("myappfooter")
        .getBoundingClientRect().height;
      this.screenInfo = `h:${height}, fh:${footerHeight}`;
      logger.debug(this.screenInfo);
      height -= appBarHeight;
      height -= footerHeight;
      height -= searchBarHeight;
      return height;
    },
    onSearchInpitFocus(event) {
      logger.debug("onSearchInpitFocus", event);
      const el = document.getElementById("infiniteScroll");

      this.$emit("screen-info", {
        virtualKeyboardHeight: el && el.clientHeight,
        info: this.screenInfo,
      });
    },
    isShowDetails(id) {
      return id && this.showDetails[id];
    },
    ...mapMutations({
      // selectPage: "setSelectedPage",
      // setUserCourse: "setUserCourse",
    }),
    reportOpenCourseDeatils(courseId) {
      logger.debug(`reportOpenCourseDeatils: ${courseId}`);
      this.$gtag &&
        this.$gtag.event("open_course", {
          event_category: "user_interaction",
          data_course_id: courseId,
          data_term_id: this.selectedTerm.id,
        });
    },
    selectForReservation(course) {
      logger.debug("selectForReservation", course);
      this.$gtag &&
        this.$gtag.event("select_course", {
          event_category: "user_interaction",
          data_course_id: course.id,
          data_term_id: this.selectedTerm.id,
        });

      // this.selectCourse(course);
      // this.setUserCourse({
      //   term: this.selectedTerm.id,
      //   slot: this.selectedSlot,
      //   course: course,
      // });
      // this.selectPage("TermCourses");
      this.$emit("add-course", {
        course: course,
        slot: this.selectedSlot,
        term: this.selectedTerm,
      });
    },
    filterF2(courses, filter) {
      // var filtered = courses;
      var filtered = [];
      if (typeof filter == "string" && filter.length > 2) {
        filtered = searchAll(
          courses,
          filter,
          this.config.courseSearchScope, //["id", "title", "description"],
          "id"
        );
      }
      const hint = document.getElementById("search-hint");
      if (hint && hint.classList) {
        if (filtered.length > 0) {
          if (hint) {
            hint.classList.add("hidden");
          }
        } else {
          if (hint) {
            hint.classList.remove("hidden");
          }
        }
      }
      if (filtered.length == 0 && filter.length > 2) {
        this.serachErrorMessage =
          "none of the courses matches the search criteria, ";
      } else {
        this.serachErrorMessage = "";
      }
      return filtered;
    },
    processSearchQuery() {
      logger.debug(this.searchQuery);
      this.onSearchInpitFocus({});
      if (this.searchQuery && this.searchQuery.length > 2) {
        this.activeFilter = this.searchQuery;
      } else {
        this.activeFilter = "";
      }
    },
    toggleDetails(event, id) {
      logger.debug("onClick, toggle:", id);
      if (id) {
        if (!this.showDetails[id]) {
          logger.debug("onClick, set:", id);
          lodash.set(this.showDetails, [id], true);
          this.$forceUpdate();
          this.reportOpenCourseDeatils(id);
        } else {
          logger.debug("onClick, unset:", id);
          lodash.unset(this.showDetails, [id]);
          this.$forceUpdate();
        }
      }
    },
  },
  created() {
    this.$store.dispatch("fetchTermCourses", { term: this.selectedTerm.id });
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
.text-details {
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.search-hint {
  /* Search by course name, number, or description */

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  /* Gray 3 */
  color: #828282;
}
.search-banner {
  border: 1px solid #9b51e0;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-badge {
  color: white;
  width: 32px;
  height: 32px;
  border-style: solid;
  border-radius: 5px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}
.low-count {
  background-color: #9b51e0;
  color: white;
}
.zero-count {
  background-color: #eb5757;
  color: white;
}
.badge-text {
  font-size: 12px;
  line-height: 12px;
}
.course-id {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
}
.course-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
.underline {
  border-bottom: 1px solid #939393;
}
.drop-pull-icon {
  width: 14px;
}
.course-tile {
  cursor: pointer;
}
</style>
<style>
html,
body {
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
}
</style>
