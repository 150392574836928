<template>
  <div>
    <PageBanner
      :bannerText="'Acceptable Times'"
      subtitle
      v-if="readOnly"
      section="times"
    />
    <v-container>
      <v-row no-gutters>
        <!-- <v-col cols="1"> </v-col> -->
        <v-col
          cols="12"
          class="d-flex align-content-space-around flex-wrap justify-center"
        >
          <Times
            class="pr-3"
            :allTimes="config.times"
            :excludeDays="excludeDays"
            :unselectDays="unselectDays"
            :excludeTimes="config.excludeTimes"
            :unselectTimes="unselectTimes"
            :unselectDayTimes="unselectDayTimes"
            :userUnselected="userUnselectedTimes()"
            :userSelected="userSelectedTimes()"
            :readOnly="readOnly"
            v-on:time-slot-unselected-warning="
              (event) => {
                onWarning(event);
              }
            "
          />
        </v-col>
        <!-- <v-col cols="1"> </v-col> -->
      </v-row>
      <v-row><v-col></v-col></v-row>
      <v-row><v-col></v-col></v-row>
      <v-row><v-col></v-col></v-row>
      <v-row><v-col></v-col></v-row>
      <v-row><v-col></v-col></v-row>
      <v-row><v-col></v-col></v-row>
    </v-container>
    <v-dialog v-model="cautionDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="headline">Caution</v-card-title>

        <v-card-text>
          <p>
            Blocking too many times significantly reduces the chances a class
            will be available at an acceptable time when your reservation is
            processed.
          </p>
          <p>
            You have a better chance to be pre-registered for your classes when
            you block fewer times.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <!-- <v-btn
            color="green darken-1"
            text
            @click="
              cautionDialog = false;
              cautionDialogShown = true;
            "
          >
            Cancel
          </v-btn> -->

          <v-btn color="green darken-1" text @click="confirmCuation()">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import PageBanner from "@/components/PageBanner";
import Times from "@/components/Times";

import logdown from "logdown";
// import _ from "lodash";

const logger = logdown("TermCoursesTimes");
// logger.state.isEnabled = true;

export default {
  name: "TermCoursesTimes",
  components: {
    PageBanner,
    Times,
  },

  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      times: "getAllTimes",
      config: "getConfig",
      userTimes: "getUserTimes",
    }),
  },
  methods: {
    ...mapGetters({
      getUserTimes: "getUserTimes",
    }),
    showCautionDialog() {
      this.cautionDialog = true;
      this.cautionDialogShown = true;
    },
    confirmCuation() {
      this.cautionDialog = false;
    },
    userSelectedTimes: function() {
      let times = _.get(this.getUserTimes(), [
        this.selectedTerm.id,
        "selected",
      ]);
      return times ? times : [];
    },
    userUnselectedTimes: function() {
      let times = _.get(this.getUserTimes(), [
        this.selectedTerm.id,
        "unselected",
      ]);
      return times ? times : [];
    },
    onWarning(countUnselected) {
      logger.debug(`onWarning: ${countUnselected}`);
      if (
        countUnselected >= this.config.unselectedTimesCountWaring &&
        !this.cautionDialogShown
      ) {
        this.showCautionDialog();
      }
    },
  },
  data: function () {
    return {
      excludeDays: [], //["Sun"],
      unselectDays: [], //["Sat", "Sun"],
      unselectTimes: [
        "3:00a",
        "4:00a",
        "5:00a",
        "6:00a",
        "7:00p",
        "8:00p",
        "9:00p",
        "10:00p",
      ],
      unselectDayTimes: ["Mon-6:00p", "Fri-6:00p"],
      // userSelected: ["Mon-10:00p", "Wed-9:00p", "Fri-10:00p"],
      // userUnselected: ["Tue-3:00p", "Wed-3:00p", "Thu-2:00p"],
      cautionDialog: false,
      cautionDialogShown: false,
    };
  },
  updated: function() {
    logger.debug("updated");
  },
  created: function() {
    logger.debug("created, excludeTimes:", this.config.excludeTimes);
  },
};
</script>

<style scoped>
.time-slot {
  height: 30px;
  width: 32px;
  border-radius: 4px;
  border-style: solid;
}
.preferred {
  background-color: #9b51e0;
  border-color: #9b51e0;
  /* color: white; */
  color: lightgray;
}
.not-preferred {
  background-color: #bdbdbd;
  border-color: #777;
  color: gray;
}
.day-slot {
  width: 32px;
}
.pointer {
  cursor: pointer;
}
.link {
  text-decoration-line: underline;
}
</style>
