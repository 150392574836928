/**
 * Term Catalog
*/
import { nick } from './static'

const introText = {
  "title": "What is EdReserve?",
  "headerWhat": ["What it does:"],
  "bulletsWhat": [
    "Request seats for classes you want",
    "Get first access to seats held for you within your priorty group",
    "Have a voice in the IUP class scheduling process"
  ],
  "headerHow": ["How it works:"],
  "bulletsHow": [
    "Request classes in future terms and tell us when and how you want to take classes.",
    `${nick} uses your requests together with other considerations to create the class schedule.`,
    "Your requests are matched with classes when the schedule is published.",
    `${nick} holds a seat in your matched classes for 24-hours at your registration time, when possible.`
  ]
}

const commonCourseBullets = [
  { class: "special-text purple-text bold-text pb-4", text: "Request without worry." },
  { class: "special-text purple-text pb-2", text: "Here is the deal:" },
  { class: "special-text purple-text pb-3", text: "" },
  { class: "special-text purple-text pb-3", text: "Tell us the courses you want and we’ll match you to classes and let you know when it’s time to register.​" },
  { class: "special-text purple-text pb-3", text: "You can change your mind anytime or even decide to take something completely different when you register. That’s cool .​" },
  { class: "special-text purple-text pb-3", text: "Letting us know the courses you want helps us schedule the classes you need.​" },
];

const commonBullets = [
  "You may request up to two (2) courses per semester that are your top priorities. Changes can be made as long as requests are accepted.",
  "Your requests will be used as one of several items used to determine what courses may be offered, in what volume and during which days and times.​ Day and time availability requests are not guaranteed.",
  "You must still register for requested courses via normal registration procedures, including meeting with advisors, clearing any registration holds, registering at the correct times, etc.​",
];

const terms = [
  // {
  //   id: '2021Winter', termId: '202160', season: 'winter', reservationLimit: 2,
  //   title: "Winter 2021-2022",
  //   info: "",
  //   begins: "January 18",
  //   courseBullets: commonCourseBullets,
  //   bullets: commonBullets,
  // finePrintBoldHeader: [
  //   `${nick} will make its best effort to pre-register you in the courses you reserve.`
  // ],
  // finePrintHeader: [
  //   "You may reserve up to 2 courses for Winter 2021-2022.",
  //   "When processing your reservations, we verify:",
  // ],
  // finePrintBullets: [
  //   "A section is offered at a time, location, and delivery method that you indicate is acceptable in EdReserve.",
  //   "You meet the prerequisite and other eligibility requirements for the course and section at the time of pre-registration. It is your responsibility to check and ensure you meet these requirements ahead of time. ",
  //   "The course and section has seats available when your pre-registration is processed.",
  //   "You do not have any holds that prevent registration."
  // ],
  // finePrintFooter: [
  //   "The reservation deadline is 11:59pm December 1, 2021. Reservations cannot be changed or canceled after this date. You will need to wait until your assigned registration window to make any changes after this date."
  // ]
  // },
  {
    id: '2022Spring', termId: '202230', season: 'spring', reservationLimit: 2,
    title: "Spring 2022",
    info: "",
    begins: "January 18",
    courseBullets: commonCourseBullets,
    bullets: commonBullets,
  },
  // {
  //   id: '2022Summer', termId: '202240', season: 'summer', reservationLimit: 2,
  //   title: "Summer 2022",
  //   info: "",
  //   begins: "",
  //   courseBullets: commonCourseBullets,
  //   bullets: commonBullets,
  // },
  {
    id: '2022Fall', termId: '202250', season: 'fall', reservationLimit: 2,
    title: "Fall 2022",
    info: "",
    begins: "",
    courseBullets: commonCourseBullets,
    bullets: commonBullets,
  },
]

export {
  introText,
  terms,
}