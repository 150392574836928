const nick = "IUP"
const name = "Indiana University of Pennsylvania"
const domain = 'iup.edu'
const appDomain = 'iup'
const startPage = 'SelectATerm'
const courseSearchScope = ["id", "title"]
const excludeTimes = ["0:00a", "1:00a", "2:00a", "3:00a", "11:00p"]
const times = ["0:00a", "1:00a", "2:00a", "3:00a", "4:00a", "5:00a",
  "6:00a", "7:00a", "8:00a", "9:00a", "10:00a", "11:00a-11:30a",
  "12:30p-1:00p", "1:00p", "2:00p", "3:00p", "4:00p", "5:00p",
  "6:00p", "7:00p", "8:00p", "9:00p", "10:00p", "11:00p",
]

const modalities = [
  { id: "In person", title: "Face-to-face" },
  { id: "Remote", title: "Blended (some face-to-face, some online)" },
  { id: "Online", title: "Distance Education" },
]

export {
  nick,
  name,
  domain,
  modalities,
  appDomain,
  startPage,
  courseSearchScope,
  excludeTimes,
  times
}