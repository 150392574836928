<template>
  <div class="control">
    <div class="row">
      <div class="left-column"></div>
      <div v-for="day in days" :key="day">
        <div class="slot-column" v-if="!excludedDay(day)">
          <div class="top-header">
            <div class="day-text">{{ day }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(time, timeIndex) in times" :key="time">
      <div class="row" v-if="!excludedTime(time)">
        <div class="left-column">
          <div class="time-text">{{ time }}</div>
        </div>
        <div v-for="(day, dayIndex) in days" :key="day">
          <div class="slot-column" v-if="!excludedDay(day)">
            <div class="slot-cell">
              <TimeSlot
                :value="!unselectedDayTime(day, time)"
                :readOnly="readOnly"
                v-on:time-slot-selected="
                  () => {
                    onSelected({
                      day: day,
                      dayIndex: dayIndex,
                      time: time,
                      timeIndex: timeIndex,
                    });
                  }
                "
                v-on:time-slot-unselected="
                  () => {
                    onUnselected({
                      day: day,
                      dayIndex: dayIndex,
                      time: time,
                      timeIndex: timeIndex,
                    });
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapMutations, mapGetters } from "vuex";
import TimeSlot from "@/components/TimeSlot";
import logdown from "logdown";
const logger = logdown("Times");
// import { mapMutations, mapGetters } from "vuex";
export default {
  name: "Times",
  components: { TimeSlot },
  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
    allTimes: {
      type: Array,
      default: function() {
        return [
          "0:00a",
          "1:00a",
          "2:00a",
          "3:00a",
          "4:00a",
          "5:00a",
          "6:00a",
          "7:00a",
          "8:00a",
          "9:00a",
          "10:00a",
          "11:00a",
          "12:00p",
          "1:00p",
          "2:00p",
          "3:00p",
          "4:00p",
          "5:00p",
          "6:00p",
          "7:00p",
          "8:00p",
          "9:00p",
          "10:00p",
          "11:00p",
        ];
      },
    },
    excludeDays: {
      type: Array,
      default: function() {
        return [];
      },
    },
    excludeTimes: {
      type: Array,
      default: function() {
        return [];
      },
    },
    unselectDays: {
      type: Array,
      default: function() {
        return [];
      },
    },
    unselectTimes: {
      type: Array,
      default: function() {
        return [];
      },
    },
    unselectDayTimes: {
      type: Array,
      default: function() {
        return [];
      },
    },
    userSelected: {
      type: Array,
      default: function() {
        return [];
      },
    },
    userUnselected: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters({
      selectedTerm: "getSelectedTerm",
      config: "getConfig",
    }),
  },
  data: function() {
    return {
      days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      times: _.cloneDeep(this.allTimes),
      userSelectedDaysTimes: _.cloneDeep(this.userSelected),
      userUnselectedDaysTimes: _.cloneDeep(this.userUnselected),
    };
  },
  methods: {
    ...mapMutations({
      setUserTermTimes: "setUserTermTimes",
    }),
    updateTimes: function(action, event) {
      const preselected = this.isDefaultSelection(event.day, event.time);
      const dayTime = event.day + "-" + event.time;
      // action: 'select' | 'unselect'
      logger.debug(`updateTimes, ${action}, ${preselected}, ${dayTime}`);
      _.remove(this.userSelectedDaysTimes, (el) => dayTime == el);
      _.remove(this.userUnselectedDaysTimes, (el) => dayTime == el);
      const unselectedBefore = this.userUnselectedDaysTimes.length;
      logger.debug(`unselected count: ${this.userUnselectedDaysTimes.length}`);
      if (preselected && "unselect" == action) {
        this.userUnselectedDaysTimes.push(dayTime);
      }
      if (!preselected && "select" == action) {
        this.userSelectedDaysTimes.push(dayTime);
      }
      logger.debug(`*unselected count: ${this.userUnselectedDaysTimes.length}`);
      const unselectedAfter = this.userUnselectedDaysTimes.length;
      if (unselectedAfter - unselectedBefore > 0) {
        this.$emit("time-slot-unselected-warning", unselectedAfter);
      }
      this.setUserTermTimes({
        term: this.selectedTerm.id,
        times: {
          selected: _.cloneDeep(this.userSelectedDaysTimes),
          unselected: _.cloneDeep(this.userUnselectedDaysTimes),
        },
      });
    },
    onSelected: function(event) {
      logger.debug("onSelected:", event);
      this.updateTimes("select", event);
    },
    onUnselected: function(event) {
      logger.debug(`onUnselected:`, event);
      this.updateTimes("unselect", event);
    },
    excludedDay: function(day) {
      return this.excludeDays.indexOf(day) >= 0;
    },
    excludedTime: function(time) {
      return this.excludeTimes.indexOf(time) >= 0;
    },
    isDefaultSelection(day, time) {
      if (
        this.unselectDays.indexOf(day) >= 0 ||
        this.unselectTimes.indexOf(time) >= 0
      ) {
        return false;
      }
      return !this.unselectDayTimes.indexOf(day + "-" + time) >= 0;
    },
    unselectedDayTime: function(day, time) {
      // logger.debug(`unselectedDayTime: ${day}, ${time}`)
      if (this.userSelectedDaysTimes.indexOf(day + "-" + time) >= 0) {
        return false;
      }
      if (this.userUnselectedDaysTimes.indexOf(day + "-" + time) >= 0) {
        return true;
      }
      return !this.isDefaultSelection(day, time);
    },
  },
};
</script>

<style scoped>
.slot-column {
  width: 32px;
}
.top-header {
  width: 30px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slot-cell {
  width: 32px;
  height: 32px;
}
.day-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}
.time-text {
  width: inherit;
  text-align: right;
  padding-right: 7px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #828282;
}
.left-column {
  display: flex;
  width: 95px;
  align-items: center;
}
</style>
