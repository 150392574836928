/**
*/

import { customer } from '@/params/current'
import axios from 'axios'
import logdown from 'logdown'
const logger = logdown("config");
import _ from 'lodash'

logger.log(`domain: ${customer.domain}`)
logger.log(`logo: ${customer.logo}`)
logger.log(`terms: ${customer.terms}`)
logger.log(`terms: ${customer.introText}`)
logger.log(`appDomain: ${customer.appDomain}`)
logger.log(`startPage: ${customer.startPage}`)
logger.log(`courseSearchScope: ${customer.courseSearchScope}`)
logger.log(`excludeTimes: ${customer.excludeTimes}`)

const _version = require('../../public/version.json')

const _domain = _.get(window, ['location', 'hostname'])
const _protocol = _.get(window, ['location', 'protocol'])
const _app_root = _protocol + "//" + _domain
const _api_root = _app_root + ("localhost" == _domain ? ":8090" : '') + "/api"

logger.debug(`client domain: ${_domain}, api root: ${_api_root}`)

function appRoot() { return _app_root }
function apiRoot() { return _api_root }

function testAPI() {
    const testUrl = apiRoot() + '/testapi'
    return axios({
        method: 'get',
        url: testUrl
    }).then((response) => {
        logger.debug(`api responded with: ${response}`)
    }).catch((error) => {
        logger.debug(`api failed with: ${error}`)
    }).then(() => {
        logger.debug(`done with api testing`)
    })
}

testAPI()

const _config = {
    testVariable: 'test',
    unselectDays: [],
    startPage: 'Intro', // 'SelectATerm', 'WhatToReserve'
    quickRequest: 'QuickRequest',
    quickRequestStartTerm: '2021Fall',
    termWizard: {
        start: 'TermCourses',
        TermCourses: { next: 'TermModalities', },
        // TermModalities: { next: 'TermInstructors', back: 'TermCourses' },
        // TermInstructors: { next: 'TermLocations', back: 'TermModalities' },
        // TermLocations: { next: 'TermTimes', back: 'TermInstructors' },
        // TermTimes: { next: 'TermReview', back: 'TermLocations' },
        TermModalities: { next: 'TermTimes', back: 'TermCourses' },
        TermTimes: { next: 'TermReview', back: 'TermModalities' },
        TermReview: { back: 'TermTimes' },
    },
    splash: 'Splash', // comment to skip splash
    lockSplash: false, // lock splash with a passcode
    tryFullScreen: true,
    unselectedTimesCountWaring: 25,
    build: '',
    versionCheckInterval: 60000 * 3,
}

function fetchConfig() {
    return new Promise((resolve) => {
        _.assign(_config, _version)
        _.assign(_config, customer)
        logger.debug('fetchConfig, returning config', _config)
        setTimeout(resolve(_config), 0)
    })
}

function getStartPage(config, mode) {
    if (mode == 'advisor') {
        return config.quickRequest
    } else {
        if (config.splash) {
            return config.splash;
        } else {
            return config.startPage;
        }
    }
}

function nextTermWizardPage(config, page) {
    logger.debug(`nextTermWizardPage for ${page}`)
    const next = _.get(config, ['termWizard', page, 'next'])
    logger.debug(`nextTermWizardPage for ${page}: ${next}`)
    return next
}

function backTermWizardPage(config, page) {
    logger.debug(`backTermWizardPage for ${page}`)
    const back = _.get(config, ['termWizard', page, 'back'])
    logger.debug(`backTermWizardPage for ${page}: ${back}`)
    return back
}

function skipPage(config, page) {
    logger.debug(`skipPage for ${page}`)
    return !_.get(config, ['termWizard', page])
}

function latestVersion() {
    return axios.get(`/version.json?t=${(new Date()).getTime()}`).then((response) => {
        logger.debug('latestVersion', response.data)
        try {
            return _.get(response.data, ['build'])
        } catch (err) {
            return undefined
        }
    })
}

const _versionCheckInterval = 5000;

/**
 * 
 * @param {*} interval 
 * @param {*} currentVersion 
 * @param {*} failCallBack 
 */
function startVersionCheck(interval, currentVersion, failCallBack, networkErrorCallBack) {
    setInterval(() => {
        latestVersion()
            .then((version) => {
                logger.debug(`latestVersion: ${version}, currentVersion: ${currentVersion}`);
                // logger.debug(`latestVersion ${version}, ${this.getConfig().version}`)
                if (version != currentVersion) {
                    failCallBack(version, currentVersion)
                }
            })
            .catch((err) => {
                logger.debug(`latestVersion ${err}`);
                if (networkErrorCallBack) {
                    networkErrorCallBack()
                }
            });
    }, (interval) ? interval : _versionCheckInterval);
}


export {
    fetchConfig,
    getStartPage,
    nextTermWizardPage,
    backTermWizardPage,
    skipPage,
    latestVersion,
    startVersionCheck,
    apiRoot,
    appRoot,
    customer,
}